bovoApp.
  factory('Holiday', ['$http', function ($http) {
    return {
      all: function (type) {
        return $http.get('api/holidays');
      },
      get: function (id) {
        return $http.get('api/holiday/' + id);
      },
      new: function (id) {
        return $http.get('api/new-holiday');
      },
      delete: function (id) {
        return $http.delete('api/delete-holiday/' + id);
      },
      save: function (data) {
        return $http.post('api/save-holiday', data);
      },
      update: function (data) {
        return $http.post('api/update-holiday', data);
      }
    };
  }]);