bovoApp.controller('DashboardController', ['$scope', 'Analytic','Account', '$location','FileUploader','$http', 'Order', '$parse', '$interval',
  function ($scope, Analytic, Account, $location, FileUploader, $http, Order, $parse, $interval) {
	$scope.calendarView = 'month';
	$scope.filterData = {
		customers: [],
		workers: [],
		workSteps: [],
		scheduled_time : { 
			startDate: moment($scope.viewDate).startOf('month'), 
			endDate: moment($scope.viewDate).endOf('month') 
		}
	}
	
	$scope.viewDate = moment();
	$scope.cellIsOpen = false;
	$scope.date = {};
	$scope.page = {
		allOrders: 1,
		pendingOrders: 1,
		completedOrders: 1,
		failedOrders: 1
	};
	moment.locale('en', {
		week : {
			dow : 1 // Monday is the first day of the week
		}
	});

	$scope.init = function (type) {
		if(type != 'notLoading')
		$scope.loadingWork = true;
		Analytic.dashboard($scope.filterData).success(function(data, status, headers, config) {
			$scope.loadingWork = false;
			$scope.data = data;
			$scope.data.workSteps.unshift({
				id : 0,
				name : 'Veri Oluşturuldu'
			});
		});
	};

	$scope.refreshScore = function() {
		$scope.init('notLoading')
		$scope.ordersFromFilter('','all','allOrders', '');
		$scope.ordersFromFilter('','pending','pendingOrders', '');
		$scope.ordersFromFilter('','completed','completedOrders', '');
		$scope.ordersFromFilter('','failed','failedOrders', '');
	}
	
	var intervalPromise;
	$scope.refreshMe = function() {
		intervalPromise = $interval($scope.refreshScore, 30000);
	}
	$scope.$on('$destroy',function(){
		if(intervalPromise)
			$interval.cancel(intervalPromise);   
	});

	$scope.refreshMe();

	$scope.ordersFromFilter = function (date, type, dataName, loadingName) {
		$scope[loadingName] = true;
		$scope.date[dataName] = date;
		Analytic.ordersFromFilter({ date: date, type : type }).success(function(data, status, headers, config) {
			$scope[loadingName] = false;
			var model = $parse(dataName);
			model.assign($scope, data.orders);
			setTimeout(function(){
				$scope.$apply();
			},300);
		});
	};

	$scope.showMore = function (type, dataName, loadingName) {
		$scope[loadingName] = true;
		Analytic.ordersFromFilterShowMore({ date: $scope.date[dataName], type : dataName, page: $scope.page[dataName] }).success(function(data, status, headers, config) {
			$scope[loadingName] = false;
			$scope.page[dataName] = data.page;
			var model = $parse(dataName);
			model.assign($scope, data.orders.data);
			setTimeout(function() {
				$scope.$apply();
			},300);
		});
	};

	$scope.changeDate = function() {
		$scope.cellIsOpen = false;
		$scope.filterData.scheduled_time = {
			startDate: moment($scope.viewDate).startOf('month'), 
			endDate: moment($scope.viewDate).endOf('month') 
		};
	};

	$scope.eventClicked = function(event) {
		$scope.getOrderDetails(event.id);
	};

	$scope.eventEdited = function(event) {
		alert('Edited', event);
	};

	$scope.eventTimesChanged = function(event) {
		alert('Dropped or resized', event);
	};

	$scope.toggle = function($event, field, event) {
		$event.preventDefault();
		$event.stopPropagation();
		event[field] = !event[field];
	};

	$scope.timespanClicked = function(date, cell) {
		if ($scope.calendarView === 'month') {
			if (($scope.cellIsOpen && moment(date).startOf('day').isSame(moment($scope.viewDate).startOf('day'))) || cell.events.length === 0 || !cell.inMonth) {
				$scope.cellIsOpen = false;
			} else {
				$scope.cellIsOpen = true;
				$scope.viewDate = date;
			}
		} else if ($scope.calendarView === 'year') {
			if (($scope.cellIsOpen && moment(date).startOf('month').isSame(moment($scope.viewDate).startOf('month'))) || cell.events.length === 0) {
				$scope.cellIsOpen = false;
			} else {
				$scope.cellIsOpen = true;
				$scope.viewDate = date;
			}
		}
	};

	$scope.clearFilter = function(page) {
		$scope.filterData = {
			customers: [],
			workers: [],
			workSteps: [],
			scheduled_time : { 
				startDate: moment($scope.viewDate).startOf('month'), 
				endDate: moment($scope.viewDate).endOf('month') 
			}
		}
	}
  }
]);
