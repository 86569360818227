bovoApp.
factory('Performance', ['$http', function($http) {
    return {
      commonPerformance: function(data) {
        return $http.post('api/common-performance', data);
      },
      showOrders: function(data) {
        return $http.post('api/performance-orders', data);
      },
    };
}]);