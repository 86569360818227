bovoApp.
factory('OrderField', ['$http', function($http) {
    return {
      all: function() {
        return $http.get('api/order-fields');
      },
      get: function(id) {
        return $http.get('api/order-field/' + id);
      },
      delete: function(id) {
        return $http.delete('api/delete-order-field/' + id);
      },
      save: function(data) {
        return $http.post('api/save-order-field',data);
      },
      update: function(data) {
        return $http.post('api/update-order-field',data);
      }
    };
}]);