bovoApp.
factory('Offer', ['$http', function($http) {
    return {
      all: function(data) {
        return $http.post('api/offers', data);
      },
      get: function(id) {
        return $http.get('api/offer/' + id);
      },
      new: function(id) {
        return $http.get('api/new-offer');
      },
      delete: function(id) {
        return $http.delete('api/delete-offer/' + id);
      },
      save: function(data) {
        return $http.post('api/save-offer',data);
      },
      update: function(data) {
        return $http.post('api/update-offer',data);
      },
      sendToApp: function(data) {
        return $http.post('api/send-offer-to-app',data);
      },
      removeWorker: function(data) {
        return $http.post('api/offer-remove-worker',data);
      },
      addWorker: function(data) {
        return $http.post('api/offer-add-worker',data);
      },
    };
}]);