bovoApp.controller('StockStoreController', ['$scope', 'StockStore', '$location','$routeParams','$filter',
  function ($scope, StockStore, $location, $routeParams, $filter) {
    $scope.deleted = [];

    $scope.all = function () {
      StockStore.all().success(function(data, status, headers, config) {
        $scope.data = data;
      });
    }

    $scope.get = function () {
      StockStore.get($routeParams.id).success(function(data, status, headers, config) {
        $scope.data = data;
      });
    }
    
    $scope.new = function () {
      StockStore.new().success(function(data, status, headers, config) {
        $scope.data = data;
      });
    }

    $scope.delete = function (type) {
			if(type == 'set') {
        $('#delete').modal('show');
      } else if(type == 'delete') {
				StockStore.delete($scope.deleted).success(function(data, status, headers, config) {
          Messenger().post(data.message);
          for (var i = $scope.deleted.length - 1; i >= 0; i--) {
            var index = $scope.data.stockStores.indexOf($filter('filter')($scope.data.stockStores, {id: $scope.deleted[i] })[0]);
            if (index > -1)
              $scope.data.stockStores.splice(index, 1);
          }
          $scope.deleted = [];
          $('#delete').modal('hide');
				}).error(function(data) {
          Messenger().post({message: data.message,type: "error"});
				});
			} 
		}

		$scope.save = function () {
			StockStore.save($scope.stockStore).success(function(data, status, headers, config) {
				$scope.data = data;
        Messenger().post(data.message);
        $location.path('stok/depolar');
			}).error(function(data) {
        Messenger().post({message: data.message,type: "error"});
      });
		}

		$scope.update = function () {
			StockStore.update($scope.data.stockStore).success(function(data, status, headers, config) {
				$scope.data = data;
        Messenger().post(data.message);
        $location.path('stok/depolar');
			}).error(function(data) {
        Messenger().post({message: data.message,type: "error"});
      });
		}

    $scope.goTo = function(id) {
      $location.path('stok/depo/' + id);
    }

    $scope.customerSelect = function(selected,type) {
      if(typeof type === 'undefined') {
          if(typeof selected !== 'undefined') {
              var customer = selected.originalObject;
          }else {
              var customer = [];
          }
      }else {
          var customer = selected;
      }
      if(typeof customer !== 'undefined' && typeof $scope.stockStore !== 'undefined') {
        $scope.stockStore.company_customer_id = customer.id;
        $scope.stockStore.company_customer = customer.customer_name;
      }
      if(typeof customer !== 'undefined' && typeof $scope.data.stockStore !== 'undefined') {
        $scope.data.stockStore.company_customer_id = customer.id;
        $scope.data.stockStore.company_customer = customer.customer_name;
      }

  }

    $scope.getType = function(item) {
      switch (item) {
        case 'text':
            return 'Yazı';
          break;
        case 'number':
            return 'Numara';
          break;
        case 'date':
            return 'Tarih';
          break;
        case 'select':
            return 'Drop-Down (Seçim)';
          break;
      }
    }

    $scope.getValue = function(item) {
      if( item.value.length == 0 ) return '-';

      switch (item.type) {
        case 'text':
            return item.value;
          break;
        case 'number':
            return item.value;
          break;
        case 'date':
            return item.value;
          break;
        case 'select':
            return item.value;
          break;
      }
    }
      
    $scope.toggleSelection = function toggleSelection(id) {
      var idx = $scope.deleted.indexOf(id);
      if (idx > -1) {
        $scope.deleted.splice(idx, 1);
      }
      else {
        $scope.deleted.push(id);
      }
    };

  }
]);
