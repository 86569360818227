bovoApp.
factory('Collection', ['$http', function($http) {
    return {
      all: function(data) {
        return $http.post('api/collections', data);
      },
      get: function(id) {
        return $http.get('api/collection/' + id);
      },
      new: function(id) {
        return $http.get('api/new-collection');
      },
      delete: function(id) {
        return $http.delete('api/delete-collection/' + id);
      },
      save: function(data) {
        return $http.post('api/save-collection',data);
      },
      update: function(data) {
        return $http.post('api/update-collection',data);
      },
      addPayment: function(data) {
        return $http.post('api/save-collection-payment',data);
      },
      updatePayment: function(data) {
        return $http.post('api/update-collection-payment',data);
      },
      deletePayment: function(id) {
        return $http.delete('api/delete-collection-payment/' + id);
      },
    };
}]);