bovoApp.controller('CustomerUserController', ['$scope', 'CustomerUser', '$location', '$filter', '$routeParams',
  function ($scope, CustomerUser, $location, $filter, $routeParams) {
    $scope.deleted = [];

    $scope.filterData = {
      permissions: null
    }

    $scope.init = function () {
      CustomerUser.init($scope.filterData).success(function (data, status, headers, config) {
        $scope.data = data;
      });
    }

    $scope.refreshData = function (page) {
      $scope.init();
    }

    $scope.clearFilter = function (page) {
      $scope.filterData = {
        permissions: null
      }
      $scope.init();
    }

    $scope.show = function () {
      CustomerUser.show($routeParams.id).success(function (data, status, headers, config) {
        $scope.customerUser = data.user;
      });
    }

    $scope.newUser = function () {
      CustomerUser.newUser().success(function (data, status, headers, config) {
        $scope.stations = data.stations;
        $scope.customerUser = {
          authorization: {
            maps: true,
            office: true,
            customers: true,
            users: true,
            reports: true,
            settings: true
          }
        }
      });
    }

    $scope.$on('new_data', function () {
      $scope.newUser();
      $('#customerUserModal').modal('show');
    }, true);

    $scope.postCustomerUserForm = function (user) {
      if ($scope.customerUser.id) {
        $scope.updateUser();
      } else {
        $scope.saveUser();
      }
    }

    $scope.saveUser = function () {
      CustomerUser.saveUser($scope.customerUser).success(function (data, status, headers, config) {
        Messenger().post(data.message);
        $('#customerUserModal').modal('hide');
        $scope.init();
      }).error(function (data) {
        Messenger().post({
          message: data.message,
          type: "error"
        });
      });
    }

    $scope.updateUser = function () {
      CustomerUser.updateUser($scope.customerUser).success(function (data, status, headers, config) {
        Messenger().post(data.message);
        $('#customerUserModal').modal('hide');
        $scope.init();
      }).error(function (data) {
        Messenger().post({
          message: data.message,
          type: "error"
        });
      })
    }

    $scope.openDetailCustomerUser = function (item) {
      CustomerUser.show(item.id).success(function (data, status, headers, config) {
        $('#customerUserModal').modal('show');
        $scope.stations = data.stations;
        $scope.customerUser = data.user;
      });
    }

    $scope.delete = function (type) {
      if (type == 'set') {
        $('#delete').modal('show');
      } else if (type == 'delete') {
        CustomerUser.deleteUser($scope.deleted).success(function (data, status, headers, config) {
          Messenger().post(data.message);
          for (var i = $scope.deleted.length - 1; i >= 0; i--) {
            var index = $scope.data.users.data.indexOf($filter('filter')($scope.data.users.data, {
              id: $scope.deleted[i]
            })[0]);
            if (index > -1)
              $scope.data.users.data.splice(index, 1);
          };
          $scope.deleted = [];
          $('#delete').modal('hide');
        }).error(function (data) {
          Messenger().post({
            message: data.message,
            type: "error"
          });
        })
      }
    }

    $scope.getNumber = function (num) {
      return new Array(num);
    }

    $scope.toggleSelection = function toggleSelection(id) {
      var idx = $scope.deleted.indexOf(id);
      if (idx > -1) {
        $scope.deleted.splice(idx, 1);
      } else {
        $scope.deleted.push(id);
      }
    }
  }
]);