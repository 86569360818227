bovoApp.controller('OrderFieldController', ['$scope', 'OrderField', '$location','$routeParams','$filter',
  function ($scope, OrderField, $location, $routeParams, $filter) {
    $scope.deleted = [];

    $scope.all = function () {
      OrderField.all().success(function(data, status, headers, config) {
        $scope.data = data;
      });
    }

    $scope.get = function () {
      OrderField.get($routeParams.id).success(function(data, status, headers, config) {
        $scope.data = data;
      });
    }

    $scope.delete = function (type) {
			if(type == 'set') {
        $('#delete').modal('show');
      } else if(type == 'delete') {
				OrderField.delete($scope.deleted).success(function(data, status, headers, config) {
          Messenger().post(data.message);
          for (var i = $scope.deleted.length - 1; i >= 0; i--) {
            var index = $scope.data.orderFields.indexOf($filter('filter')($scope.data.orderFields, {id: $scope.deleted[i] })[0]);
            if (index > -1)
              $scope.data.orderFields.splice(index, 1);
          }
          $scope.deleted = [];
          $('#delete').modal('hide');
				}).error(function(data) {
          Messenger().post({message: data.message,type: "error"});
				});
			} 
		}

		$scope.save = function () {
			OrderField.save($scope.orderField).success(function(data, status, headers, config) {
				$scope.data = data;
        Messenger().post(data.message);
        $location.path('ozel-alanlar');
			}).error(function(data) {
        Messenger().post({message: data.message,type: "error"});
      });
		}

		$scope.update = function () {
			OrderField.update($scope.data.orderField).success(function(data, status, headers, config) {
				$scope.data = data;
        Messenger().post(data.message);
        $location.path('ozel-alanlar');
			}).error(function(data) {
        Messenger().post({message: data.message,type: "error"});
      });
		}

    $scope.goTo = function(id) {
      $location.path('ozel-alanlar/' + id);
    }

    $scope.getType = function(item) {
      switch (item) {
        case 'text':
            return 'Yazı';
          break;
        case 'number':
            return 'Numara';
          break;
        case 'date':
            return 'Tarih';
          break;
        case 'select':
            return 'Drop-Down (Seçim)';
          break;
      }
    }

    $scope.getValue = function(item) {
      if( item.value.length == 0 ) return '-';

      switch (item.type) {
        case 'text':
            return item.value;
          break;
        case 'number':
            return item.value;
          break;
        case 'date':
            return item.value;
          break;
        case 'select':
            return item.value;
          break;
      }
    }
      
    $scope.toggleSelection = function toggleSelection(id) {
      var idx = $scope.deleted.indexOf(id);
      if (idx > -1) {
        $scope.deleted.splice(idx, 1);
      }
      else {
        $scope.deleted.push(id);
      }
    };

  }
]);
