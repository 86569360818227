bovoApp.factory('CustomerOrder',['$http',function($http){
    return{
        customerOrder: function(id) {
            return $http.get('api/customer-order/' + id);
          },
        customerOrders: function(data) {
            return $http.post('api/customer-orders',data);
        },
        newCustomerOrder: function(data) {
            return $http.post('api/new-customer-order',data);
        },
        dashboard: function(data) {
            return $http.post('api/customer-dashboard',data);
        },
        donwloadSampleExcel: function() {
            return $http.get('api/download-sample-customer-order-excel');
        },
        updateCustomerOrder: function(data) {
            return $http.post('api/update-customer-order',data);
        },
        newOrderData: function(data) {
            return $http.post('api/initiate-customer-order',data);
        },
        getWorker: function(data) {
            return $http.post('api/get-worker',data);
        },
        checkOrder : function(data){
          return $http.post('api/check-order',data);      
        },
        removeNotDelivery : function(data){
          return $http.post('api/remove-not-delivery');      
        },
        getReaminingTime : function(id) {
          return $http.get('api/get-remaining-time-customer/' + id);      
        },
        notifications: function (data) {
            return $http.post('api/customer-notifications', data);
        },
        consensus: function (data) {
            return $http.post('api/customer-consensus', data);
        },
        approveConsensus: function (data) {
            return $http.post('api/approve-customer-consensus', data);
        },
        setTicimaxOrderId: function (data) {
            return $http.post('api/set-ticimax-orderid', data);
        },
    };
}]);