// Reacts upon enter key press.
(function (module) {
    module.directive('enterStroke', function () {
        return function (scope, element, attrs) {
          element.bind('keydown keypress', function (event) {
            if (event.which === 13) {
              scope.$apply(function () {
                scope.$eval(attrs.enterStroke);
              });
              event.preventDefault();
            }
          });
        };
    });
}(angular.module("enterStroke", [])));