bovoApp.controller('RouteOrderController', ['$scope', 'Order', '$location','$filter','$routeParams','$rootScope', 'ngAudio',
	function ($scope, Order, $location, $filter, $routeParams, $rootScope, ngAudio) {

		var successSound = ngAudio.load("sounds/success.mp3");

		$scope.movement = {
			code: null,
			station_id: null,
			route_optimization_id: null,
			weight: null
		}

		$scope.send = function () {
			Order.routeOrder($scope.movement).success(function(data, status, headers, config) {
				$scope.movement.code = null
				Messenger().post(data.message);
				successSound.play()
			}).error(function (data) {
			  Messenger().post({
				message: data.message,
				type: "error"
			  });
			});
		};

		$scope.init = function(station_id) {
			var data = {};
			if(station_id) {
				data.station_id = station_id;
			}
			Order.routeOrderInit({params: data}).success(function(data, status, headers, config) {
				$scope.data = data;
				setTimeout(() => {
					$scope.$apply();;
				}, 300);
			});
		};

	}
]);