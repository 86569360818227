bovoApp.controller('CustomerOrderController', ['$scope', 'Order', '$location','$filter','$interval',
	'ngAudio','$routeParams','$rootScope','$window','$http','$timeout','CustomerOrder',
	function ($scope, Order, $location, $filter, ngAudio, $routeParams, $rootScope, $window, $http, $interval, $timeout, CustomerOrder) {

		//var successSound = ngAudio.load("sounds/success.mp3");
		var routeOptimizationDirectionsDisplay = [];
		var routeOptimizationWorkerOrders      = [];

		$scope.statuses = {work:1, worker:1};
		$scope.filterPanel = false;
		$scope.tab = 0;

		$scope.filterData = {
			workers: [],
			workSteps: [],
			created_at : { 
				startDate: null, 
				endDate: null 
			},
			scheduled_time : { 
				startDate: null, 
				endDate: null
			}
		}
		$scope.filterProp = [];

		$scope.addFilterProps = function (item) {
			if($scope.filterProp.indexOf(item) === -1)
				$scope.filterProp.push(item);
			else {
				var index = $scope.filterProp.indexOf(item);
				$scope.filterProp.splice(index, 1);
			}
		}
	
		var calculatedOrder = [];

		if(window.localStorage.getItem('map_detail') !== null) {
			$scope.mapDetail = JSON.parse(window.localStorage.getItem('map_detail'));
		}else {
			$scope.mapDetail = {
				traffic: false,
				detail_name: true,
				darkColor: true
			}
		}
		var geocoder, interval;

		$scope.getWorkerOrders = function(item) {
			return item.worker_id === $scope.sWorker.id;
		}

		$scope.$on('teamChange', function (event,data,nextParams,fromState) {
			$scope.filterData.team = data !== null ? data.id : null;
			$scope.init();
		});

		$scope.export = function(page) {
			$scope.filterData.export = true;
			$scope.init('export');
		}

		$scope.searchOrder = function(term) {
			$scope.filterData.term = term;
			$scope.init(); 
		}

		$scope.init = function (type) {
			if(typeof type !== 'undefined' && type == 'clear') {
				$scope.filterData = {
					term: '',
					workers: [],
					workSteps: [],
					created_at : { 
						startDate: null, 
						endDate: null 
					},
					scheduled_time : { 
						startDate: null, 
						endDate: null 
					}
				}
			}

			$scope.getPage = function(page) {
				$scope.filterData.page = page;
				$scope.init();
			}
			
			if ($routeParams.real_order) {
                $scope.filterData.real_order = true;
                $rootScope.realOrder = true;
            } else {
                $rootScope.realOrder = false;
            }

			CustomerOrder.dashboard($scope.filterData).success(function(data, status, headers, config) {
				if(typeof type !== undefined && type == 'export') {
					$scope.filterData.export = false;
					window.location.href = data.url;
				}else {

					$scope.data = data;
					$scope.paginate = {
						current_page : data.orders.current_page,
						from : data.orders.from,
						last_page : data.orders.last_page,
						per_page : data.orders.per_page,
						to : data.orders.to,
						total : data.orders.total
					};
					$scope.data.orders = data.orders.data;
					if($rootScope.realOrder) {
						$scope.data.steps.unshift({
							id : 0,
							name : 'Veri Oluşturuldu'
						});
					}
					var trafficLayer = new google.maps.TrafficLayer();
					$scope.markers = {
						'order' : [],
						'worker' : []
					};
					$scope.infowindows = [];
					$scope.infowindowsOrder = [];
					var bounds = new google.maps.LatLngBounds();
					$scope.map = new google.maps.Map(document.getElementById('map'), { //this is map
						mapTypeId: google.maps.MapTypeId.ROADMAP,
						mapTypeControl: true,
						streetViewControl: true,
						rotateControl: true,
						scaleControl: true,
						fullscreenControl: true,
					});

					//createContextMenu();
					for (var i = $scope.data.orders.length - 1; i >= 0; i--) {
						$scope.createOrderMarker($scope.data.orders[i]);
						if(!$scope.authenticatedUser.setting.dashboard_map && typeof $scope.markers['order'][$scope.data.orders[i].id] !== 'undefined') 
						bounds.extend($scope.markers['order'][$scope.data.orders[i].id].getPosition());
					}
					if(
							($scope.data.orders.length > 0)
							&& !$scope.authenticatedUser.setting.dashboard_map
						) {
						$scope.map.fitBounds(bounds);
					}

					if($scope.authenticatedUser.setting.dashboard_map) {
						$scope.map.setCenter(new google.maps.LatLng($scope.authenticatedUser.location.lat,$scope.authenticatedUser.location.lon));
						$scope.map.setZoom($scope.authenticatedUser.location.zoom);
					}
					
					$scope.$watch('mapDetail',function(value) {
						var style = {styles: [
							{
								featureType: "all",
								elementType: "labels",
								stylers: [
									{ visibility: "off" }
								]
							}
						]};

						if(value.detail_name) {
							style.styles.push({
								"stylers": [
									{ "visibility": "on" }
							]});
						}

						/* if(value.darkColor) {
							style.styles.push({
								"stylers":[
									{"invert_lightness":true},
									{"saturation":10},
									{"lightness":10},
									{"gamma":0.9},
									{"hue":"#435158"}
								]
							});
						}else {
							style.styles.push({
								"featureType":"all",
								"elementType":"all",
							});
						} */

						style.styles.push({
							"featureType":"all",
							"elementType":"all",
						});

						$scope.map.setOptions(style);
						
						if(value.traffic)
							trafficLayer.setMap($scope.map);
						else
							trafficLayer.setMap(null);
						window.localStorage.setItem('map_detail', JSON.stringify(value));
					},true);

					/*
						interval = setInterval(function( ) {
							Order.workers({team: $scope.selectedTeamName.id }).success(function(data, status, headers, config) {
								for (var i = $scope.data.workers.length - 1; i >= 0; i--) {
									if($scope.data.workers[i].lat.length != 0) 
										$scope.createWorkerMarker($scope.data.workers[i]);
								}
							});
						}, 15000);
					*/
				}

			});
		}

		$scope.$on('refresh_data', function() {
			console.log('refreshing map...')
			$scope.init();
		}, true);

		$scope.clearFilter = function(page) {
			$scope.filterData = {
				workers: [],
				workSteps: [],
				created_at : { 
					startDate: null, 
					endDate: null 
				},
				scheduled_time : { 
					startDate: null, 
					endDate: null
				}
			}
			$scope.filterProp = [];
			$scope.refreshData('map');
		}

		$scope.$on('$routeChangeStart', function(next, current) { 
			//clearInterval(interval);
		});

		$scope.$on('new_order', function (event,data,nextParams,fromState) {
			
			if(typeof data[0] !== 'undefined') {
				data.orders.forEach(function(element) {
					addOrder(element);
				}, this);
			} else {
				if(typeof data.order !== 'undefined') {
					addOrder(data.order);
				}else {
					data.orders.forEach(function(element) {
						addOrder(element);
					}, this);
				}
			}
		});

		function addOrder(data) {
			$scope.infowindowsOrder.forEach(function(element) {
				element.close();
			}, this);

			if($scope.filterData.scheduled_time.startDate == null) { 
				var order = find($scope.data.orders, data.id);
				if(typeof order !== 'undefined') {
					angular.merge(order, data);
				}else {
					$scope.data.orders.unshift(data);
				}

				$scope.createOrderMarker(order);
				$scope.infowindowsOrder[order.id].open($scope.map, $scope.markers['order'][order.id]);

			}else {
				var date1 = moment(data.scheduled_time).format('DD/MM/YYYY'),
					date2 = moment($scope.filterData.scheduled_time.startDate).format('DD/MM/YYYY');
				if(date1 == date2) {
					var order = find($scope.data.orders, data.id);
					if(typeof order !== 'undefined') {
						angular.merge(order, data);
					}else {
						$scope.data.orders.unshift(data);
					}

					$scope.createOrderMarker(order);
					$scope.infowindowsOrder[order.id].open($scope.map, $scope.markers['order'][order.id]);
				}
			}
		}

		$scope.$on('updateOrder', function (event,data,nextParams,fromState) {
			var order = find($scope.data.orders, data.id);
			angular.merge(order, data);
			$scope.createOrderMarker(order);
		});


		$scope.$on('refreshDashboard', function (event,data,nextParams,fromState) {
			$scope.init();
		});


		$scope.$on('deleteOrder', function (event,data,nextParams,fromState) {
			var index = $scope.data.orders.indexOf($filter('filter')($scope.data.orders, {id: data.id})[0]);
			if (index > -1) 
				$scope.data.orders.splice(index, 1);
			$scope.markers['order'][data.order.id].setMap(null);
		});

		$scope.disableEnter = function(e) {
			if(e.keyCode == 13) {
				return false;
			}
		}
		
		$scope.getWorkerStatus = function (status) {
			if(typeof $scope.data !== 'undefined')
				return $filter('filter')($scope.data.workers, {status: status}).length;
		}

		$scope.checkLastLocationTime = function(worker) {
			if(worker.location_info.length > 0) {
				var extra = angular.fromJson(worker.location_info);
				var now = moment(new Date()); 
				var end = moment(extra.timestamp);
				var duration = moment.duration(now.diff(end));
				var hours = duration.asHours();
				return hours > 12;
			}
		}

		$scope.detailsWorker = function (worker) {
			$scope.sWorker = worker;
			if($scope.sWorker.location_info.length != 0) {
				Order.getLatAndLngOfWorkerOrders({workerID:worker['id'],workerLatAndLng:Number($scope.sWorker.lat)+','+Number($scope.sWorker.lon)}).success(function(data, status, headers, config) {
				if(typeof data['latAndLng'] != "undefined" && typeof data['ids'] != "undefined"){ 

				    var start = data['latAndLng'][0];
					var end = data['latAndLng'][data['latAndLng'].length-1];
					var waypts = [];
					for (var i = 1; i < data['latAndLng'].length-1; i++) {
						token = data['latAndLng'][i].split(',');
					 	var point = new google.maps.LatLng(Number(token[0]),Number(token[1]));
					 	waypts.push({'location' : point,"stopover":true});
					}
					makeRoute(start,end,waypts,data['ids']);
					
				}
				else if(typeof data['latAndLng'] === "undefined" && typeof data['ids'] === "undefined" && routeOptimizationWorkerOrders.length > 0){

					for (var i = 1; i < routeOptimizationWorkerOrders.length; i++) {
						$scope.markers['order'][routeOptimizationWorkerOrders[i]].setLabel({
						    text: " ",
						    color: 'white',
						    fontSize: "10px",
							fontWeight: 'bold'
						});
					}
					routeOptimizationDirectionsDisplay.setMap(null);
				}

			        var location = new google.maps.LatLng(Number($scope.sWorker.lat),Number($scope.sWorker.lon));
					$scope.map.panTo(location);
					$scope.map.setCenter(location);
					$scope.infowindows.forEach(function(element) {
						element.close();
					}, this);
					infoWindow(worker,'open');  

		    	});	
			}

		}

		$scope.clickWork = function (work) {
			$scope.selectedOrderId = work.id;	
			$scope.infowindowsOrder.forEach(function(element) {
				element.close();
			}, this);
			$scope.infowindowsOrder[work.id].open($scope.map, $scope.markers['order'][work.id]);
			$scope.map.panTo(new google.maps.LatLng(Number(work.location.latitude),Number(work.location.longitude)));
			$scope.map.setZoom(13);
			
			google.maps.event.addListener($scope.infowindowsOrder[work.id],'closeclick',function() {
				$scope.selectedOrderId = 0;
            });
            
            CustomerOrder.getWorker({ id: work.id }).success(function(data, status, headers, config) {
                $scope.markers['worker'].forEach(function(item){
                    item.setMap(null);
                });
				$scope.createWorkerMarker(data);
				var directionsService = new google.maps.DirectionsService,
					directionsDisplay = new google.maps.DirectionsRenderer({
					suppressMarkers: true
				});
                if(work.status != 0 && work.sort != null && work.status && work.status.sort > 1) {
                    if(work.status.value == 'last') {
    
                        var request = {
                            origin:new google.maps.LatLng(Number(data.lat),Number(data.lon)),
                            destination:new google.maps.LatLng(Number(work.location.latitude),Number(work.location.longitude)),
                            waypoints: [],
                            travelMode: google.maps.DirectionsTravelMode.DRIVING
                        };
                    }else {
    
                        var request = {
                            origin:new google.maps.LatLng(Number(data.lat),Number(data.lon)),
                            destination:new google.maps.LatLng(Number(work.location.latitude),Number(work.location.longitude)),
                            waypoints: [],
                            travelMode: google.maps.DirectionsTravelMode.DRIVING
                        };
                    }
                    directionsService.route(request, function(response, status) {
                        if (status == google.maps.DirectionsStatus.OK) {
                            directionsDisplay.setDirections(response);
                        }
                    });
                    routeOptimizationDirectionsDisplay = directionsDisplay;
                    routeOptimizationDirectionsDisplay.setMap($scope.map);
                }else {
                    var request = {
                        origin:new google.maps.LatLng(Number(data.lat),Number(data.lon)),
                        destination:new google.maps.LatLng(Number(work.location.latitude),Number(work.location.longitude)),
                        waypoints: [],
                        travelMode: google.maps.DirectionsTravelMode.DRIVING
                    };
                    directionsService.route(request, function(response, status) {
                        if (status == google.maps.DirectionsStatus.OK) {
                            directionsDisplay.setDirections(response);
                        }
                    });
                    routeOptimizationDirectionsDisplay = directionsDisplay;
                    routeOptimizationDirectionsDisplay.setMap($scope.map);
                }
            });	
		}

		$scope.closeWorker = function () {
			delete $scope.sWorker;
			for (var i = 1; i < routeOptimizationWorkerOrders.length; i++) {
				$scope.markers['order'][routeOptimizationWorkerOrders[i]].setLabel({
				    text: " ",
				    color: 'white',
				    fontSize: "10px",
					fontWeight: 'bold'
				});
			}
			if(routeOptimizationDirectionsDisplay.length > 0)
			routeOptimizationDirectionsDisplay.setMap(null);
		}

		$scope.filterWorker = function (worker) {
			if($scope.statuses['worker'] == 0)
				return worker.status === 0;
			else if($scope.statuses['worker'] == 1)
				return worker.status === 1;
			else
				return true;
		}

		$scope.setStatus = function (status,type) {
			$scope.statuses[type] = status;
		}

		$scope.getWorkStatus = function (status) {
			if(typeof $scope.data !== 'undefined' && typeof $scope.data.orders !== 'undefined') {
				if(status == 'continue') {
					return $filter('filter')($scope.data.orders, function(value) {
						return value.status == null  || (value.status.id != $scope.data.complete && value.status.id != $scope.data.fail);
					}).length;
				}
				else if(status == 'cancel') {
					return $filter('filter')($scope.data.orders, function(value){
						return value.status != null && value.status.id == $scope.data.fail;
					}).length;
				}
				else if(status == 'success') {
					return $filter('filter')($scope.data.orders, function(value) {
						return value.status != null && value.status.id == $scope.data.complete;
					}).length;
				}
				else if(status == 'assign') {
					return $filter('filter')($scope.data.orders, function(value) {
						return value.worker_id != 0;
					}).length;
				}
				else if(status == 'not-assign') {
					return $filter('filter')($scope.data.orders, function(value) {
						return value.worker_id == 0;
					}).length;
				}
				else if(status == 'other') {
					return $filter('filter')($scope.data.orders, function(value) {
						return value.status != null && (value.status.id == $scope.data.complete || value.status.id == $scope.data.fail );
					}).length;
				}
			}
		}

		$scope.filterWork = function (work) {
			if($scope.statuses['work'] == 1) {
				return work.status == null || (work.status.id != $scope.data.complete && work.status.id != $scope.data.fail);
			}
			else if($scope.statuses['work'] == 0) {
				return work.status != null && (work.status.id == $scope.data.fail);
			}
			else if($scope.statuses['work'] == 2) {
				return work.status != null && (work.status.id == $scope.data.complete);
			}
		}

		$scope.refreshOrders = function () {
			Order.refreshOrders().success(function(data, status, headers, config) {
				$scope.data.orders = data.orders;
			});
		};

		$scope.$on('worker_answer', function (event,data,nextParams,fromState) {
			var order = find($scope.data.orders,data.order.id);
			angular.merge(order, data.order);
			$scope.createOrderMarker(order);
			Messenger().post(data.order.id + ' Nolu Kargonun durumu "'+ order.status.name +'" olarak değiştirildi.', 'İş Durumu!');
			Push.create('İş Durumu!', {
				body: data.order.id + ' Nolu Kargonun durumu "'+ order.status.name +'" olarak değiştirildi.',
				icon: { x32: 'https://s3.eu-central-1.amazonaws.com/bovo/'+$scope.authenticatedUser.logo },
				timeout: 3000
			});
		});

		$scope.$on('location_update', function (event,data,nextParams,fromState) {
			if($scope.selectedTeamName.id != null && $scope.selectedTeamName.id != data.worker.team_id) return false;

			var workerFind = find($scope.data.workers, data.worker.id);
			
			$scope.createWorkerMarker(data.worker);
			angular.merge(workerFind, data.worker);
		});

		$scope.$on('delete_order', function (event,data,nextParams,fromState) {
			$scope.refreshOrders();
		});

		$scope.$on('update_order', function (event,data,nextParams,fromState) {
			$scope.refreshOrders();
		});

		$scope.$on('worker_status', function (event,data,nextParams,fromState) {
			if($scope.selectedTeamName.id != null && $scope.selectedTeamName.id != data.worker.worker.team_id) return false;
			
			if(data.status == 1) {
				var message = data.worker.full_name + ' İş Alımını Açtı!';
				Messenger().post(message);
				Push.create('Durum!', {
					body: message,
					icon: { x32: 'https://s3.eu-central-1.amazonaws.com/bovo/'+$scope.authenticatedUser.logo },
					timeout: 3000
				});
			} else if(data.status == 0) {
				var message = data.worker.full_name + ' İş Alımını Kapattı!';
				Messenger().post({
					message: message,
					type: 'error',
					showCloseButton: true
				});
				Push.create('Durum!', {
					body: message,
					icon: { x32: 'https://s3.eu-central-1.amazonaws.com/bovo/'+$scope.authenticatedUser.logo },
					timeout: 3000
				});
			} else if(data.status == 3) {
				if(data.gps) {
					var message = data.worker.full_name + ' GPS\'ni Açtı!';
					Messenger().post(message);
				} else {
					var message = data.worker.full_name + ' GPS\'ni Kapattı!';
					Messenger().post({
						message: message,
						type: 'error',
						showCloseButton: true
					});
				}
			} else if(data.status == 'note') {
				var order = find($scope.data.orders, data.log.order_id);
				if(typeof order !== 'undefined') {
					if(typeof order.notes === 'undefined') order.notes = [];
					order.notes.push(data.log);
				}
				Messenger().post({
					message: data.worker.full_name +',  #'+ data.log.order_id +' nolu işe "'+ data.log.reason +'" notunu ekledi!',
					type: 'success',
					showCloseButton: true
				});
				Push.create('İş Notu!', {
					body: data.worker.full_name +',  #'+ data.log.order_id +' nolu işe "'+ data.log.reason +'" notunu ekledi!',
					icon: { x32: 'https://s3.eu-central-1.amazonaws.com/bovo/'+$scope.authenticatedUser.logo },
					timeout: 3000
				});
			}
			if(data.status != 'note') {
				var worker = find($scope.data.workers, data.worker.worker.id);
				worker.status = data.status;
				worker.location_info = data.worker.worker.location_info;			
				$scope.changeWorkerMarker(worker);
			}
		});

		function find(data,find) {
			var index = data.indexOf($filter('filter')(data, {id: find})[0]);
			if (index > -1)
				return data[index];
		}

		$scope.updateOrder = function (e) {
			Order.updateOrder($scope.editOrderData).success(function(data, status, headers, config) {
				var index = $scope.data.orders.indexOf($filter('filter')($scope.data.orders, {id: $scope.editOrderData.id})[0]);
				if (index > -1) {
					$scope.data.orders[index] = data;
					$('#orderEdit').modal('hide');
				}
			}).error(function() {
				alert('Şuan bir hata oluştu lütfen daha sonra yeniden deneyiniz.');
			});
		};

		$scope.calculateDiff = function (start,end) {
			return moment(end).diff(moment(start), 'minute');
		}

		$scope.getBatteryInfo = function(worker) {
			if(worker.location_info.length != 0) {
				var extra = angular.fromJson(worker.location_info);
				if(typeof extra.battery !== 'undefined')
					return parseFloat(extra.battery * 100).toFixed(0);
			}else {
				return 'Bilgi Yok';
			}
		}

		$scope.getLastLocationTime = function(worker) {
			if(worker.location_info.length > 0) {
				var extra = angular.fromJson(worker.location_info);
				return moment(extra.timestamp).format('D MMM, HH:mm');
			}else {
				return 'Bilgi Yok';
			}
		}

		function infoWindow(data,type) {
			if($scope.markers['worker'][data.id]) {

				var extra = angular.fromJson(data.location_info),
				content = '<div id="iw-container">' +
					'<div class="iw-content"> <div class="btn-group pull-right">' +
					'<a href="#" onclick="angular.element(this).scope().zoomIn('+data.lat+','+data.lon+')" class="btn btn-xs btn-warning">Yaklaş</a>' + 
					'<a href="#" onclick="angular.element(this).scope().getWorkerDetails('+data.id+')" class="btn btn-xs btn-primary">Detaylar</a> </div>' + 
					'<div class="iw-subTitle">'+data.full_name+'</div>' +
					'<img src="https://s3.eu-central-1.amazonaws.com/bovo/'+ data.profile_photo +'" class="img-circle" height="90" width="90">'
					//'<p><strong> Şarj : </strong>'+Math.round(extra.battery * 100 )+' %</p>' +
					//'<p><strong> Doğruluk : </strong>'+Math.round(extra.accuracy)+' Metre</p>' +
					//'<p><strong> Hareket Durumu : </strong>'+getActivity(extra.activity)+'</p>'
					;
					
				// if($scope.authenticatedUser.setting.last_location_time) {
				// 	content += '<p><strong> Son Konum Zamanı :</strong>'+moment(extra.timestamp).format('D MMM, HH:mm');
				// }
				content +=	'</div></div>';
				var infowindow = new google.maps.InfoWindow({
					content: content,
					maxWidth: 360
				});
				google.maps.event.addListener(infowindow,'closeclick',function(){
				   if(routeOptimizationWorkerOrders.length > 0){
						for (var i = 1; i < routeOptimizationWorkerOrders.length; i++) {
							$scope.markers['order'][routeOptimizationWorkerOrders[i]].setLabel({
							    text: " ",
							    color: 'white',
							    fontSize: "10px",
								fontWeight: 'bold'
							});
						}
						routeOptimizationDirectionsDisplay.setMap(null);
					}
				});

				if(typeof $scope.infowindows[data.id] === 'undefined') {
					$scope.infowindows[data.id] = infowindow;
					$scope.markers['worker'][data.id].addListener('click', function() {
						infowindow.open($scope.map, $scope.markers['worker'][data.id]);
					});
				}else {
					$scope.infowindows[data.id].setContent(content);
				}
				
				if(type == 'open') $scope.infowindows[data.id].open($scope.map, $scope.markers['worker'][data.id]);
			}
		}

		function getActivity(activity) {
			var type = '';
			switch (activity) {
				case 'still':
					type = 'Beklemede';
					break;
				case 'on_foot':
					type = 'Yürüyüş';
					break;
				case 'in_vehicle':
					type = 'Araçta';
					break;
				case 'on_bicycle':
					type = 'Motor - Bisiklet';
					break;
				case 'running':
					type = 'Yürüyüş';
					break;
			
				default:
					type = 'Tanımlanamadı';
					break;

			}
			return type;
		}

		$scope.changeWorkerMarker = function(worker) {
			if(typeof $scope.markers['worker'][worker.id] !== 'undefined') {
				
				var icon = getIcon(worker.icon),
					title = worker.status == 1 ? 'location' : 'offline';

				if(icon) {
					icon = {
						path: icon,
						scale: 0.5,
						strokeWeight: 0.2,
						strokeColor: worker.icon_color,
						strokeOpacity: 1,
						fillColor: worker.icon_color,
						fillOpacity: 0.9,
					};
					title = "offline";
				}else {
					icon = 'img/markers/worker_' + worker.status + '.png';
				}

				$scope.markers['worker'][worker.id].setIcon(icon);
				$scope.markers['worker'][worker.id].setTitle(title);
				infoWindow(worker);				
			}
		}

		$scope.createWorkerMarker = function (worker) {
			if(typeof worker.lat === 'undefined') return false;
			if(typeof $scope.markers['worker'][worker.id] !== 'undefined') {
				
				var location = new google.maps.LatLng(Number(worker.lat),Number(worker.lon));

				$scope.markers['worker'][worker.id].setDuration(1000);
				$scope.markers['worker'][worker.id].setEasing("swing");
				$scope.markers['worker'][worker.id].setPosition(location);
				infoWindow(worker);				
			}else {
				if(worker.lat.length == 0) return false;

				var icon = getIcon(worker.icon),
					title = worker.status == 1 ? 'location' : 'offline';

				if(icon) {
					icon = {
						path: icon,
						scale: 0.5,
						strokeWeight: 0.2,
						strokeColor: worker.icon_color,
						strokeOpacity: 1,
						fillColor: worker.icon_color,
						fillOpacity: 0.9,
					};
					title = "offline";
				}else {
					icon = 'img/markers/worker_' + worker.status + '.png';
				}
				var	marker = new SlidingMarker({
					position: new google.maps.LatLng(Number(worker.lat),Number(worker.lon)),
					map: $scope.map,
					icon: icon,
					title: title,
					visible: true,
					optimized: false
				});

				infoWindow(worker);				
				marker.addListener('click', function() {
					$scope.detailsWorker(worker);
				});
				$scope.markers['worker'][worker.id] = marker;
			}
		}

		function getIcon(type) {
			var fontawesomeIcon;
			switch (type) {
				case 'truck':
					fontawesomeIcon = "M23.04-13.751q0-1.872-1.368-3.24t-3.24-1.368-3.24 1.368-1.368 3.24 1.368 3.24 3.24 1.368 3.24-1.368 1.368-3.24zm-13.824-18.432h13.824v-9.216h-5.688q-.468 0-.792.324l-7.02 7.02q-.324.324-.324.792v1.08zm46.08 18.432q0-1.872-1.368-3.24t-3.24-1.368-3.24 1.368-1.368 3.24 1.368 3.24 3.24 1.368 3.24-1.368 1.368-3.24zm9.216-39.168v36.864q0 .54-.144.954t-.486.666-.594.414-.846.216-.81.072-.918 0-.81-.018q0 3.816-2.7 6.516t-6.516 2.7-6.516-2.7-2.7-6.516h-13.824q0 3.816-2.7 6.516t-6.516 2.7-6.516-2.7-2.7-6.516h-2.304q-.108 0-.81.018t-.918 0-.81-.072-.846-.216-.594-.414-.486-.666-.144-.954q0-.936.684-1.62t1.62-.684v-11.52q0-.288-.018-1.26t0-1.368.09-1.242.234-1.332.504-1.098.81-1.08l7.128-7.128q.684-.684 1.818-1.152t2.106-.468h5.76v-6.912q0-.936.684-1.62t1.62-.684h36.864q.936 0 1.62.684t.684 1.62z";
					break;
				case 'automobile':
					fontawesomeIcon = "M17.28-25.271q0-2.376-1.692-4.068t-4.068-1.692-4.068 1.692-1.692 4.068 1.692 4.068 4.068 1.692 4.068-1.692 1.692-4.068zm1.296-11.52h36.576l-3.204-12.852q-.072-.288-.504-.63t-.756-.342h-27.648q-.324 0-.756.342t-.504.63zm49.392 11.52q0-2.376-1.692-4.068t-4.068-1.692-4.068 1.692-1.692 4.068 1.692 4.068 4.068 1.692 4.068-1.692 1.692-4.068zm5.76-3.456v13.824q0 .504-.324.828t-.828.324h-3.456v4.608q0 2.88-2.016 4.896t-4.896 2.016-4.896-2.016-2.016-4.896v-4.608h-36.864v4.608q0 2.88-2.016 4.896t-4.896 2.016-4.896-2.016-2.016-4.896v-4.608h-3.456q-.504 0-.828-.324t-.324-.828v-13.824q0-3.348 2.358-5.706t5.706-2.358h1.008l3.78-15.084q.828-3.384 3.744-5.67t6.444-2.286h27.648q3.528 0 6.444 2.286t3.744 5.67l3.78 15.084h1.008q3.348 0 5.706 2.358t2.358 5.706z";
					break;
				case 'motorcycle':
					fontawesomeIcon = "M82.836-27.143q.432 3.708-.792 7.146t-3.564 5.886-5.706 3.816-7.074 1.116q-5.796-.396-10.062-4.5t-4.842-9.864q-.432-3.996.99-7.578t4.266-6.138l-2.556-3.852q-3.456 2.88-5.436 6.984t-1.98 8.784q0 .972-.666 1.674t-1.638.702h-11.7q-.828 5.904-5.364 9.864t-10.584 3.96q-6.66 0-11.394-4.734t-4.734-11.394 4.734-11.394 11.394-4.734q2.736 0 5.472.972l.864-1.62q-4.428-3.96-10.944-3.96h-2.304q-.936 0-1.62-.684t-.684-1.62.684-1.62 1.62-.684h4.608q2.808 0 5.22.486t4.194 1.386 2.574 1.422 1.836 1.314h22.572l-3.06-4.608h-7.992q-1.08 0-1.764-.81t-.504-1.89q.144-.828.828-1.368t1.548-.54h9.108q1.188 0 1.908 1.008l2.52 3.78 4.104-4.104q.684-.684 1.656-.684h3.636q.936 0 1.62.684t.684 1.62v4.608q0 .936-.684 1.62t-1.62.684h-6.444l4.14 6.192q4.716-2.268 9.9-1.296 5.148.936 8.784 4.842t4.248 9.126zm-66.708 13.392q4.14 0 7.308-2.61t3.996-6.606h-11.304q-1.26 0-1.98-1.116-.648-1.152-.036-2.268l5.292-9.972q-1.692-.468-3.276-.468-4.752 0-8.136 3.384t-3.384 8.136 3.384 8.136 8.136 3.384zm50.688 0q4.752 0 8.136-3.384t3.384-8.136-3.384-8.136-8.136-3.384q-2.16 0-4.356.864l6.264 9.36q.54.828.36 1.764t-.972 1.44q-.54.396-1.296.396-1.26 0-1.908-1.044l-6.264-9.36q-3.348 3.42-3.348 8.1 0 4.752 3.384 8.136t8.136 3.384z";
					break;
				case 'bicycle':
					fontawesomeIcon = "M27.432-22.967h-11.304q-1.44 0-2.07-1.26t.234-2.412l6.768-9.036q-2.34-1.116-4.932-1.116-4.752 0-8.136 3.384t-3.384 8.136 3.384 8.136 8.136 3.384q4.14 0 7.308-2.61t3.996-6.606zm-6.696-4.608h6.696q-.648-3.06-2.7-5.328zm17.28 0l10.368-13.824h-17.28l-3.564 4.752q3.78 3.708 4.536 9.072h5.94zm40.32 2.304q0-4.752-3.384-8.136t-8.136-3.384q-2.16 0-4.356.864l6.264 9.36q.54.828.36 1.764t-.972 1.44q-.54.396-1.296.396-1.26 0-1.908-1.044l-6.264-9.36q-3.348 3.42-3.348 8.1 0 4.752 3.384 8.136t8.136 3.384 8.136-3.384 3.384-8.136zm4.608 0q0 6.66-4.734 11.394t-11.394 4.734-11.394-4.734-4.734-11.394q0-3.492 1.422-6.606t3.942-5.382l-2.34-3.528-12.708 16.884q-.648.936-1.836.936h-7.092q-.828 5.904-5.364 9.864t-10.584 3.96q-6.66 0-11.394-4.734t-4.734-11.394 4.734-11.394 11.394-4.734q4.104 0 7.74 1.98l4.932-6.588h-8.064q-.936 0-1.62-.684t-.684-1.62.684-1.62 1.62-.684h13.824v4.608h15.66l-3.06-4.608h-7.992q-.936 0-1.62-.684t-.684-1.62.684-1.62 1.62-.684h9.216q1.188 0 1.908 1.008l9.612 14.4q3.276-1.584 6.912-1.584 6.66 0 11.394 4.734t4.734 11.394z";
					break;
				case 'walking':
					fontawesomeIcon = "M36.864-39.095v14.976q0 1.44-1.008 2.448t-2.448 1.008-2.448-1.008-1.008-2.448v-12.672h-2.304v32.832q0 1.656-1.188 2.844t-2.844 1.188-2.844-1.188-1.188-2.844v-16.704h-2.304v16.704q0 1.656-1.188 2.844t-2.844 1.188-2.844-1.188-1.188-2.844v-32.832h-2.304v12.672q0 1.44-1.008 2.448t-2.448 1.008-2.448-1.008-1.008-2.448v-14.976q0-2.88 2.016-4.896t4.896-2.016h23.04q2.88 0 4.896 2.016t2.016 4.896zm-10.368-16.128q0 3.348-2.358 5.706t-5.706 2.358-5.706-2.358-2.358-5.706 2.358-5.706 5.706-2.358 5.706 2.358 2.358 5.706z";
					break;
				default:
					fontawesomeIcon = false;
					break;
			}
			return fontawesomeIcon;
		}

		function addCSSRule(sheet, selector, rules, index) {
			if ("insertRule" in sheet) {
				sheet.insertRule(selector + "{" + rules + "}", index);
			} else if ("addRule" in sheet) {
				sheet.addRule(selector, rules, index);
			}
		}

		$scope.createOrderMarker = function (order) {
			if($scope.markers['order'].indexOf(order.id) > -1) {
				$scope.markers['order'][order.id].setIcon(icon);
			}else if(order.location) {
				var location = new google.maps.LatLng(Number(order.location.latitude),Number(order.location.longitude));
	
                if(typeof order.status !== 'undefined' && order.status != null) {
                    //var icon = window.location.origin + '/marker?hex=' + order.status.color.substring(1);
                    //console.log(order.status.color.substring(1));
                    var pinImage = {
                        url: "/img/markers/"+order.status.color.substring(1)+".png",
                        scaledSize: new google.maps.Size(30, 46),
                    };
                }else {
                    var pinImage = new google.maps.MarkerImage("https://chart.googleapis.com/chart?chst=d_map_pin_letter&chld=%7C333333");
                }
                
                var marker = new SlidingMarker({
                    position: location,
                    map: $scope.map,
                    icon: pinImage,
                    title: "order_location",
                    visible: true
                });

				var content = '<div id="iw-container">' +
					'<div class="iw-content"> <div class="btn-group pull-right">' +
					'<a href="#" onclick="angular.element(this).scope().zoomIn('+order.location.latitude+','+order.location.longitude+')" class="btn btn-xs btn-warning">Yaklaş</a>' + 
					'<a href="#" onclick="angular.element(this).scope().getOrderDetails('+order.id+')" class="btn btn-xs btn-primary">Detaylar</a> </div>' + 
					'<strong>#' + order.id  +'</strong>';
				
				if(order.name && order.name != null)
					content += ' - ' + order.name;
				
					
				content += '<hr style="margin:17px 0">' +
					'<p><strong>'+order.location.name+' </strong></p>' +
					'<p>'+order.location.address+'</p>';

				if(order.worker)
					content += '<p><strong>Personel : </strong> '+order.worker.full_name+'</p>';
				content += '</div></div>';
				var infowindow = new google.maps.InfoWindow({
					content: content,
					maxWidth: 360
				});
				marker.addListener('click', function() {
					infowindow.open($scope.map, marker);
				});

				$scope.markers['order'][order.id] = marker;
				$scope.infowindowsOrder[order.id] = infowindow;
			}
		}

		$scope.updateOrderMarker = function(order) {
			if($scope.markers['order'][order.id]) {
				var content = '<div id="iw-container">' +
					'<div class="iw-content"> <div class="btn-group pull-right">' +
					'<a href="#" onclick="angular.element(this).scope().zoomIn('+order.location.latitude+','+order.location.longitude+')" class="btn btn-xs btn-warning">Yaklaş</a>' + 
					'<a href="#" onclick="angular.element(this).scope().getOrderDetails('+order.id+')" class="btn btn-xs btn-primary">Detaylar</a> </div>' + 
					'<strong>#' + order.id  +'</strong>';
				
				if(order.name && order.name != null)
					content += ' - ' + order.name;
				
					
				content += '<hr style="margin:17px 0">' +
					'<p><strong>'+order.location.name+' </strong></p>' +
					'<p>'+order.location.address+'</p>';

				if(order.worker)
					content += '<p><strong>Personel : </strong> '+order.worker.full_name+'</p>';
				content += '</div></div>';
				$scope.infowindowsOrder[order.id].setContent(content);
			}
		}


		$scope.zoomIn = function(lat,lng) {
			$scope.map.panTo(new google.maps.LatLng(Number(lat),Number(lng)));
			$scope.map.setZoom(16);
		}

		$scope.getNumber = function(num) {
			return new Array(num);
		}

		function createContextMenu() {
			var contextMenuOptions={
				classNames : {
					menu:'dropdown-menu', 
					menuSeparator:'divider'
				},
				menuItems : [{
					className:'item', 
					eventName:'create_order', 
					label:'Buraya Noktaya İş Oluştur'
				},
				{},
				{
					className:'item', 
					eventName:'center_map_click', 
					label:'Haritayı Ortala'
				},
				{
					className:'item', 
					eventName:'zoom_in_click', 
					label:'Yaklaş'
				},
				{
					className:'item', 
					eventName:'zoom_out_click', 
					label:'Uzaklaş'
				}],
				pixelOffset: new google.maps.Point(10, -5),
				zIndex: 5
			};
			//	create the ContextMenu object
			var contextMenu = new ContextMenu($scope.map, contextMenuOptions);

			//	display the ContextMenu on a Map right click
			google.maps.event.addListener($scope.map, 'rightclick', function(mouseEvent){
				contextMenu.show(mouseEvent.latLng);

			});

			google.maps.event.addListener(contextMenu, 'menu_item_selected', 
				function(latLng, eventName, source){
				switch(eventName) {
					case 'zoom_in_click':
						$scope.map.setZoom($scope.map.getZoom()+1);
						break;
					case 'zoom_out_click':
						$scope.map.setZoom($scope.map.getZoom()-1);
						break;
					case 'center_map_click':
						$scope.map.panTo(latLng);
						break;
					case 'create_order':
						geocoder = new google.maps.Geocoder();
						geocoder.geocode({
							'latLng': latLng
						}, function (results, status) {
							if (status === google.maps.GeocoderStatus.OK) {
								var componentForm = {
									street_number: 'short_name',
									route: 'long_name',
									locality: 'long_name',
									administrative_area_level_1: 'short_name',
									administrative_area_level_2: 'short_name',
									country: 'long_name',
									postal_code: 'short_name'
								},
								mapping = {
									street_number: 'number',
									route: 'street',
									locality: 'state',
									administrative_area_level_1: 'city',
									administrative_area_level_2: 'district',
									country: 'country',
									postal_code: 'zip'
								},
								details;
								for (var i = 0; i < results[0].address_components.length; i++) {
									var addressType = results[0].address_components[i].types[0];
									if (componentForm[addressType]) {
										var val = results[0].address_components[i][componentForm[addressType]];
										details[mapping[addressType]] = val;
									}
								}
								details.formatted = results[0].formatted_address;
								details.address = results[0].formatted_address;
								details.placeId = results[0].place_id;
								console.log(details);
							}
						});
						break;
				}
			});
		}

		$scope.getAddressFromCoord = function(coord) {

			if(coord.lat != '') {
				var url = "//maps.googleapis.com/maps/api/geocode/json?latlng=" + coord.lat + "," + coord.lon +"&key=AIzaSyDxjEa1-VMmyYdudc4Vmsfa8XBGlnxubco";
				Order.getAddressFromCoord(url).success(function(data, status, headers, config) {
					console.log(angular.fromJson(data));
				});
			}
		}

		$scope.sidebar = function(e) {
			if(e == 'left') {
				$scope.filterPanel = false;
				$("#sidebar_right").toggle();
				if(!$("#sidebar_right").is( ":visible" ))
				$(".sidebar_left_toggle").css({left: 0}).html('<i class="fa fa-arrow-right"></i>');
				else
				$(".sidebar_left_toggle").css({left: '310px'}).html('<i class="fa fa-arrow-left"></i>');
			}else {
				$(".sidebar_worker").toggle();
				if(!$(".sidebar_worker").is( ":visible" ))
				$(".sidebar_right_toggle").css({right: 0}).html('<i class="fa fa-arrow-left"></i>');
				else
				$(".sidebar_right_toggle").css({right: '260px'}).html('<i class="fa fa-arrow-right"></i>');
			}
		}

		var directionsService = [],
			directionsDisplay = [];

		$scope.getRemainingTime = function (id,loading, direction) {

			Order.remainingTime(id,loading).success(function(data, status, headers, config) {
				var order = find($scope.data.orders, id);
				
				order.dist = data.dist;

				if(calculatedOrder.indexOf(id) == -1)
				calculatedOrder.push(id);

				if(typeof direction === 'undefined') {

					directionsService[order.id] = new google.maps.DirectionsService;
					directionsDisplay[order.id] = new google.maps.DirectionsRenderer({
						suppressMarkers: true
					});

					directionsDisplay[order.id].setMap($scope.map);
					calculateAndDisplayRoute(
						directionsService,
						directionsDisplay,
						data.coords[0],
						data.coords[1],
						data.coords[2],
						data.coords[3], 
						order.id
					);
				}

				if (typeof $scope.calculaterInterval == 'undefined') {
					$scope.calculaterInterval = setInterval(function() { 
						calculatedOrder.forEach(function(element) {
							$scope.getRemainingTime(element,true,false);
						});
					}, 60000);
				}

			});
		};

		function calculateAndDisplayRoute(directionsService, directionsDisplay , workLat, workLon, workerLat, workerLon, orderID) {
			var org = new google.maps.LatLng ( workLat,  workLon ),
				dest = new google.maps.LatLng ( workerLat, workerLon );

			var display = directionsService[orderID].route({
				origin: org,
				destination: dest,
				travelMode: google.maps.DirectionsTravelMode.DRIVING
			}, function(response, status) {
				if (status === 'OK') {

					directionsDisplay.forEach(function(element) {
						if(element != directionsDisplay[orderID]) {
							element.setMap(null);
						}
					}, this);

					directionsDisplay[orderID].setDirections(response);
				}
			});
		}

		function makeRoute(start,end,waypts,allID){
			if(routeOptimizationWorkerOrders.length > 0){

				for (var i = 1; i < routeOptimizationWorkerOrders.length; i++) {
					$scope.markers['order'][routeOptimizationWorkerOrders[i]].setLabel({
					    text: " ",
					    color: 'white',
					    fontSize: "10px",
						fontWeight: 'bold'
					});
				}
				routeOptimizationDirectionsDisplay.setMap(null);
			}
			

            var directionsDisplay;
            var directionsService = new google.maps.DirectionsService();
            directionsDisplay = new google.maps.DirectionsRenderer({
                suppressMarkers: true, //false it if you want a marker from the direction service
                polylineOptions: {
                    strokeColor: 'green', 
                    strokeOpacity: 1.0,
                    strokeWeight: 3,
                    icons:[{repeat:'300px',icon:{path:google.maps.SymbolPath.FORWARD_CLOSED_ARROW}}]
                }
            });
            if(waypts.length > -1) {
                var request = {
					origin:start,
					destination:end,
					waypoints: waypts,
					optimizeWaypoints: true,
					travelMode: google.maps.DirectionsTravelMode.DRIVING
				};
                directionsService.route(request, function(response, status) {
                    if (status == google.maps.DirectionsStatus.OK) {
                        directionsDisplay.setDirections(response);
			            var my_route = response.routes[0];
			            var clickIcon = {};

			            for (var i = 0; i < my_route.legs.length; i++) {
			            	if(i == 0){
			                	continue;	
			            	}else{
				    
				                $scope.markers['order'][allID[i]].setLabel({
								    text: ""+i,
								    color: 'white',
								    fontSize: "10px",
									fontWeight: 'bold'
								  	});

				                clickIcon = {
				                	url: $scope.markers['order'][allID[i]]['icon']['url'],
								    fillColor: "#666666",
								    fillOpacity: 1,
								    strokeColor: "#666666",
								    strokeWeight: 1,
								    labelOrigin: new google.maps.Point(15, 19),
									anchor: new google.maps.Point(9, 35),
									scaledSize: new google.maps.Size(30, 46),
								};

			             		$scope.markers['order'][allID[i]].setIcon(clickIcon);
			            	}
			            }
			
			            $scope.markers['order'][allID[allID.length-1]].setLabel({
								    text: "son",
								    color: 'white',
								    fontSize: "9px",
									fontWeight: 'bold'
								  	});

			            clickIcon = {
				                	url: $scope.markers['order'][allID[allID.length-1]]['icon']['url'],
								    fillColor: "#666666",
								    fillOpacity: 1,
								    strokeColor: "#666666",
								    strokeWeight: 1,
								    labelOrigin: new google.maps.Point(10, 10),
								    anchor: new google.maps.Point(9, 35),
								};
			            $scope.markers['order'][allID[allID.length-1]].setIcon(clickIcon);
                    }
                });
            }
            routeOptimizationDirectionsDisplay = directionsDisplay;
         	routeOptimizationDirectionsDisplay.setMap($scope.map);
         	routeOptimizationWorkerOrders = allID;
        }
	}
]);