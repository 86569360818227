bovoApp.controller('PricingCityController', ['$scope', 'PricingCity', '$location', '$routeParams', '$filter', '$translate',
  function ($scope, PricingCity, $location, $routeParams, $filter, $translate) {
    $scope.deleted = [];

    $scope.all = function () {
      PricingCity.all().success(function (data, status, headers, config) {
        $scope.data = data;
      });
    }

    $scope.openDetail = function (item) {
      PricingCity.get(item.id).success(function (data, status, headers, config) {
        $('#pricingCityModal').modal('show');
        $scope.pricingCity = data.pricingCity;
        $scope.data.cities = data.cities;
      });
    }

    $scope.$on('new_data', function () {
      $('#pricingCityModal').modal('show');
      $scope.new();
    }, true);

    $scope.new = function () {
      PricingCity.new().success(function (data, status, headers, config) {
        $scope.data.cities = data.cities;
        $scope.pricingCity = {
          arrival_cities: []
        };
      });
    }

    $scope.addItem = function () {
      $scope.pricingCity.arrival_cities.push({
        city_id: null,
      });
    }

    $scope.removeItem = function (index) {
      $scope.pricingCity.arrival_cities.splice(index, 1);
    }

    $scope.delete = function (type) {
      if (type == 'set') {
        $('#delete').modal('show');
      } else if (type == 'delete') {
        PricingCity.delete($scope.deleted).success(function (data, status, headers, config) {
          Messenger().post('Silindi');
          $scope.all()
          $scope.deleted = [];
          $('#delete').modal('hide');
        }).error(function (data) {
          Messenger().post({
            message: data.message,
            type: "error"
          });
        });
      }
    }

    $scope.postForm = function (user) {
      if ($scope.pricingCity.id) {
        $scope.update();
      } else {
        $scope.save();
      }
    }

    $scope.save = function () {
      PricingCity.save($scope.pricingCity).success(function (data, status, headers, config) {
        $scope.data = data;
        Messenger().post('Başarılı');
        $('#pricingCityModal').modal('hide');
        $scope.all();
      }).error(function (data) {
        Messenger().post({
          message: data.message,
          type: "error"
        });
      });
    }

    $scope.update = function () {
      PricingCity.update($scope.pricingCity).success(function (data, status, headers, config) {
        $scope.data = data;
        Messenger().post('Başarılı');
        $('#pricingCityModal').modal('hide');
        $scope.all();
      }).error(function (data) {
        Messenger().post({
          message: data.message,
          type: "error"
        });
      });
    }

    $scope.toggleSelection = function toggleSelection(id) {
      var idx = $scope.deleted.indexOf(id);
      if (idx > -1) {
        $scope.deleted.splice(idx, 1);
      } else {
        $scope.deleted.push(id);
      }
    };

  }
]);