bovoApp.
  factory('OrderMovement', ['$http', function ($http) {
    return {
      movement: function (data) {
        return $http.post('api/order-movement', data);
      },
      bulkComplete: function (data) {
        return $http.post('api/bulk-complete', data);
      },
      movementInit: function (data) {
        return $http.get('api/order-movement');
      },
      getBatch: function (data) {
        return $http.get('api/movement-batch', { params: data });
      },
      getDebitList: function (data) {
        return $http.post('api/order-debit-list', data);
      },
      downBatch: function (data) {
        return $http.post('api/down-batch', data);
      },
      findLineVehicle: function (data) {
        return $http.post('api/find-line-vehicle', data);
      },
    };
  }]);