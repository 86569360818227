bovoApp.controller('CustomerBillingController', ['$scope', 'Analytic', '$location', '$http',
    function($scope, Analytic, $location, $http) {

        $scope.filterProp = [];

        $scope.filterData = {
            export: false,
            term: '',
            page: 1,
            paginate : 25,
            date: {
                startDate: null,
                endDate: null,
            },
        }

        $scope.selectedItems = [];

        $scope.clearFilter = function(page) {
            $scope.filterData = {
                export: false,
                term: '',
                page: 1,
                paginate : 25,
                date: {
                    startDate: null,
                    endDate: null,
                },
            }
            $scope.filterProp = [];
            $scope.init();
        }

        $scope.$on('refresh_data', function() {
            $scope.init();
        }, true);

        // $scope.$watch('filterData', function() {
        //  $scope.init();
        // }, true);

        $scope.printInvoice = function(item) {
            // if($scope.selectedItem) {
            var win = window.open('/print-invoice?start=' + item.id + '&end=' + item.end, '_blank');
            win.focus();
            // }else {
            //  alert('Please select row!');
            // }
        };

        $scope.init = function(type) {
            Analytic.customerBilling($scope.filterData).success(function(data, status, headers, config) {
                $scope.data = data;
                if (data.invoices.data.length === 0) {
                    $scope.isEmpty = true;
                }else {
                    $scope.isEmpty = false;
                }
            });
        };

        $scope.accountingOrders = function(item) {
            $scope.selectedItem = item;
            Analytic.accountingOrders(item).success(function(data, status, headers, config) {
                $scope.data.orders = data;
            });
        };

        // billing card check or uncheck
        $scope.checkBilling = function(item) {
            if ($scope.selectedItems.indexOf(item) === -1) {
                $scope.selectedItems.push(item);
            } else {
                $scope.selectedItems = $scope.selectedItems.filter(id => id !== item);
            }
        };

        $scope.selectAllBilling = function(selectAllBilling) {
            if (selectAllBilling) {
                $scope.selectedItems = $scope.data.invoices.data.map((count) => {
                    // if(!count.status) {
                        return count.id
                    // }
                });
            } else {
                $scope.selectedItems = [];
            }
        }


        $scope.setDate = function(date) {
            $scope.filterData.date = date;
            $scope.init();
        }

        $scope.filter = function(type) {
            if (type == 'clear') {
                $scope.filterData = {
                    date: {
                        startDate: null,
                        endDate: null,
                    },
                }
            }
            $scope.init();
        }

		$scope.sort = function(field,sort) {
			$scope.filterData.field = field;
			$scope.filterData.sort = sort;
			$scope.init();
        }
        
        $scope.showCompletedOrders = function(invoice) {
            var data  = {
                invoice_id : invoice.id,
                scheduled_time : {
                    startDate: moment(invoice.date).startOf('day'),
                    endDate: moment(invoice.date).endOf('day'),
                },
                workSteps : [$scope.data.completed.id.toString()]
            }
            var url = 'customer-orders?data=' + JSON.stringify(data);
            window.open(url, '_blank');
        }


    }
]);