bovoApp.
  factory('Announcement', ['$http', function ($http) {
    return {
      all: function (type) {
        return $http.get('api/announcements');
      },
      get: function (id) {
        return $http.get('api/announcement/' + id);
      },
      new: function (id) {
        return $http.get('api/new-announcement');
      },
      delete: function (id) {
        return $http.delete('api/delete-announcement/' + id);
      },
      save: function (data) {
        return $http.post('api/save-announcement', data);
      },
      update: function (data) {
        return $http.post('api/update-announcement', data);
      },
      seen: function (data) {
        return $http.post('api/seen-announcement', data);
      }
    };
  }]);