bovoApp.controller('PerformanceController', ['$scope', 'Performance', '$location', '$filter', '$routeParams',
  function ($scope, Performance, $location, $filter, $routeParams) {
    $scope.deleted = [];
    $scope.performanceData = [];
    $scope.filterData = {
      customers: [],
      stations: [],
      date_type: 'estimated_delivery_date',
      scheduled_time: {
        startDate: null,
        endDate: null
      },
      cargo_date: {
        startDate: null,
        endDate: null
      },
      performance_type: null
    }

    $scope.commonPerformance = function () {
      Performance.commonPerformance($scope.filterData).success(function (data, status, headers, config) {
        $scope.performanceData = data;
        $scope.performanceData.forEach(element => {
          element.pure_performance = (element.on_time.length * 100) / element.success;
          element.bovo_performance = ((element.on_time.length + element.fail_customer.length + element.fail_mc.length) * 100) / element.success;
          element.station_performance = ((element.on_time.length + element.fail_customer.length + element.fail_mc.length + element.fail_bovo.length) * 100) / element.success;
        });

      });
    }

    $scope.calculateAvg = function (data) {
      var avg = 0;
      data.forEach(element => {
        avg = avg + Number(element.day_diff)
      });

      return (Number(avg) / Number(data.length)).toFixed(2);
    }

    $scope.$on('refresh_data', function () {
      console.log('refreshing customers...')
      $scope.commonPerformance();
    }, true);

    $scope.openDetail = function (item) {
      Performance.showOrders({ ids: item }).success(function (data, status, headers, config) {
        $scope.data = data;
        $('#performanceModal').modal('show');
      });
    }

  }
]);
