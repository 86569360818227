bovoApp.controller('ProductController', ['$scope', 'Product', '$location', '$routeParams', '$filter', '$translate',
  function ($scope, Product, $location, $routeParams, $filter, $translate) {
    $scope.deleted = [];
    $scope.filterData = {
      station_id: [],
      price: '',
      vehicle: [],
      page: 1,
      date: {
        startDate: null,
        endDate: null
      }
    }

    $scope.clearFilter = function (page) {
      $scope.filterData = {
        station_id: [],
        price: '',
        vehicle: [],
        page: 1,
        date: {
          startDate: null,
          endDate: null
        }
      }
      $scope.all();
    }

    $scope.product = {
      prices: [{
        name: null,
        price: null
      }]
    }


    $scope.selectAll = function (select) {
      if (select) {
        $scope.deleted = [];
        for (let i = 0; i < $scope.data.products.data.length; i++) {
          $scope.deleted.push($scope.data.products.data[i].id);
        }
      } else {
        $scope.deleted = [];
      }
    };

    $scope.$on('refresh_data', function () {
      console.log('refreshing map...')
      $scope.all();
    }, true);

    $scope.$on('new_data', function () {
      $('#newProduct').modal('show');
      $scope.new();
    }, true);

    $scope.getPage = function (page) {
      $scope.filterData.page = page;
      $scope.all();
    }

    $scope.all = function () {
      Product.all($scope.filterData).success(function (data, status, headers, config) {
        $scope.data = data;
      });
    }

    $scope.editProduct = function (product) {
      $scope.product = product;
      $('#newProduct').modal('show');
      $('#productDetail').modal('hide');
    }

    $scope.get = function () {
      Product.get($routeParams.id).success(function (data, status, headers, config) {
        $scope.data = data;
      });
    }

    $scope.new = function () {
      Product.new().success(function (data, status, headers, config) {
        $scope.product = {
          prices: [{
            name: null,
            price: null
          }]
        };
      });
    }

    $scope.delete = function (type) {
      if (type == 'set') {
        $('#delete').modal('show');
      } else if (type == 'delete') {
        Product.delete($scope.deleted).success(function (data, status, headers, config) {
          Messenger().post(data.message);
          for (var i = $scope.deleted.length - 1; i >= 0; i--) {
            var index = $scope.data.products.data.indexOf($filter('filter')($scope.data.products.data, {
              id: $scope.deleted[i]
            })[0]);
            if (index > -1)
              $scope.data.products.data.splice(index, 1);
          }
          $scope.deleted = [];
          $('#delete').modal('hide');
        }).error(function (data) {
          Messenger().post({
            message: data.message,
            type: "error"
          });
        });
      }
    }

    $scope.save = function () {
      if ($scope.product.id) {
        $scope.update();
      } else {
        Product.save($scope.product).success(function (data, status, headers, config) {
          $scope.data = data;
          Messenger().post($translate.instant(data.message));
          $('#newProduct').modal('hide');
          $scope.all();
        }).error(function (data) {
          Messenger().post({
            message: data.message,
            type: "error"
          });
        });
      }
    }

    $scope.update = function () {
      Product.update($scope.product).success(function (data, status, headers, config) {
        $scope.openDetail($scope.product)
        Messenger().post($translate.instant(data.message));
        $('#newProduct').modal('hide');
        $scope.all();
      }).error(function (data) {
        Messenger().post({
          message: data.message,
          type: "error"
        });
      });
    }

    $scope.openDetail = function (data) {
      Product.get(data.id).success(function (data, status, headers, config) {
        $scope.stations = data.stations;
        $scope.product = data.product;
        $('#productDetail').modal('show');
      });
    }

    $scope.toggleSelection = function toggleSelection(id) {
      var idx = $scope.deleted.indexOf(id);
      if (idx > -1) {
        $scope.deleted.splice(idx, 1);
      } else {
        $scope.deleted.push(id);
      }
    };

    $scope.addPrice = function () {
      $scope.product.prices.push({
        name: null,
        price: null,
      });
    }

    $scope.removePrice = function (index) {
      $scope.product.prices.splice(index, 1);
    }
  }
]);