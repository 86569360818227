bovoApp.controller('OrderListController', ['$scope', 'Order', '$location', '$filter', '$routeParams', '$rootScope', 'Analytic',
	function ($scope, Order, $location, $filter, $routeParams, $rootScope, Analytic) {

		$scope.filterProp = [];
		$scope.selectedItem = [];

		$scope.calendarView = 'month';

		$scope.addSelectedItem = function (item) {

			if ($scope.selectedItem.indexOf(item) > -1) {
				$scope.selectedItem.splice($scope.selectedItem.indexOf(item), 1);
			} else {
				$scope.selectedItem.push(item);
			}
		}

		$scope.selectAll = function (select) {
			if (select) {
				$scope.selectedItem = [];
				for (let i = 0; i < $scope.data.orders.data.length; i++) {
					$scope.selectedItem.push($scope.data.orders.data[i].id);
				}
			} else {
				$scope.selectedItem = [];
			}
		};

		$scope.$on('updateOrder', function (event, data, nextParams, fromState) {
			var order = find($scope.data.orders.data, data.id);
			angular.merge(order, data);
			// $scope.createOrderMarker(order);
		});

		function find(data, find) {
			var index = data.indexOf($filter('filter')(data, {
				id: find
			})[0]);
			if (index > -1)
				return data[index];
		}

		$scope.filterData = {
			customers: [],
			workers: [],
			workSteps: [],
			page: 1,
			created_at: {
				startDate: null,
				endDate: null
			},
			scheduled_time: {
				startDate: null,
				endDate: null
			}
		}

		$scope.addFilterProps = function (item) {
			if ($scope.filterProp.indexOf(item) === -1)
				$scope.filterProp.push(item);
			else {
				var index = $scope.filterProp.indexOf(item);
				$scope.filterProp.splice(index, 1);
				if (item != 'term') {
					$scope.filterData[item] = [];
				} else {
					$scope.filterData[item] = "";
				}
				if (item == 'date') {
					delete $scope.filterData.startDate;
					delete $scope.filterData.endDate;
				}
				if ($scope.filterProp.length == 0) {
					$scope.filter('clear');
				}
			}
		}

		$scope.getPage = function (page) {
			$scope.filterData.page = page;
			$scope.filter();
		}

		$scope.sort = function (field, sort) {
			$scope.filterData.field = field;
			$scope.filterData.sort = sort;
			$scope.filter();
		}

		$scope.filter = function (type) {
			if (type == 'clear') {
				$scope.filterData = {
					customers: [],
					workers: [],
					workSteps: [],
					page: 1,
					created_at: {
						startDate: null,
						endDate: null
					},
					scheduled_time: {
						startDate: null,
						endDate: null
					}
				}
				$scope.filterProp = [];
			} else {
				$scope.filterData.filterProp = $scope.filterProp;
				if (typeof $scope.filterData.created_at.endDate !== 'undefined' && $scope.filterData.created_at.endDate !== null) {
					$scope.filterData.filterProp.created_at = 'created_at';
				}
				if (typeof $scope.filterData.scheduled_time.endDate !== 'undefined' && $scope.filterData.scheduled_time.endDate !== null) {
					$scope.filterData.filterProp.scheduled_time = 'scheduled_time';
				}
			}
			$scope.orders();
		}

		$scope.$on('refresh_data', function () {
			console.log('refreshing list...')
			$scope.filterData.page = 1
			$scope.orders();
		}, true);

		$scope.searchOrder = function (term) {
			$scope.filterData.term = term;
			$scope.orders();
		}

		$scope.clearFilter = function (page) {
			$scope.filterData = {
				term: '',
				workers: [],
				workSteps: [],
				page: 1,
				created_at: {
					startDate: null,
					endDate: null
				},
				scheduled_time: {
					startDate: null,
					endDate: null
				}
			}
			$scope.filterProp = [];
			$scope.orders();
		}

		$scope.orders = function (type) {
			var url = window.location.pathname

			if (url == '/orders') {
				if ($routeParams.invoice_id) {
					$scope.filterData.invoice_id = $routeParams.invoice_id;
				}

				if ($routeParams.batch_order_id) {
					$scope.filterData.batch_order_id = $routeParams.batch_order_id;
				}

				if ($routeParams.route_optimization_id) {
					$scope.filterData.route_optimization_id = $routeParams.route_optimization_id;
				}

				if ($routeParams.order_id) {
					$scope.getOrderDetails($routeParams.order_id);
				}
				if ($routeParams.real_order) {
					$scope.filterData.real_order = true;
					$rootScope.realOrder = true;
				} else {
					$rootScope.realOrder = false;
				}
				if ($routeParams.return_order) {
					$scope.filterData.return_order = true;
					$rootScope.returnOrder = true;
				} else {
					$rootScope.returnOrder = false;
				}
				if ($routeParams.transfer_order) {
					$scope.filterData.transfer_order = true;
					$rootScope.transferOrder = true;
				} else {
					$rootScope.transferOrder = false;
				}

				$rootScope.compensateFilter = false;
				if ($routeParams.compensate) {
					$scope.filterData.compensate = true;
					$rootScope.compensateFilter = true;
				} else {
					$rootScope.compensateFilter = false;
				}

				if ($routeParams.direction) {
					$scope.filterData.direction = $routeParams.direction;
				}

				if ($routeParams.work_type) {
					$scope.filterData.subWorkTypes = [$routeParams.work_type];
				}

				if ($routeParams.all) {
					$scope.filterData.all = true;
				}

				if (type == 'piece') {
					$scope.filterData.return_type = 'total_piece';
					$scope.filterData.ignoreLoadingBar = false;
				} else if (type == 'total') {
					$scope.filterData.return_type = 'total';
					$scope.filterData.ignoreLoadingBar = false;
				} else {
					$scope.filterData.return_type = null;
					$scope.filterData.ignoreLoadingBar = true;
				}

				Order.orders($scope.filterData, $scope.filterData.ignoreLoadingBar).success(function (data, status, headers, config) {
					if (type == 'piece') {
						$scope.data.orders.total_piece = data.total_piece;
					} else if (type == 'total') {
						$scope.data.orders.total = data.total;
					} else {
						$scope.data = data;
						if ($rootScope.realOrder) {
							$scope.data.steps.unshift({
								id: 0,
								name: 'Veri Oluşturuldu'
							});
						}
						$scope.orders('total');
						setTimeout(() => {
							$scope.orders('piece');
						}, 300);
						angular.merge($scope.filterData, $routeParams);
						if (typeof $scope.filterData.filterProp !== 'undefined') {
							$scope.filterProp = [];
							if (angular.isArray($scope.filterData.filterProp)) {
								for (var i = $scope.filterData.filterProp.length - 1; i >= 0; i--) {
									$scope.filterProp.push($scope.filterData.filterProp[i])
								}
							} else {
								$scope.filterProp.push($scope.filterData.filterProp);
							}
						}
					}
				});
			} else if (url == '/waiting-orders') {
				$scope.filterData.date_type = 'cargo_date';
				$scope.filterData.field = 'cargo_date';
				$scope.filterData.sort = 'asc';
				Order.oldOrder($scope.filterData, $scope.filterData.ignoreLoadingBar).success(function (data) {

					$scope.data = data
				})
			} else if (url == '/door-pay-orders') {
				$scope.filterData.date_type = 'cargo_date';
				$scope.filterData.field = 'cargo_date';
				$scope.filterData.sort = 'asc';
				Order.doorPayOrder($scope.filterData, $scope.filterData.ignoreLoadingBar).success(function (data) {

					$scope.data = data
				})
			} else if (url == '/notes-orders') {
				$scope.filterData.date_type = 'cargo_date';
				$scope.filterData.field = 'cargo_date';
				$scope.filterData.sort = 'asc';
				Order.notesOrder($scope.filterData, $scope.filterData.ignoreLoadingBar).success(function (data) {
					$scope.data = data
				})
			}


		};

		$scope.windowControl = window.location.pathname



		$scope.report = function (type) {
			Analytic.generalOrderReport($scope.filterData).success(function (data, status, headers, config) {
				$scope.reportData = data;
				$scope.series = ['Teslim Edilen', 'Teslimatta', 'Teslim Edilemeyen'];
				$scope.chartColors = [
					'#63BC46',
					'#4a5157',
					'#c93b2b',
				];
				$scope.chartData = [data.count.success, data.count.other, data.count.fail];
				$('#reportModal').modal('show');
			});
		};

		$scope.downloadZip = function () {

			Order.generateTotalOrdersReportInZip({ orders: $scope.selectedItem }).success(function (data, status, headers, config) {
				window.location.href = data['url'];
				// setTimeout(function(){
				// 	Order.removeDownloadFile({path: data['path']}).success(function(response, status, headers, config) {	
				// 		$scope.selectedItem = [];
				// 	});
				// },2000)
			});

		};

		$scope.downloadExcel = function (status) {
			if(!status) {
				$('#downloadExcelModal').modal('show');
			}else {
				data = $scope.filterData;
				data.orders = $scope.selectedItem;
				$('#downloadExcelModal').modal('hide');
				Order.generateExcelReport(data).success(function (data, status, headers, config) {
					if (data.type === 'download') {
						window.location.href = data['url'];
						setTimeout(function () {
							Order.removeDownloadFile({ path: data['path'] }).success(function (response, status, headers, config) {
								$scope.selectedItem = [];
							});
						}, 2000)
					} else {
						Messenger().post(data.message);
					}
				});
			}
		};

		$scope.toggleSelection = function toggleSelection(event) {

			if (event.target.checked) {

				$scope.selectedItem = [];

				for (var i = 0; i < $scope.data.orders.data.length; i++) {

					$scope.selectedItem.push($scope.data.orders.data[i].id);
				}
			} else {

				$scope.selectedItem = [];

			}
		};

		$scope.deleteOrders = function (send_cancel_sms) {
			Order.deleteOrders({ orders: $scope.selectedItem, send_cancel_sms: send_cancel_sms }).success(function (response, status, headers, config) {
				$scope.orders();
				$scope.selectedItem = [];
				$('#deleteOrdersModal').modal('toggle');
			});
		}
		$scope.sendOffer = function (send_cancel_sms) {
			Order.sendOffer({ orders: $scope.selectedItem }).success(function (response, status, headers, config) {
				$scope.orders();
				$scope.selectedItem = [];
				Messenger().post('Selected orders changed as offer!');
			});
		}
		$scope.toggleSelectionCustomer = function (event) {
			if (event.target.checked) {
				$scope.selectedItem = [];
				for (var i = 0; i < $scope.data.orders.data.length; i++) {
					// if($scope.data.orders.data[i].status) {
					// 	if($scope.data.orders.data[i].status.id != $scope.data.success.id && $scope.data.orders.data[i].status.id != $scope.data.fail.id) {
					// 		$scope.selectedItem.push($scope.data.orders.data[i].id);
					// 	}
					// }else {
					$scope.selectedItem.push($scope.data.orders.data[i].id);
					// }

				}
			} else {
				$scope.selectedItem = [];
			}
		};
	}
]);