bovoApp.controller('AccountController', ['$scope', 'Account', '$location', 'FileUploader', '$localStorage', 'WizardHandler',
  function ($scope, Account, $location, FileUploader, $localStorage, WizardHandler) {
    $scope.password = {};

    $scope.init = function () {
      Account.init().success(function (data, status, headers, config) {
        $scope.company = data.company;
        $scope.user = data.user;
        $scope.password = {};
        /*
        setTimeout(function(){
          var location = new google.maps.LatLng($scope.company.latitude,$scope.company.longitude);
          $scope.settingMap = new google.maps.Map(document.getElementById('company-setting-map'), {
            zoom: 10,
            center: location,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
          });
  
          if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(function(position){
              $scope.settingMap.setCenter(new google.maps.LatLng(position.coords.latitude,position.coords.longitude));
              $scope.marker.setPosition(new google.maps.LatLng(position.coords.latitude,position.coords.longitude));
              $scope.settingMap.setZoom(11);
              $scope.company.latitude = position.coords.latitude
              $scope.company.longitude = position.coords.longitude;
            });
          }

          $scope.marker = new SlidingMarker({
            position: location,
            map: $scope.settingMap,
            visible: true,
            draggable: true
          });

          console.log('map');

          $scope.marker.addListener('dragend', function(event) {
            var latlng = new google.maps.LatLng(event.latLng.lat(), );
            $scope.company.latitude = event.latLng.lat();
            $scope.company.longitude = event.latLng.lng();
          });
                    
          google.maps.event.addListener($scope.settingMap, 'idle', function(){
            $scope.company.zoom = $scope.settingMap.getZoom();
          });
        }, 200);
        */
      });
    };

    $scope.updateUserInfo = function (tour) {
      Account.updateUserInfo($scope.user).success(function (data, status, headers, config) {
        Messenger().post('Başarıyla Güncelledi!');
      }).error(function (data) {
        Messenger().post({
          message: data.message,
          type: "error"
        });
      });
    };

    $scope.updateCompanyInfo = function (tour) {
      Account.updateCompanyInfo($scope.company).success(function (data, status, headers, config) {
        Messenger().post('Başarıyla Güncelledi!');
      }).error(function (data) {
        Messenger().post({
          message: data.message,
          type: "error"
        });
      });
    };

    $scope.updatePassword = function () {
      Account.updatePassword($scope.password).success(function (data, status, headers, config) {
        Messenger().post(data.message);
        $scope.password = {};
      }).error(function (data) {
        Messenger().post({
          message: data.message,
          type: "error"
        });
      })
    };

    $scope.createWebhook = function () {
      Account.createWebhook().success(function (data, status, headers, config) {
        Messenger().post(data.message);
        $scope.data = data;
      }).error(function (data) {
        Messenger().post({
          message: data.message,
          type: "error"
        });
      })
    };

    $scope.webhooks = function () {
      Account.webhooks().success(function (data, status, headers, config) {
        $scope.data = data;
      }).error(function (data) {
        Messenger().post({
          message: data.message,
          type: "error"
        });
      })
    };

    $scope.smsProvider = function () {
      Account.smsProvider().success(function (data, status, headers, config) {
        $scope.sms_provider = data.data;
      }).error(function (data) {
        Messenger().post({
          message: data.message,
          type: "error"
        });
      })
    };

    $scope.updateSmsProvider = function (sms_provider) {
      Account.updateSmsProvider(sms_provider).success(function (data, status, headers, config) {
        Messenger().post(data.message);
        $scope.sms_provider = data.data;
      }).error(function (data) {
        Messenger().post({
          message: data.message,
          type: "error"
        });
      })
    };

    $scope.sendTestSms = function (test_sms_number) {
      $scope.loadingsms = true;
      Account.sendTestSms({
        test_sms_number: test_sms_number
      }).success(function (data, status, headers, config) {
        var message = data.split("<script>");
        if (message[0].length == 0) {
          message[0] = "Sms başarıyla gönderilmiştir.";
        }
        Messenger().post(message[0]);
        $scope.loadingsms = false;
      }).error(function (data) {
        Messenger().post({
          message: data.message,
          type: "error"
        });
      })
    };

    var logo = $scope.logo = new FileUploader({
      url: '/api/upload-logo',
      headers: {
        'X-CSRF-TOKEN': CSRF_TOKEN
      },
      autoUpload: true
    });

    logo.filters.push({
      name: 'formatFilter',
      fn: function (item /*{File|FileLikeObject}*/ , options) {
        var type = '|' + item.type.slice(item.type.lastIndexOf('/') + 1) + '|';
        return '|jpg|png|jpeg|bmp|gif|'.indexOf(type) !== -1;
      }
    });

    logo.onAfterAddingFile = function (fileItem) {
      $scope.proccessProfile = true;
      $scope.pending = true;
    };

    logo.onCompleteItem = function (item, response, status, headers) {
      $scope.proccessProfile = false;
      $scope.company.company.logo = response.file;
      if (response.status == 'success')
        $scope.pending = false;
    }

    $scope.passwordChangeMail = function () {
      Account.changePasswordMail().success(function (data) {
        Messenger().post('Talebini Başarıyla E-mail Olarak İlettik!');
      }).error(function (data) {
        Messenger().post({
          message: data.message,
          type: "error"
        });
      })
    }
  }
]);