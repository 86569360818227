bovoApp.controller('AnnouncementController', ['$scope', 'Announcement', '$location', '$routeParams', '$filter', '$translate',
  function ($scope, Announcement, $location, $routeParams, $filter, $translate) {
    $scope.deleted = [];


    $scope.all = function () {
      Announcement.all($scope.type).success(function (data, status, headers, config) {
        $scope.data = data;
      });
    }

    $scope.openDetail = function (item) {
      Announcement.get(item.id).success(function (data, status, headers, config) {
        $('#announcementModal').modal('show');
        $scope.announcement = data.announcement;
      });
    }

    $scope.$on('new_data', function () {
      $('#announcementModal').modal('show');
      $scope.new();
    }, true);

    $scope.new = function () {
      Announcement.new().success(function (data, status, headers, config) {
        $scope.data.vehicleRoutes = data.vehicleRoutes;
        $scope.announcement = {
          type: $scope.type,
          performance: 'no'
        };
      });
    }

    $scope.delete = function (type) {
      if (type == 'set') {
        $('#delete').modal('show');
      } else if (type == 'delete') {
        Announcement.delete($scope.deleted).success(function (data, status, headers, config) {
          Messenger().post($scope.translate(data.message.toString()));
          for (var i = $scope.deleted.length - 1; i >= 0; i--) {
            var index = $scope.data.announcements.indexOf($filter('filter')($scope.data.announcements, {
              id: $scope.deleted[i]
            })[0]);
            if (index > -1) {
              $scope.data.announcements.splice(index, 1);
            }
          }
          $scope.deleted = [];
          $('#delete').modal('hide');
        }).error(function (data) {
          Messenger().post({
            message: data.message,
            type: "error"
          });
        });
      }
    }

    $scope.postForm = function (user) {
      if ($scope.announcement.id) {
        $scope.update();
      } else {
        $scope.save();
      }
    }

    $scope.save = function () {
      Announcement.save($scope.announcement).success(function (data, status, headers, config) {
        $scope.data = data;
        Messenger().post('Başarılı');
        $('#announcementModal').modal('hide');
        $scope.all();
      }).error(function (data) {
        Messenger().post({
          message: data.message,
          type: "error"
        });
      });
    }

    $scope.update = function () {
      Announcement.update($scope.announcement).success(function (data, status, headers, config) {
        $scope.data = data;
        Messenger().post('Başarılı');
        $('#announcementModal').modal('hide');
        $scope.all();
      }).error(function (data) {
        Messenger().post({
          message: data.message,
          type: "error"
        });
      });
    }

    $scope.toggleSelection = function toggleSelection(id) {
      var idx = $scope.deleted.indexOf(id);
      if (idx > -1) {
        $scope.deleted.splice(idx, 1);
      } else {
        $scope.deleted.push(id);
      }
    };

  }
]);