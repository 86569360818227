bovoApp.
factory('UserManager', ['$http', function($http) {
    return {
      init: function(data) {
        return $http.post('api/users',data);
      },
      newUser: function() {
        return $http.get('api/new-user');
      },
      show: function(e) {
        return $http.get('api/user/'+e);
      },
      saveUser: function(data) {
        return $http.post('api/save-user',data);
      },
      deleteUser: function(data) {
        return $http.post('api/delete-user',data);
      },
      updateUser: function(data) {
        return $http.post('api/update-user-company',data);
      }
    };
}]);