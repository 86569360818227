bovoApp.controller('DeliveryZoneController', ['$scope', 'Setting', '$location', '$routeParams', '$filter',
  function ($scope, Setting, $location, $routeParams, $filter) {
    $scope.deleted = [];
    $scope.mapProp = {};


    $scope.init = function () {

      var tileLayer = L.tileLayer('https://bms.basarsoft.com.tr/Service/api/v1/map/ProMap?accId=VcLyowNd1kaSzkvxDKA8WQ&appCode=m1Dm5_9HskqpAoKI0i_baA&x={x}&y={y}&z={z}', {
        attribution: '&copy; <a href="https://www.basarsoft.com.tr">Basarsoft</a>'
      });
      var mTile = tileLayer;
      var map = L.map('map', {
        zoomControl: false,
        layers: [mTile],
        attributionControl: false,
        maxZoom: 18,
        minZoom: 6
      }).setView([39.21412499862414, 34.72533269746542], 7);

      var varName = L.control.attribution({ prefix: '' }).addTo(map);
      map.addControl(new L.Control.Zoom({ position: 'topright', zoomInText: '+', zoomOutText: '-', zoomInTitle: 'Yakınlaş', zoomOutTitle: 'Uzaklaş' }));

      function regionStyle(feature) {
        return {
          fillColor: 'blue',
          fillOpacity: 0.5,
          weight: 2,
          opacity: 1,
          color: '#ffffff',
          dashArray: '3'
        };
      }
      var normalStyle = {
        'fillColor': 'blue',
        'weight': 2,
        'opacity': 1
      };
      var highlightStyle = {
        'fillColor': 'yellow',
        'weight': 2,
        'opacity': 1
      };

      function forEachFeature(feature, layer) {
        var regionName = feature.properties.adm1_tr + " / " + feature.properties.adm2_tr;

        layer.bindTooltip(regionName);

        layer.on("click", function (e) {
          var branchId = $("#BranchId").val();
          var productId = $("#ProductId").val();
          var pcode = feature.properties.pcode;

          pushMatching(branchId, productId, pcode, layer);
        });
      }

      function buildCountyList(obj, view) {
        $("#countyList button").unbind("click");
        $("#countyList").html('<table class="table table-striped table-hover mt-3"><tbody></tbody></table>');
        for (var i = 0; i < obj.length; i++) {
          var row = '<tr>'
            + '<td>' + obj[i].text + '</td>'
            + '<td>' + obj[i].opt + ' mh.</td>'
            + '<td class="text-right"><button type="button" class="btn btn-link detail blue p-0" data-id="' + obj[i].id + '" style="height:auto"><i class="fa fa-search"></i></button></td>'
            + '<td class="text-right"><button type="button" class="btn btn-link remove red p-0" data-id="' + obj[i].id + '" style="height:auto"><i class="fa fa-trash"></i></button></td>'
            + '</tr>';
          $("#countyList>table>tbody").append(row);
          if (i == 0 && view) {
            map.setView([obj[i].Lat, obj[i].Lng], 10);
          }
        }
        $.each(shpfile._layers, function (index, layer) {
          layerExist = false;
          //console.log(layer.feature.properties.pcode);
          for (var i = 0; i < obj.length; i++) {
            if (obj[i].id == layer.feature.properties.pcode) {
              layerExist = true;
            }
          }
          layer.setStyle(layerExist ? highlightStyle : normalStyle);
        });
        $("#countyList .remove").click(function () {
          var pcode = $(this).data("id");
          var branchId = $("#BranchId").val();
          var productId = $("#ProductId").val();

          pushMatching(branchId, productId, pcode);
        });
        $("#countyList .detail").click(function () {
          var pcode = $(this).data("id");
          var branchId = $("#BranchId").val();
          var productId = $("#ProductId").val();

          $.fancybox.open({
            src: "/Branch/GetBranchNeighborhoods?branchId=" + branchId + "&productId=" + productId + "&pcode=" + pcode,
            type: "iframe",
            toolbar: false,
            smallBtn: true,
            iframe: {
              preload: false,
              css: {
                width: 800,
                "max-width": "100%",
                height: '600px',
                "max-height": "90%"
              },
              attr: {
                scrolling: "auto"
              }
            },
            lang: "tr"
          });
        });
      }

      function pushMatching(branchId, productId, pcode, layer) {
        if (branchId == "" || productId == "") {
          $.fancybox.open("<div class='alert alert-danger'><i class='fa fa-exclamation-triangle'></i> Lütfen ürün ve şube seçiniz.</div>");
        }
        else {
          $("body").addClass("locationmaploading");
          $.ajax({
            url: '/Branch/PushBranchMapping',
            type: "POST",
            async: true,
            dataType: "json",
            data: "branchId=" + branchId + "&productId=" + productId + "&pcode=" + pcode,
            success: function (data) {
              $("body").removeClass("locationmaploading");
              if (data.result) {
                buildCountyList(data.list, false);
                //if (typeof (layer) == "object") {
                //    var newStyle = layer.options.fillColor == highlightStyle.fillColor ? normalStyle : highlightStyle;
                //    layer.setStyle(newStyle);
                //}
              }
              else {
                $.fancybox.open("<div class='alert alert-danger'><i class='fa fa-exclamation-triangle'></i> " + data.message + "</div>");
              }
            }
          });
        }
      }

      var shpfile = new L.Shapefile('/assets/turkey_administrativelevels0_1_2.zip', {
        onEachFeature: forEachFeature,
        style: regionStyle
      });

      /*var shpfile = new L.Shapefile('mahalle.zip', {
          onEachFeature: function(feature, layer) {
              if (feature.properties) {
                  layer.bindPopup(Object.keys(feature.properties).map(function(k) {
                      return k + ": " + feature.properties[k];
                  }).join("<br />"), {
                      maxHeight: 200
                  });
              }
          },
          style:function(feature){
              return {color:"black",fillColor:"red",fillOpacity:.75}
          }
      });*/
      shpfile.addTo(map);
      shpfile.once("data:loaded", function () {
        $("body").removeClass("locationmaploading");
        //console.log("finished loaded shapefile");
      });

      $(document).ready(function () {
        $("body").addClass("locationmaploading");
        $("#filterMap").click(function () {
          var branchId = $("#BranchId").val();
          var productId = $("#ProductId").val();
          //console.log(shpfile._layers);

          if (branchId == "" || productId == "") {
            $.fancybox.open("<div class='alert alert-danger'><i class='fa fa-exclamation-triangle'></i> Lütfen ürün ve şube seçiniz.</div>");
          }
          else {
            $("body").addClass("locationmaploading");
            $.ajax({
              url: '/Branch/GetBranchMapping',
              type: "POST",
              async: true,
              dataType: "json",
              data: "branchId=" + branchId + "&productId=" + productId,
              success: function (data) {
                $("body").removeClass("locationmaploading");
                if (data.result) {
                  buildCountyList(data.list, true);
                }
                else {
                  $.fancybox.open("<div class='alert alert-danger'><i class='fa fa-exclamation-triangle'></i> " + data.message + "</div>");
                }
              }
            });
          }
        });
      });
    }



  }
]);
