bovoApp.
factory('Team', ['$http', function($http) {
    return {
      init: function() {
        return $http.get('api/teams');
      },
      newTeam: function() {
        return $http.get('api/new-team');
      },
      show: function(e) {
        return $http.get('api/team/'+e);
      },
      saveTeam: function(data) {
        return $http.post('api/save-team',data);
      },
      deleteTeam: function(data) {
        return $http.post('api/delete-team',data);
      },
      updateTeam: function(data) {
        return $http.post('api/update-team',data);
      }
    };
}]);