bovoApp.
factory('Support', ['$http', function($http) {
    return {
      save: function(data) {
        return $http.post('api/save-support',data);
      },
      orders: function(data) {
        return $http.post('api/support-orders',data);
      },
      addSupportType: function(data) {
        return $http.post('api/add-supports',data);
      },
      deleteSupportType: function(id) {
        return $http.get('api/delete-supports/'+ id);
      },
    };
}]);