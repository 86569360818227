bovoApp.controller('SupportController', ['$scope', 'Support', 'Order', 'Analytic', 'Account', '$location', 'FileUploader', '$http', '$rootScope', '$sce',
	function ($scope, Support, Order, Analytic, Account, $location, FileUploader, $http, $rootScope, $sce) {

		$scope.filterProp = [];

		$scope.support = {
			name:null,
		},

		$scope.filterData = {
			export: false,
			customers: [],
			workers: [],
			workSteps: [],
			page: 1,
			date_type: 'cargo_date',
			scheduled_time: {
				startDate: null,
				endDate: null
			},
		},

		setTimeout(() => {
			$scope.supportUrl = $sce.trustAsResourceUrl('https://bovo-bot.inverizo.com/index.html?email=' + $scope.authenticatedUser.email + '&v=4');
		}, 500);

		$scope.$on('refresh_data', function () {
			console.log('refreshing order reports...')
			$scope.init();
		}, true);

		$scope.$on('update_support', function () {
			console.log('refreshing order reports...')
			$scope.init();
		}, true);

		$scope.addSupport = function () {
			Support.addSupportType($scope.support).success(function (data, status, headers, config) {
				$scope.init();
				$scope.support.name=null
				$('#exampleModalCenter').modal('hide')
			})
		}

		$scope.deleteSupportType= function(id){
			var control = confirm('Emin misiniz');
			if(control){
				Support.deleteSupportType(id).success(function (data, status, headers, config) { 
					$scope.init()
				 })
			}
		}

		$scope.clearFilter = function (page) {
			$scope.filterData = {
				export: false,
				customers: [],
				workers: [],
				workSteps: [],
				page: 1,
				created_at: {
					startDate: null,
					endDate: null
				},
				scheduled_time: {
					startDate: null,
					endDate: null
				}
			}
		}

		$scope.init = function (type) {
			Analytic.support($scope.filterData).success(function (data, status, headers, config) {
				$scope.data = data;
				$scope.typeId = $scope.data.types[0].id;
				$scope.getOrders($scope.typeId);
			});
		};

		$scope.getOrders = function (type_id, support_status = null) {
			if (type_id) {
				$scope.typeId = type_id;
				$scope.filterData.type_id = type_id;
			}
			if (support_status) {
				$scope.filterData.support_status = support_status
			}
			Analytic.supportOrders($scope.filterData).success(function (data, status, headers, config) {
				$scope.orders = data.orders;
			});
		}

		$scope.downloadExcel = function () {
			data = $scope.filterData;
			data.orders = $scope.selectedItem;
			Order.generateSupportExcelReport(data).success(function (data, status, headers, config) {
				if (data.type === 'download') {
					window.location.href = data['url'];
					setTimeout(function () {
						Order.removeDownloadFile({ path: data['path'] }).success(function (response, status, headers, config) {
							$scope.selectedItem = [];
						});
					}, 2000)
				} else {
					Messenger().post(data.message);
				}
			});
		};

		$scope.getPage = function (page) {
			$scope.filterData.page = page;
			$scope.filter();
		}

		$scope.export = function (page) {
			$scope.filterData.export = true;
			$scope.init('export');
		}

		$scope.sort = function (field, sort) {
			$scope.filterData.field = field;
			$scope.filterData.sort = sort;
			$scope.filter();
		}

		$scope.filter = function (type) {
			if (type == 'clear') {
				$scope.filterData = {
					export: false,
					customers: [],
					workers: [],
					workSteps: [],
					page: 1,
					created_at: {
						startDate: null,
						endDate: null
					},
					scheduled_time: {
						startDate: null,
						endDate: null
					}
				}
			}
			$scope.getOrders();
		}

	}
]);
