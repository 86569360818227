bovoApp.
  factory('PricingAssign', ['$http', function ($http) {
    return {
      all: function () {
        return $http.get('api/pricing-assigns');
      },
      get: function (id) {
        return $http.get('api/pricing-assign/' + id);
      },
      new: function (id) {
        return $http.get('api/new-pricing-assign');
      },
      delete: function (id) {
        return $http.delete('api/delete-pricing-assign/' + id);
      },
      save: function (data) {
        return $http.post('api/save-pricing-assign', data);
      },
      update: function (data) {
        return $http.post('api/update-pricing-assign', data);
      },
      getTables: function (data) {
        return $http.post('api/pricing-assign-tables', data);
      }
    };
  }]);