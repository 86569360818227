bovoApp.
factory('CustomerAccount', ['$http', function($http) {
    return{
        init: function() {
            return $http.get('api/customer-info');
        },
        updateCustomerInfo: function(data) {
            return $http.post('api/update-customer-info',data);
        },
        updatePassword: function(data) {
            return $http.post('api/update-customer-password',data);
        },
    };
}]);