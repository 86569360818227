bovoApp.controller('ReturnOrderController', ['$scope', 'ReturnOrder', '$location', '$routeParams', '$filter', '$translate', 'ngAudio',
	function ($scope, ReturnOrder, $location, $routeParams, $filter, $translate, ngAudio) {
		$scope.deleted = [];
		var successSound = ngAudio.load("sounds/success.mp3");
		$scope.order_id = null;

		$scope.returnOrder = {
			orders: []
		}

		$scope.filterData = {
			station_id: [],
			price: '',
			vehicle: [],
			page: 1,
			date: {
				startDate: null,
				endDate: null
			}
		}

		$scope.clearFilter = function (page) {
			$scope.filterData = {
				station_id: [],
				price: '',
				vehicle: [],
				page: 1,
				date: {
					startDate: null,
					endDate: null
				}
			}
			$scope.all();
		}

		$scope.remove = function (index) {
			$scope.failOrder.orders.splice(index, 1);
		}
		
		$scope.selectAll = function (select) {
			if (select) {
				$scope.deleted = [];
				for (let i = 0; i < $scope.data.returnOrders.data.length; i++) {
					$scope.deleted.push($scope.data.returnOrders.data[i].id);
				}
			} else {
				$scope.deleted = [];
			}
		};

		$scope.$on('refresh_data', function () {
			$scope.all();
		}, true);

		$scope.$on('new_data', function () {
			$('#newReturnOrder').modal('show');
			$scope.new();
		}, true);

		$scope.getPage = function (page) {
			$scope.filterData.page = page;
			$scope.all();
		}

		$scope.all = function () {
			ReturnOrder.all($scope.filterData).success(function (data, status, headers, config) {
				$scope.data = data;
			});
		}

		$scope.send = function () {
			var data = { order_id: $scope.order_id };
			if ($scope.returnOrder.id) {
				data.id = $scope.returnOrder.id
			}
			ReturnOrder.addOrder(data).success(function (data, status, headers, config) {
				$scope.returnOrder.orders.push(data.order)
				$scope.order_id = null;
				successSound.play()
			}).error(function (data) {
				Messenger().post({
					message: data.message,
					type: "error"
				});
			});
		};

		$scope.init = function () {
			if ($routeParams.id) {
				$scope.get($routeParams.id);
			}
		}

		$scope.get = function (id) {
			ReturnOrder.get(id).success(function (data, status, headers, config) {
				$scope.returnOrder = data.returnOrder;
			});
		}

		$scope.delete = function (type) {
			if (type == 'set') {
				$('#delete').modal('show');
			} else if (type == 'delete') {
				ReturnOrder.delete($scope.deleted).success(function (data, status, headers, config) {
					Messenger().post('Silindi');
					for (var i = $scope.deleted.length - 1; i >= 0; i--) {
						var index = $scope.data.returnOrders.data.indexOf($filter('filter')($scope.data.returnOrders.data, {
							id: $scope.deleted[i]
						})[0]);
						if (index > -1)
							$scope.data.returnOrders.data.splice(index, 1);
					}
					$scope.deleted = [];
					$('#delete').modal('hide');
				}).error(function (data) {
					Messenger().post({
						message: data.message,
						type: "error"
					});
				});
			}
		}

		$scope.save = function (force) {
			// if(!force) {
			// 	var warning = false;
			// 	$scope.returnOrder.orders.forEach(element => {
			// 		if(!element.status || (element.status && element.status.value != 'fail' && element.status.value != 'refund')) {
			// 			warning = true;
			// 		}
			// 	});
			// 	if(warning) {
			// 		$('#warning').modal('show');
			// 		return;
			// 	}
			// }

			// $('#warning').modal('hide');

			if ($scope.returnOrder.id) {
				$scope.update();
			} else {
				ReturnOrder.save($scope.returnOrder).success(function (data, status, headers, config) {
					$scope.returnOrder = data.data;
					$location.path('return-order/create').search('id', data.data.id);
					Messenger().post('Kaydedildi');
				}).error(function (data) {
					Messenger().post({
						message: data.message,
						type: "error"
					});
				});
			}
		}

		$scope.update = function () {
			ReturnOrder.update($scope.returnOrder).success(function (data, status, headers, config) {
				Messenger().post('Güncellendi');
			}).error(function (data) {
				Messenger().post({
					message: data.message,
					type: "error"
				});
			});
		}

		$scope.openDetail = function (data) {
			$location.path('return-order/create').search('id', data.id);
		}

		$scope.print = function (data) {
			window.open('/print-return-order/' + data.id , '_blank');
		}

		$scope.toggleSelection = function toggleSelection(id) {
			var idx = $scope.deleted.indexOf(id);
			if (idx > -1) {
				$scope.deleted.splice(idx, 1);
			} else {
				$scope.deleted.push(id);
			}
		};

	}
]);