bovoApp.controller('CustomerHistoryController', ['$scope', 'Order', 'CustomerOrder', '$location', '$filter', '$routeParams', '$rootScope', 'Analytic', '$translate',
    function ($scope, Order, CustomerOrder, $location, $filter, $routeParams, $rootScope, Analytic, $translate) {

        $scope.filterProp = [];
        $scope.selectedItems = [];
        $scope.isEmpty = false;

        $scope.selectAllOrder = function (select) {
            if (select) {
                // $scope.selectedItems = [];
                // for (let i = 0; i < $scope.data.orders.data.length; i++) {
                //     if (!$scope.data.orders.data[i].status) {
                //         // if ($scope.data.orders.data[i].status.id !== $scope.data.success.id && $scope.data.orders.data[i].status.id !== $scope.data.fail.id) {
                //             $scope.selectedItems.push($scope.data.orders.data[i].id);
                //         // }
                //     } else {
                //         // $scope.selectedItems.push($scope.data.orders.data[i].id);
                //     }
                // }
                $scope.filterData.selectall = true;
                $scope.customerOrders()

            } else {
                $scope.selectedItems = [];
            }
        };
        $scope.selectAllOrderCheck = function (select) {
            if (select) {
                $scope.selectedItems = [];
                for (let i = 0; i < $scope.data.orders.data.length; i++) {
                    console.log($scope.data.orders.data[i])
                    $scope.selectedItems.push($scope.data.orders.data[i].id);
                }
            } else {
                $scope.selectedItems = [];
            }
        };


        $scope.addSelectedItem = function (item) {
            if ($scope.selectedItems.indexOf(item) > -1) {
                $scope.selectedItems.splice($scope.selectedItems.indexOf(item), 1);
            } else {
                $scope.selectedItems.push(item);
            }
        };

        $scope.checklistOpts = {
            autoApply: false,
            minDate: moment(),
            locale: {
                applyClass: 'btn-green',
                applyLabel: "Done",
                cancelLabel: "",
                fromLabel: "From",
                toLabel: "/",
                format: "MM/DD/YY",
                customRangeLabel: 'Ozel Aralık'
            },
            ranges: {
                // 'All': [moment().subtract(2, 'years'), moment().add(2, 'years')],
                'Today': [moment(), moment()],
                'Tomorrow': [moment().add(1, 'days'), moment().add(1, 'days')]
            }
        }

        $scope.filterData = {
            customers: [],
            workers: [],
            workSteps: [],
            page: 1,
            paginate: 25,
            stations: [],
            created_at: {
                startDate: null,
                endDate: null
            },
            scheduled_time: {
                startDate: null,
                endDate: null
            }
        }

        $scope.addFilterProps = function (item) {
            if ($scope.filterProp.indexOf(item) === -1)
                $scope.filterProp.push(item);
            else {
                var index = $scope.filterProp.indexOf(item);
                $scope.filterProp.splice(index, 1);
                if (item != 'term') {
                    $scope.filterData[item] = [];
                } else {
                    $scope.filterData[item] = "";
                }
                if (item == 'date') {
                    delete $scope.filterData.startDate;
                    delete $scope.filterData.endDate;
                }
                if ($scope.filterProp.length == 0) {
                    $scope.filter('clear');
                }
            }
        }

        $scope.getPage = function (page) {
            $scope.filterData.page = page;
            $scope.filter();
        }

        $scope.sort = function (field, sort) {
            $scope.filterData.field = field;
            $scope.filterData.sort = sort;
            $scope.filter();
        }

        $scope.filter = function (type) {
            if (type == 'clear') {
                $scope.filterData = {
                    customers: [],
                    workers: [],
                    workSteps: [],
                    paginate: 25,
                    page: 1,
                    created_at: {
                        startDate: null,
                        endDate: null
                    },
                    scheduled_time: {
                        startDate: null,
                        endDate: null
                    }
                }
                $scope.filterProp = [];
            } else {
                $scope.filterData.filterProp = $scope.filterProp;
                if (typeof $scope.filterData.created_at.endDate !== 'undefined' && $scope.filterData.created_at.endDate !== null) {
                    $scope.filterData.filterProp.created_at = 'created_at';
                }
                if (typeof $scope.filterData.scheduled_time.endDate !== 'undefined' && $scope.filterData.scheduled_time.endDate !== null) {
                    $scope.filterData.filterProp.scheduled_time = 'scheduled_time';
                }
            }
            $scope.customerOrders();
        }

        $scope.clearFilter = function (page) {
            $scope.filterData = {
                workers: [],
                workSteps: [],
                paginate: 25,
                page: 1,
                created_at: {
                    startDate: null,
                    endDate: null
                },
                scheduled_time: {
                    startDate: null,
                    endDate: null
                }
            }
            $location.search('data', null)
            $scope.filterProp = [];
            $scope.customerOrders('map');
        }

        $scope.$on('refresh_data', function () {
            console.log('refreshing map...')
            $scope.customerOrders();
        }, true);

        $scope.searchOrder = function (term) {
            $scope.filterData.term = term;
            $scope.customerOrders();
        }

        $scope.exportCustomerExcel = function () {
            $scope.filterData.export = true;
            CustomerOrder.customerOrders($scope.filterData).success(function (data, status, headers, config) {
                if (data.url) {
                    window.location.href = data['url'];
                    delete $scope.filterData.export;
                } else {
                    Messenger().post(data.message);
                }
            });

            // Order.generateExcelReport($scope.filterData).success(function(data, status, headers, config) {
            //     setTimeout(function(){
            //         Order.removeDownloadFile({path: data['path']}).success(function(response, status, headers, config) { 
            //             $scope.selectedItem = [];
            //         });
            //     },2000)

            // });
        };

        $scope.customerOrders = function () {
            if ($routeParams.invoice_id) {
                $scope.filterData.invoice_id = $routeParams.invoice_id;
            }
            if (IsJsonString($routeParams.data)) {
                filter = JSON.parse($routeParams.data);
                if (typeof filter == 'object') {
                    angular.merge($scope.filterData, filter);
                }
            }
            if ($routeParams.real_order) {
                $scope.filterData.real_order = true;
                $rootScope.realOrder = true;
            } else {
                $rootScope.realOrder = false;
            }

            if ($routeParams.all) {
                $scope.filterData.all = true;
            }
            CustomerOrder.customerOrders($scope.filterData).success(function (data, status, headers, config) {
                if ($scope.filterData.selectall) {
                    $scope.selectedItems = data.orders;
                    delete $scope.filterData.selectall;
                    return true;
                }
                $scope.data = data;
                if ($rootScope.realOrder) {
                    $scope.data.steps.unshift({
                        id: 0,
                        name: 'Veri Oluşturuldu'
                    });
                }
                $scope.data.stations.unshift({
                    id: 0,
                    code: 'All'
                });

                if (data.orders.data.length === 0) {
                    $scope.isEmpty = true;
                } else {
                    $scope.isEmpty = false;
                }
            });
        };

        $scope.getReaminingTime = function (item) {
            if (!item.estimated_delivery) {
                CustomerOrder.getReaminingTime(item.id).success(function (data, status, headers, config) {
                    item.estimated_delivery = data.estimated_delivery;
                });
            }
        };

        $scope.removeNotDelivery = function () {
            CustomerOrder.removeNotDelivery().success(function (data, status, headers, config) {
                $scope.data.not_delivery_zone = null;
            });
        };

        $scope.setTicimaxOrderId = function () {
            $rootScope.loadingWork = true;
            if (!$scope.filterData.ticimaxIds.length) {
                alert('Lütfen Ticimax Sipariş Numarası Giriniz!');
                return;
            }
            CustomerOrder.setTicimaxOrderId({ ids: $scope.filterData.ticimaxIds }).success(function (data, status, headers, config) {
                $scope.customerOrders();
                $scope.filterData.ticimaxIds = [];
                Messenger().post(data.message);
                $('#ticimaxOrderIdModal').modal('toggle');
            });
        };

        $scope.downloadZip = function () {
            Order.generateTotalOrdersReportInZip({
                orders: $scope.selectedItems
            }).success(function (data, status, headers, config) {
                window.location.href = data['url'];
                setTimeout(function () {
                    Order.removeDownloadFile({
                        path: data['path']
                    }).success(function (response, status, headers, config) {
                        $scope.selectedItems = [];
                    });
                }, 2000)
            });
        };


        $scope.deleteOrders = function (send_cancel_sms) {
            Order.deleteOrders({
                orders: $scope.selectedItems,
                send_cancel_sms: send_cancel_sms
            }).success(function (response, status, headers, config) {
                $scope.customerOrders();
                $scope.selectedItems = [];
                $('#deleteOrdersModal').modal('toggle');
            });
        };

        $scope.getCustomerOrderDetails = function (work) {
            $rootScope.loadingWork = true;
            var id = work.id;
            if (typeof id == 'undefined') {
                var id = work;
            }
            CustomerOrder.customerOrder(id).success(function (data, status, headers, config) {
                $rootScope.loadingWork = false;
                $rootScope.selectedWork = data.order;
                setTimeout(() => {
                    $scope.$apply();
                }, 300);
                $('#orderDetail').modal('show');
            });
        }

        $scope.report = function (type) {
            Analytic.customerOrders($scope.filterData).success(function (data, status, headers, config) {
                $scope.data = data;
                $scope.series = ['Tamamlanan', 'Bekleyen', 'Başarısız', 'Toplam'];
                $scope.chartColors = [
                    '#63BC46',
                    '#4a5157',
                    '#c93b2b',
                    '#63BC46'
                ];
                $scope.barChartData = [
                    data.chart.count.success,
                    data.chart.count.other,
                    data.chart.count.fail,
                    data.chart.count.total
                ];
                $scope.chartData = [data.count.success, data.count.other, data.count.fail, data.count.total];
                $('#reportModal').modal('show');
            });
        };

        $scope.selectDate = {
            page: 1,
            created_at: {
                startDate: moment(),
                endDate: moment()
            }
        }

        $scope.optsCheckOrder = {
            date: {
                startDate: moment(),
                endDate: moment()
            }
        };

        $scope.checkOrder = function (id) {
            $('#checkboxDefault-' + id).prop("disabled", true).closest('tr').addClass('checked');
            CustomerOrder.checkOrder({
                id: id
            }).success(function (data, status, headers, config) {
                Messenger().post(data.message);
            });
        };

        $scope.$watchCollection('filterData.scheduled_time', function () {
            $scope.refreshData('map');
        });

        $scope.selectedDate = 'All';
        $scope.setDate = function (date) {
            $scope.selectedDate = date;
            $scope.filterData.weekDay = date;
            $scope.filterData.checklist = true;
            $scope.filterData.no_paginate = true;
            $scope.filterData.stations = [0];

            setTimeout(() => {
                $('.selected-item').click();
                $('.filter-option').html($translate.instant('BY_STATION'))
            }, 1000);
        }

        $scope.clearCheckFilter = function (page) {
            $scope.filterData = {
                workers: [],
                workSteps: [],
                paginate: 25,
                page: 1,
                stations: [],
                checklist: true,
                created_at: {
                    startDate: null,
                    endDate: null
                },
                scheduled_time: {
                    startDate: null,
                    endDate: null
                }
            }
            $('.selectpicker').selectpicker('val', []);
            $location.search('data', null)
            $scope.filterProp = [];
            $scope.customerOrders('map');
        }

        function IsJsonString(str) {
            try {
                JSON.parse(str);
            } catch (e) {
                return false;
            }
            return true;
        }

    }
]);