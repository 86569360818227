bovoApp.
  factory('Voyage', ['$http', function ($http) {
    return {
      all: function (data) {
        return $http.post('api/voyages', data);
      },
      get: function (id) {
        return $http.get('api/voyage/' + id, { ignoreLoadingBar: true });
      },
      new: function (id) {
        return $http.get('api/new-voyage');
      },
      delete: function (id) {
        return $http.delete('api/delete-voyage/' + id);
      },
      save: function (data) {
        return $http.post('api/save-voyage', data);
      },
      update: function (data) {
        return $http.post('api/update-voyage', data);
      },
      readOrder: function (data) {
        return $http.post('api/voyage-read-order', data);
      },
      batchMovement: function (data) {
        return $http.post('api/voyage-batch-movement', data);
      },
      closeVoyage: function (data) {
        return $http.post('api/voyage-close', data);
      },
      findVoyage: function (data) {
        return $http.post('api/find-voyage', data);
      },
      getTransferVoyages: function (data) {
        return $http.post('api/find-transfer-voyage', data);
      },
      groupBarcode: function (data) {
        return $http.post('api/voyage-group-barcode', data);
      },
      exportExcel: function (data) {
        return $http.post('api/voyage-export-excel', data);
      },
    };
  }]);