bovoApp.controller('TeamController', ['$scope', 'Team', '$location','$filter','$routeParams',
  function ($scope, Team, $location, $filter, $routeParams) {
    $scope.deleted = [];

    $scope.init = function () {
      Team.init().success(function(data, status, headers, config) {
        $scope.data = data;
      });
    };

    $scope.show = function () {
      Team.show($routeParams.id).success(function(data, status, headers, config) {
        $scope.team = data.team;
        $scope.workers = data.workers;
      });
    };

    $scope.saveTeam = function () {
      Team.saveTeam($scope.team).success(function(data, status, headers, config) {
        Messenger().post(data.message);
        $location.path('ekip-yonetimi');
      }).error(function(data) {
        Messenger().post({message: data.message,type: "error"});
      });
    };

    $scope.updateTeam = function () {
      Team.updateTeam($scope.team).success(function(data, status, headers, config) {
        Messenger().post(data.message);
      }).error(function(data){
        Messenger().post({message: data.message,type: "error"});
      })
    };

    $scope.delete = function (id,type) {
      if(type == 'set') {
        $('#delete').modal('show');
        $scope.deleted = id;
      } else if(type == 'delete') {
        Team.deleteTeam({ id: $scope.deleted }).success(function(data, status, headers, config) {
          Messenger().post(data.message);
          var index = $scope.data.teams.data.indexOf($filter('filter')($scope.data.teams.data, { id: $scope.deleted })[0]);
          if (index > -1)
            $scope.data.teams.data.splice(index, 1);
        }).error(function(data){
          Messenger().post({message: data.message,type: "error"});
        })
			}
    };

    $scope.getNumber = function(num) {
      return new Array(num);   
    };

    $scope.toggleSelection = function toggleSelection(id) {
      var idx = $scope.deleted.indexOf(id);
      if (idx > -1) {
        $scope.deleted.splice(idx, 1);
      }
      else {
        $scope.deleted.push(id);
      }
    };

  }
]);
