bovoApp.
  factory('TransferReason', ['$http', function ($http) {
    return {
      all: function (type) {
        return $http.get('api/transfer-reasons?type=' + type);
      },
      get: function (id) {
        return $http.get('api/transfer-reason/' + id);
      },
      new: function (id) {
        return $http.get('api/new-transfer-reason');
      },
      delete: function (id) {
        return $http.delete('api/delete-transfer-reason/' + id);
      },
      save: function (data) {
        return $http.post('api/save-transfer-reason', data);
      },
      update: function (data) {
        return $http.post('api/update-transfer-reason', data);
      }
    };
  }]);