bovoApp.controller('PricingAssignController', ['$scope', 'PricingAssign', '$location', '$routeParams', '$filter', '$translate',
  function ($scope, PricingAssign, $location, $routeParams, $filter, $translate) {
    $scope.deleted = [];

    $scope.all = function () {
      PricingAssign.all().success(function (data, status, headers, config) {
        $scope.data = data;
      });
    }

    $scope.openDetail = function (item) {
      PricingAssign.get(item.id).success(function (data, status, headers, config) {
        $('#pricingAssignModal').modal('show');
        $scope.pricingAssign = data.pricingAssign;
        $scope.formData = data;
        $scope.getTables($scope.pricingAssign, true);
      });
    }

    $scope.$on('new_data', function () {
      $('#pricingAssignModal').modal('show');
      $scope.new();
    }, true);

    $scope.new = function () {
      PricingAssign.new().success(function (data, status, headers, config) {
        $scope.formData = data;
        $scope.pricingAssign = {
          types: []
        };
      });
    }

    $scope.bulkAssignTable = function (table) {
      $scope.formData.workTypes.forEach(element => {
        $scope.pricingAssign.types[element.id] = table.id;
      });
    }

    $scope.getTables = function (pricingAssign, set = false) {
      PricingAssign.getTables(pricingAssign).success(function (data, status, headers, config) {
        $scope.formData.tables = data.tables;
        if (set) {
          var types = [];
          $scope.pricingAssign.types.forEach(element => {
            types[element.work_type_id] = element.pricing_id
          });
          $scope.pricingAssign.types = types
        }
      });
    }

    $scope.delete = function (type) {
      if (type == 'set') {
        $('#delete').modal('show');
      } else if (type == 'delete') {
        PricingAssign.delete($scope.deleted).success(function (data, status, headers, config) {
          Messenger().post('Silindi');
          $scope.all()
          $scope.deleted = [];
          $('#delete').modal('hide');
        }).error(function (data) {
          Messenger().post({
            message: data.message,
            type: "error"
          });
        });
      }
    }

    $scope.postForm = function (user) {
      if ($scope.pricingAssign.id) {
        $scope.update();
      } else {
        $scope.save();
      }
    }

    $scope.save = function () {
      PricingAssign.save($scope.pricingAssign).success(function (data, status, headers, config) {
        $scope.data = data;
        Messenger().post('Başarılı');
        $('#pricingAssignModal').modal('hide');
        $scope.all();
      }).error(function (data) {
        Messenger().post({
          message: data.message,
          type: "error"
        });
      });
    }

    $scope.update = function () {
      PricingAssign.update($scope.pricingAssign).success(function (data, status, headers, config) {
        $scope.data = data;
        Messenger().post('Başarılı');
        $('#pricingAssignModal').modal('hide');
        $scope.all();
      }).error(function (data) {
        Messenger().post({
          message: data.message,
          type: "error"
        });
      });
    }

    $scope.toggleSelection = function toggleSelection(id) {
      var idx = $scope.deleted.indexOf(id);
      if (idx > -1) {
        $scope.deleted.splice(idx, 1);
      } else {
        $scope.deleted.push(id);
      }
    };

  }
]);