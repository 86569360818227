bovoApp.
factory('Account', ['$http', function($http) {
    return {
      init: function() {
        return $http.get('api/company-info');
      },
      filter: function(data) {
        return $http.post('api/filter-analytic',data);
      },
      updateCompanyInfo: function(data) {
        return $http.post('api/update-company-info',data);
      },
      updateUserInfo: function(data) {
        return $http.post('api/update-user-info',data);
      },
      changePasswordMail: function(data) {
        return $http.post('api/change-password-mail',data);
      },
      updatePassword: function(data) {
        return $http.post('api/update-company-password',data);
      },
      createWebhook: function(data) {
        return $http.post('api/create-webhook',data);
      },
      webhooks: function() {
        return $http.get('api/get-webhooks');
      },
      smsProvider: function() {
        return $http.get('api/get-sms-provider');
      },
      updateSmsProvider: function(data) {
        return $http.post('api/update-sms-provider',data);
      },
      sendTestSms: function (data) {
        return $http.post('api/send-test-sms', data);
      },
      cities: function (data) {
        return $http.get('api/cities', data);
      },
      districts: function (data) {
        return $http.get('api/districts', {params: data});
      },
    };
}]);