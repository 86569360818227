bovoApp.
  factory('PackageType', ['$http', function ($http) {
    return {
      all: function (type) {
        return $http.get('api/package-types');
      },
      get: function (id) {
        return $http.get('api/package-type/' + id);
      },
      new: function (id) {
        return $http.get('api/new-package-type');
      },
      delete: function (id) {
        return $http.delete('api/delete-package-type/' + id);
      },
      save: function (data) {
        return $http.post('api/save-package-type', data);
      },
      update: function (data) {
        return $http.post('api/update-package-type', data);
      }
    };
  }]);