bovoApp.controller('FailCallController', ['$scope', 'FailCall', '$location', '$routeParams', '$filter', '$translate',
  function ($scope, FailCall, $location, $routeParams, $filter, $translate) {
    $scope.deleted = [];

    $scope.type = 'line';
    if ($location.path() == '/route-vehicles') {
      $scope.type = 'route'
    }

    $scope.all = function () {
      FailCall.all($scope.type).success(function (data, status, headers, config) {
        $scope.data = data;
      });
    }

    $scope.openDetail = function (item) {
      FailCall.get(item.list_id).success(function (data, status, headers, config) {
        $('#failCallModal').modal('show');
        $scope.failCall = item;
        $scope.failCall.calls = data.failCalls.body[0].numbers;
      });
    }
  }
]);