bovoApp.
  factory('Analytic', ['$http', function ($http) {
    return {
      init: function () {
        return $http.get('api/analytic');
      },
      dashboard: function (data) {
        return $http.post('api/analytic/dashboard', data);
      },
      ordersFromFilter: function (data) {
        return $http.post('api/analytic/orders-from-filter', data);
      },
      ordersFromFilterShowMore: function (data) {
        return $http.post('api/analytic/orders-from-filter-show-more', data);
      },
      orders: function (data) {
        return $http.post('api/analytic/orders', data);
      },
      customerAccounting: function (data) {
        return $http.post('api/analytic/customer-accounting', data);
      },
      customerBilling: function (data) {
        return $http.post('api/analytic/customer-billing', data);
      },
      accountingOrders: function (data) {
        return $http.post('api/analytic/accounting-orders', data);
      },
      customerOrders: function (data) {
        return $http.post('api/analytic/customer-orders', data);
      },
      generalOrderReport: function (data) {
        return $http.post('api/analytic/general-order-report', data);
      },
      profit: function (data) {
        return $http.post('api/analytic/profit', data);
      },
      fail: function (data) {
        return $http.post('api/analytic/fail', data);
      },
      customerProfit: function (data) {
        return $http.post('api/analytic/customer-profit', data);
      },
      customers: function (data) {
        return $http.post('api/analytic/customers', data);
      },
      forms: function (data) {
        return $http.post('api/analytic/forms', data);
      },
      workers: function (data) {
        return $http.post('api/analytic/workers', data);
      },
      filter: function (data) {
        return $http.post('api/filter-analytic', data);
      },
      addPayment: function (data) {
        return $http.post('api/add-payment', data);
      },
      deletePayment: function (id) {
        return $http.delete('api/delete-payment/' + id);
      },
      invoices: function (data) {
        return $http.post('api/analytic/invoices', data);
      },
      collections: function (data) {
        return $http.post('api/analytic/collections', data);
      },
      earnings: function (data) {
        return $http.post('api/analytic/earnings', data);
      },
      orderDashboard: function (data) {
        return $http.post('api/analytic/order-dashboard', data);
      },
      orderDashboardSingle: function (data) {
        return $http.post('api/analytic/order-dashboard-single', data);
      },
      support: function (data) {
        return $http.post('api/supports', data);
      },
      supportOrders: function (data) {
        return $http.post('api/supports/orders', data);
      },
      showCustomerOrders: function (data) {
        return $http.post('api/get-customer-orders', data);
      },
      showOrders: function (data) {
        return $http.post('api/get-orders', data);
      },
    };
  }]);