bovoApp.
factory('Transfer', ['$http', function($http) {
    return {
      all: function() {
        return $http.get('api/transfers');
      },
      get: function(id) {
        return $http.get('api/transfer/' + id);
      },
      new: function(id) {
        return $http.get('api/new-transfer');
      },
      delete: function(id) {
        return $http.delete('api/delete-transfer/' + id);
      },
      save: function(data) {
        return $http.post('api/save-transfer',data);
      },
      update: function(data) {
        return $http.post('api/update-transfer',data);
      }
    };
}]);