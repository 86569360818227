bovoApp.
  factory('ShipmentArea', ['$http', function ($http) {
    return {
      all: function () {
        return $http.get('api/shipment-areas');
      },
      get: function (id) {
        return $http.get('api/shipment-area/' + id);
      },
      new: function (id) {
        return $http.get('api/new-shipment-area');
      },
      delete: function (id) {
        return $http.delete('api/delete-shipment-area/' + id);
      },
      save: function (data) {
        return $http.post('api/save-shipment-area', data);
      },
      update: function (data) {
        return $http.post('api/update-shipment-area', data);
      }
    };
  }]);