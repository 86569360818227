bovoApp.
  factory('Route', ['$http', function ($http) {
    return {
      routeOrders: function (data) {
        return $http.post('api/route-orders', data);
      },
      initRoute: function (data) {
        return $http.post('api/init-routes', data);
      },
      initData: function () {
        return $http.get('api/routing-data');
      },
      optimize: function (data) {
        return $http.post('api/optimize-orders', data);
      },
      assignToWorker: function (data) {
        return $http.post('api/assing-worker-to-route', data);
      },
      changeRouteStatus: function (data) {
        return $http.post('api/change-route-status', data);
      },
      delete: function (id) {
        return $http.delete('api/delete-route/' + id);
      },
      distancematrix: function (data) {
        return $http.post('api/distancematrix', data);
      },
    };
  }]);