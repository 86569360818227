bovoApp.
  factory('FailCall', ['$http', function ($http) {
    return {
      all: function (type) {
        return $http.get('api/fail-calls?type=' + type);
      },
      get: function (id) {
        return $http.get('api/fail-call/' + id);
      },
    };
  }]);