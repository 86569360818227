bovoApp.controller('EarningController', ['$scope', 'Earning', '$location', '$routeParams', '$filter', '$translate',
  function ($scope, Earning, $location, $routeParams, $filter, $translate) {
    $scope.deleted = [];

    $scope.selectAll = function (select) {
      if (select) {
        $scope.deleted = [];
        for (let i = 0; i < $scope.data.earnings.data.length; i++) {
          $scope.deleted.push($scope.data.earnings.data[i].id);
        }
      } else {
        $scope.deleted = [];
      }
    };

    $scope.filterData = {
      station_id: [],
      price: '',
      vehicle: [],
      workers: [],
      page: 1,
      date: {
        startDate: null,
        endDate: null
      }
    }

    $scope.clearFilter = function (page) {
      $scope.filterData = {
        station_id: [],
        price: '',
        vehicle: [],
        workers: [],
        page: 1,
        date: {
          startDate: null,
          endDate: null
        }
      }
      $scope.all();
    }

    $scope.$on('refresh_data', function () {
      console.log('refreshing map...')
      $scope.all();
    }, true);

    $scope.$on('new_data', function () {
      $scope.earning = {}
      $scope.new();
      $('#earningDetail').modal('hide');
      $('#newEarning').modal('show');
    }, true);

    $scope.editEarning = function (page) {
      $scope.new();
      $('#earningDetail').modal('hide');
      $('#newEarning').modal('show');
    }

    $scope.getPage = function (page) {
      $scope.filterData.page = page;
      $scope.all();
    }

    $scope.all = function (type) {
      if ($routeParams.worker_id) {
        $scope.filterData.workers.push($routeParams.worker_id);
      }
      if ($routeParams.summary) {
        $scope.filterData.summary = true;
      }
      Earning.all($scope.filterData).success(function (data, status, headers, config) {
        $scope.data = data;
        if (type && type == 'open') {
          var tempEarning = $scope.data.earnings.data.find(function (element) {
            return element.id == $scope.earning.id;
          })
          $scope.openDetail(tempEarning);
        }
      });
    }

    $scope.get = function () {
      Earning.get($routeParams.id).success(function (data, status, headers, config) {
        $scope.data = data;
        jQuery('#date').datetimepicker({
          timepicker: false,
          datepicker: true,
          format: 'Y-m-d'
        });
      });
    }

    $scope.new = function () {
      Earning.new().success(function (data, status, headers, config) {
        jQuery('#date').datetimepicker({
          timepicker: false,
          datepicker: true,
          format: 'Y-m-d'
        });
      });
    }

    $scope.delete = function (type) {
      if (type == 'set') {
        $('#delete').modal('show');
      } else if (type == 'delete') {
        Earning.delete($scope.deleted).success(function (data, status, headers, config) {
          Messenger().post(data.message);
          for (var i = $scope.deleted.length - 1; i >= 0; i--) {
            var index = $scope.data.earnings.data.indexOf($filter('filter')($scope.data.earnings.data, {
              id: $scope.deleted[i]
            })[0]);
            if (index > -1)
              $scope.data.earnings.data.splice(index, 1);
          }
          $scope.deleted = [];
          $('#delete').modal('hide');
        }).error(function (data) {
          Messenger().post({
            message: data.message,
            type: "error"
          });
        });
      }
    }

    $scope.save = function () {
      if ($scope.earning.id) {
        $scope.update();
      } else {
        Earning.save($scope.earning).success(function (data, status, headers, config) {
          $scope.data = data;
          Messenger().post(data.message);
          $('#newEarning').modal('hide');
          $scope.all();
        }).error(function (data) {
          Messenger().post({
            message: data.message,
            type: "error"
          });
        });
      }
    }

    $scope.update = function () {
      Earning.update($scope.earning).success(function (data, status, headers, config) {
        $scope.data = data;
        Messenger().post(data.message);
        $('#newEarning').modal('hide');
        $scope.all('open');

      }).error(function (data) {
        Messenger().post({
          message: data.message,
          type: "error"
        });
      });
    }

    $scope.openDetail = function (data) {
      $scope.earning = data;
      $('#earningDetail').modal('show');
    }

    $scope.toggleSelection = function toggleSelection(id) {
      var idx = $scope.deleted.indexOf(id);
      if (idx > -1) {
        $scope.deleted.splice(idx, 1);
      } else {
        $scope.deleted.push(id);
      }
    };
    $scope.earning = {
      count: 3,
      earnings: []
    }
    jQuery('#date').datetimepicker({
      timepicker: false,
      datepicker: true,
      format: 'Y-m-d'
    });

    $scope.showCompletedOrders = function (earning) {
      var data = {
        earning_id: earning.id,
        scheduled_time: {
          startDate: moment(earning.date).startOf('day'),
          endDate: moment(earning.date).endOf('day'),
        },
        // workSteps: [$scope.data.completed.id.toString()]
      }
      var url = 'orders?data=' + JSON.stringify(data);
      window.open(url, '_blank');
    }

    $scope.openPayment = function (payment) {
      $scope.payment = payment;
      jQuery('#payment_date').datetimepicker({
        timepicker: false,
        datepicker: true,
        format: 'Y-m-d'
      });
      $('#earningDetail').modal('hide');
      $('#newEarningPayment').modal('show');
    }

    $scope.newPayment = function () {
      $scope.payment = {};
      jQuery('#payment_date').datetimepicker({
        timepicker: false,
        datepicker: true,
        format: 'Y-m-d'
      });
      $('#earningDetail').modal('hide');
      $('#newEarningPayment').modal('show');
    }

    $scope.addPayment = function () {
      if ($scope.payment.id) {
        $scope.updatePayment();
      } else {
        $scope.payment.earning_id = $scope.earning.id;
        $scope.payment.price = $scope.payment.rate * $scope.payment.qty;
        Earning.addPayment($scope.payment).success(function (data, status, headers, config) {
          $scope.data = data;
          Messenger().post(data.message);
          $('#newEarningPayment').modal('hide');
          $scope.all('open');
        }).error(function (data) {
          Messenger().post({
            message: data.message,
            type: "error"
          });
        });
      }
    }

    $scope.updatePayment = function () {
      $scope.payment.price = $scope.payment.rate * $scope.payment.qty;
      Earning.updatePayment($scope.payment).success(function (data, status, headers, config) {
        $scope.data = data;
        Messenger().post(data.message);
        $('#newEarningPayment').modal('hide');
        $scope.all('open');
      }).error(function (data) {
        Messenger().post({
          message: data.message,
          type: "error"
        });
      });
    }


    $scope.deletePayment = function (payment) {
      if (confirm($translate.instant("ARE_U_SURE_DELETE"))) {
        Earning.deletePayment(payment.id).success(function (data, status, headers, config) {
          Messenger().post(data.message);
          $scope.all('open');
        }).error(function (data) {
          Messenger().post({
            message: data.message,
            type: "error"
          });
        });
      }
    }

    $scope.getPrice = function (payment) {
      try {
        $scope.payment.price = $scope.payment.rate * $scope.payment.qty;
      } catch (error) {
      }
    }

    $scope.getEarningPrice = function (earning) {
      try {
        $scope.earning.totla = $scope.earning.price * $scope.earning.qty;
      } catch (error) {
      }
    }

  }
]);