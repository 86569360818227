bovoApp.controller('FormAnalyticController', ['$scope', 'Analytic','Account', '$location','FileUploader','$localStorage',
  function ($scope, Analytic, Account, $location, FileUploader, $localStorage) {

		$scope.filterData = {
      export: false,
			customers: [],
			workers: [],
			workSteps: [],
			forms : null,
			page: 1,
			form_id: 0,
			created_at : { 
				startDate: null, 
				endDate: null 
			},
			scheduled_time : { 
				startDate: null, 
				endDate: null 
			}
		}
		
		$scope.$on('refresh_data', function() {
			console.log('refreshing form reports...')
			$scope.init();
			$scope.selected_form = $scope.filterData.forms;
		}, true);

		$scope.$watch('filterData', function() {
			$scope.init();
			$scope.selected_form = $scope.filterData.forms;
		}, true);
		
		$scope.init = function (type) {
			Analytic.forms($scope.filterData).success(function(data, status, headers, config) {
				if(typeof type !== undefined && type == 'export') {
			    $scope.filterData.export = false;
					window.location.href = data.url;					
        }else {
					$scope.data = data;
					if($scope.filterData.form_id == 0) {
						//$scope.filterData.form_id = data.selected_form;
					}
          $scope.series = ['Toplam Tanımlı Form','Toplam Form'];
          $scope.lineSeries = ['Toplam Form'];
          $scope.chartData = [data.forms.length, data.count.total];
          $scope.chart2Data = [data.chart.count];
        }
			});
		};

		$scope.getPage = function(page) {
			$scope.filterData.page = page;
			$scope.filter();
		}

		$scope.export = function(page) {
			$scope.filterData.export = true;
			$scope.init('export');
		}

		$scope.sort = function(field,sort) {
			$scope.filterData.field = field;
			$scope.filterData.sort = sort;
			$scope.filter();
		}

		$scope.filter = function (type) {
			if(type == 'clear') {
				$scope.filterData = {
          export: false,
					customers: [],
					workers: [],
					workSteps: [],
					forms : {},
					page: 1,
					created_at : { 
						startDate: null, 
						endDate: null 
					},
					scheduled_time : { 
						startDate: null, 
						endDate: null 
					}
				}
			}
			$scope.init();
		}

		$scope.getArrayVal = function(data) {
			try {
					return JSON.parse(data);
			} catch (e) {
					return data;
			}
      return data;
    }

  }
]);
