bovoApp.controller('PaymentController', ['$scope', 'Payment', '$location','$routeParams','$filter',
  function ($scope, Payment, $location, $routeParams, $filter) {
    $scope.paymentData = {};
    $scope.loading = false;

    $scope.userCount = 0;
    $scope.extraUserCount = 0;
    $scope.time = 6;

    $scope.init = function () {
      Payment.init().success(function(data, status, headers, config) {
        $scope.data = data;
        $scope.workerCount = [];
        workerCount.forEach(function(element) {
          //if(data.worker_count <= element.number * 20) {
            $scope.workerCount.push(element)
          //}
        }, this);

        if(data.type == 'premium') {
          $scope.extraCount = workerCount;
          $scope.extra = {
            'type' : 'extra',
            'userCount' : '',
            'time' : Number(data.remaining_day)
          };
        }

      });
    };

    $scope.setValue = function(value) {
      $scope.time = value;
    }

    $scope.calculatePrice = function() {
      $scope.total = 0;
      if($scope.userCount == 1) {
        $scope.total = Number($scope.time) * 55;
      } else {
        $scope.total = ( Number($scope.time) * 55 ) + ( Number($scope.userCount) - 1 ) * Number($scope.time) * 50 ;
      }

      var total = Number( $scope.total );
      var kdv = Number(total * 0.18);
      return Number(total + kdv).toLocaleString();
    }


    $scope.payment = function(type) {
      $scope.loading = true;
      $scope.errorMessage = "";
      $scope.paymentData = {
        'time' : $scope.time,
        'userCount' : $scope.userCount  
      };
      if(typeof type !== undefined && type == 'extra') {
        $scope.paymentData = $scope.extra;
      }
      Payment.payment($scope.paymentData).success(function(data, status, headers, config) {
        $scope.loading = false;
        window.location.replace('odeme/' + data);
      }).error(function(data) {
        $scope.loading = false;
        $scope.errorMessage = data;
      });
    }

    var workerCount = [
      {
        number : 1,
        name : '50 Kredi'
      },
      {
        number : 2,
        name : '100 Kredi'
      },
      {
        number : 3,
        name : '150 Kredi'
      },
      {
        number : 4,
        name : '200 Kredi'
      },
      {
        number : 5,
        name : '250 Kredi'
      },
      {
        number : 6,
        name : '300 Kredi'
      },
      {
        number : 7,
        name : '400 Kredi'
      },
      {
        number : 8,
        name : '500 Kredi'
      },
      {
        number : 9,
        name : '600 Kredi'
      },
      {
        number : 10,
        name : '700 Kredi'
      },
      {
        number : 10,
        name : '800 Kredi'
      },
      {
        number : 10,
        name : '900 Kredi'
      },
      {
        number : 10,
        name : '1000 Kredi'
      }
    ];

  }
]);
