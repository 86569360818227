bovoApp.
factory('PricingCity', ['$http', function($http) {
    return {
      all: function() {
        return $http.get('api/pricing-cities');
      },
      get: function(id) {
        return $http.get('api/pricing-city/' + id);
      },
      new: function(id) {
        return $http.get('api/new-pricing-city');
      },
      delete: function(id) {
        return $http.delete('api/delete-pricing-city/' + id);
      },
      save: function(data) {
        return $http.post('api/save-pricing-city',data);
      },
      update: function(data) {
        return $http.post('api/update-pricing-city',data);
      }
    };
}]);