bovoApp.controller('RouteListController', ['$scope', 'RouteList', 'Route', '$location', '$routeParams', '$filter', '$rootScope', 'Order', '$translate', 'ngAudio',
    function($scope, RouteList, Route, $location, $routeParams, $filter, $rootScope, Order, $translate, ngAudio) {
        var successSound = ngAudio.load("sounds/success.mp3");

        $scope.deleted = [];
        var statusNew = ['', 'ring', 'station', 'transfer'];
        var completed = ['complete'];
        var fail = ['fail', 'refund'];
        $scope.sortType = 'user.status';
        $scope.sortReverse = false;
        var onWay = ['last'];

        $scope.progressBar = function(order_count, completed_order_count) {

            return Math.ceil((order_count / completed_order_count) * 100)

        };

        $scope.alertControl = function(item) {
            var control = item
            var hour = new Date().getHours();

            if (control < 20 && hour >= 13 || control < 60 && hour >= 16 || control < 80 && hour >= 19) {
                return false
            } else {
                return true
            }
        }

        $scope.getProgressColor = function(item) {
            var percent = item
            if (percent >= 75) {
                return 'green'
            } else if (percent >= 50 && percent < 75) {
                return 'yellow'
            } else if (percent >= 25 && percent < 50) {
                return 'orange'
            } else if (percent < 25) {
                return 'red'
            }
        }

        $scope.selectAll = function(select) {
            if (select) {
                $scope.deleted = [];
                for (let i = 0; i < $scope.data.routes.data.length; i++) {
                    $scope.deleted.push($scope.data.routes.data[i].id);
                }
            } else {
                $scope.deleted = [];
            }
        };

        $scope.oldRoute = {};

        $scope.changeWorker = function() {
            if (confirm('Bu rotanın sahibi değiştirilecektir, onaylıyor musunuz ?')) {
                Route.assignToWorker({
                    worker_id: $scope.route.worker.id,
                    route_id: $scope.route.id
                }).success(function(data, status, headers, config) {
                    Messenger().post('Başarılı');
                    $scope.all();
                }).error(function(data) {
                    $scope.route.worker = $scope.oldRoute.worker;
                    alert(data.message);
                });
            } else {
                $scope.route.worker_id = 0;
            }
        }

        $scope.changeRouteStatus = function() {
            if (confirm('Bu rotanın durumu değiştirilecektir, onaylıyor musunuz ?')) {
                Route.changeRouteStatus({
                    status: $scope.route.status,
                    route_id: $scope.route.id
                }).success(function(data, status, headers, config) {
                    Messenger().post('Başarılı');
                    $scope.all();
                }).error(function(data) {
                    $scope.route.status = $scope.oldRoute.status;
                    alert(data.message);
                });
            } else {
                $scope.route.status = $scope.oldRoute.status;
            }
        }

        $scope.changeItem = function(type, code, status, item) {
            if (confirm('Durum değiştirilecektir, onaylıyor musunuz ?')) {
                RouteList.changeItem({
                    type: type,
                    code: code,
                    status: status
                }).success(function(data, status, headers, config) {
                    Messenger().post('Başarılı');
                }).error(function(data) {
                    alert('Error');
                });
            } else {
                item[type] = !status;
                $scope.route.worker_id = 0;
            }
        }

        $scope.readAll = function(type, id, field, route_id) {
            if (confirm($translate.instant("ARE_U_SURE_DELETE"))) {
                RouteList.readAll({
                    type: type,
                    id: id,
                    field: field
                }).success(function(data, status, headers, config) {
                    Messenger().post('Başarılı');
                    RouteList.get(route_id).success(function(data, status, headers, config) {
                        $scope.route = data.route;
                        $scope.oldRoute = data.route;
                        $scope.steps = data.steps;
                    });
                }).error(function(data) {
                    alert('Error');
                });
            } else {
                item[type] = !status;
                $scope.route.worker_id = 0;
            }
        }

        $scope.changeStatus = function(order, oldStatus) {
            if (confirm('Durum değiştirilecektir, onaylıyor musunuz ?')) {
                Order.changeWorkStatus(order).success(function(data, status, headers, config) {
                    Messenger().post('Başarılı');
                });
            } else {
                order.status = oldStatus;
            }
        }

        $scope.updateBarcode = function() {
            if (confirm('Bu rotanin barkodu değişecektir, onaylıyor musunuz ?')) {
                RouteList.updateBarcode($scope.route).success(function(data, status, headers, config) {
                    Messenger().post('Barkod Güncellendi');
                });
            }
        }

        $scope.changeArrived = function(order, oldArrived) {
            if (confirm('Durum değiştirilecektir, onaylıyor musunuz ?')) {
                Order.changeArrived({ order_id: order.id, arrived: order.arrived }).success(function(data, status, headers, config) {
                    Messenger().post('Başarılı');
                });
            } else {
                order.arrived = oldArrived;
            }
        }

        $scope.filterData = {
            station_id: [],
            price: '',
            vehicle: [],

            workers: [],
            page: 1,
            date: {
                startDate: null,
                endDate: null
            }
        }

        $scope.clearFilter = function(page) {
            $scope.filterData = {
                station_id: [],
                price: '',
                vehicle: [],
                workers: [],

                page: 1,
                date: {
                    startDate: null,
                    endDate: null
                }
            }
            $('.selectpicker').selectpicker('val', []);
            $scope.all();
        }

        $scope.$on('refresh_data', function() {
            console.log('refreshing map...')
            if (window.location.pathname == '/routes') {
                $scope.all();
            } else if (window.location.pathname == '/waiting-routes') {
                $scope.oldRoutes();
            }
        }, true);

        $scope.all = function() {
            if ($routeParams.worker_id) {
                $scope.filterData.workers.push($routeParams.worker_id);
            }
            if ($routeParams.route_id) {
                $scope.openDetail($routeParams.route_id);
                // return true;
            }
            RouteList.all($scope.filterData).success(function(data, status, headers, config) {
                $scope.data = data;


            });
        }

        $scope.oldRoutes = function() {
            RouteList.oldRoutes($scope.filterData).success(function(data, status, headers, config) {
                $scope.data = data;
            })

        }

        $scope.$on('new_data', function() {
            $scope.new();
            $('#newRoute').modal('show');
        }, true);

        $scope.$on('edit_data', function() {
            $scope.editWorker(data);
            $('#newRoute').modal('show');
        }, true);

        $scope.editRoute = function(route) {
            $rootScope.route = route;
            $rootScope.route.date = moment(route.scheduled_start).format('YYYY-MM-DD');
            $rootScope.route.scheduled_start = moment(route.scheduled_start).format('hh:mm');
            $rootScope.route.scheduled_end = moment(route.scheduled_end).format('hh:mm');
            $('#newRoute').modal('show');
            $('#routesDetail').modal('hide');
            RouteList.new().success(function(data, status, headers, config) {
                $rootScope.stations = data.stations;
                jQuery('#date').datetimepicker({
                    timepicker: false,
                    datepicker: true,
                    format: 'Y-m-d'
                });
                jQuery('#start').datetimepicker({
                    format: 'H:i',
                    formatTime: 'H:i',
                    datepicker: false,
                    step: 30,
                });
                jQuery('#end').datetimepicker({
                    format: 'H:i',
                    formatTime: 'H:i',
                    datepicker: false,
                    step: 30,
                })
            });
        }

        $scope.getPage = function(page) {
            $scope.filterData.page = page;
            $scope.all();
        }

        $scope.get = function() {
            RouteList.get($routeParams.id).success(function(data, status, headers, config) {
                $scope.data = data;
                jQuery('#date').datetimepicker({
                    timepicker: false,
                    datepicker: true,
                    format: 'Y-m-d'
                });
                jQuery('#start').datetimepicker({
                    format: 'H:i',
                    formatTime: 'H:i',
                    datepicker: false,
                    step: 30,
                });
                jQuery('#end').datetimepicker({
                    format: 'H:i',
                    formatTime: 'H:i',
                    datepicker: false,
                    step: 30,
                });
            });
        }

        $scope.new = function() {
            RouteList.new().success(function(data, status, headers, config) {
                $rootScope.stations = data.stations;
                $scope.route = {
                    scheduled_start: '10:00',
                    scheduled_end: '14:00',
                }
                $rootScope.route = {
                    scheduled_start: '10:00',
                    scheduled_end: '14:00',
                }
                jQuery('#date').datetimepicker({
                    timepicker: false,
                    datepicker: true,
                    format: 'Y-m-d'
                });
                jQuery('#start').datetimepicker({
                    format: 'H:i',
                    formatTime: 'H:i',
                    datepicker: false,
                    step: 30,
                });
                jQuery('#end').datetimepicker({
                    format: 'H:i',
                    formatTime: 'H:i',
                    datepicker: false,
                    step: 30,
                })
            });
        }

        $scope.delete = function(type) {
            if (type == 'set') {
                $('#delete').modal('show');
            } else if (type == 'delete') {
                RouteList.delete($scope.deleted).success(function(data, status, headers, config) {
                    Messenger().post(data.message);
                    for (var i = $scope.deleted.length - 1; i >= 0; i--) {
                        var index = $scope.data.routes.data.indexOf($filter('filter')($scope.data.routes.data, {
                            id: $scope.deleted[i]
                        })[0]);
                        if (index > -1)
                            $scope.data.routes.data.splice(index, 1);
                    }
                    $scope.deleted = [];
                    $('#delete').modal('hide');
                }).error(function(data) {
                    Messenger().post({
                        message: data.message,
                        type: "error"
                    });
                });
            }
        }

        $rootScope.saveRoute = function(route) {
            if (route.id) {
                $scope.update();
            } else {
                RouteList.save(route).success(function(data, status, headers, config) {
                    $scope.data = data;
                    setTimeout(() => {
                        $scope.$apply()
                    }, 300);
                    Messenger().post(data.message);
                    $('#newRoute').modal('hide');
                    $scope.$broadcast('refresh_data');
                }).error(function(data) {
                    Messenger().post({
                        message: data.message,
                        type: "error"
                    });
                });
            }
        }

        $scope.update = function() {
            RouteList.update($scope.route).success(function(data, status, headers, config) {
                $scope.data = data;
                $scope.openDetail(data.data)
                Messenger().post(data.message);
                $('#newRoute').modal('hide');
                $scope.all();
            }).error(function(data) {
                Messenger().post({
                    message: data.message,
                    type: "error"
                });
            });
        }

        $scope.openDetail = function(route, tab) {
            // if (tab) {
            //   var url = 'routes?route_id=' + route.id;
            //   if ($scope.filterData.term) {
            //     url += '&term=' + $scope.filterData.term;
            //   }
            //   if ($scope.filterData.qr) {
            //     url += '&qr=' + $scope.filterData.qr;
            //   }
            //   window.open(url, '_blank');
            //   return true;
            // }

            RouteList.get(route && route.id ? route.id : route).success(function(data, status, headers, config) {
                $('#routesDetail').modal('show');
                $scope.route = data.route;
                $scope.workers = data.workers;
                $scope.oldRoute = data.route;
                $scope.steps = data.steps;
                $('.rtstops').click();

                document.getElementById('mapArea').innerHTML = '<div id="routeMap" style="width:100%;height:100%;">></div>';
                $scope.map = L.map('routeMap').setView([38.7267005, 35.2151109], 6);
                L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                    maxZoom: 18,
                    attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                }).addTo($scope.map);

                $scope.orderMarkers = [];

                // Order Markers
                $scope.route.orders.forEach((element, key) => {
                    $scope.createOrderMarker(element, key);
                });

                var boundMarkers = $scope.orderMarkers;

                // Worker Markers
                if ($scope.route.worker) {
                    $scope.createWorkerMarker($scope.route.worker);

                    if ($scope.workerMarker) {
                        boundMarkers.push($scope.workerMarker);
                    }
                }

                // Station Markers
                if ($scope.route.station && $scope.route.station.latitude) {
                    var icon = L.icon({
                        iconUrl: "/img/map-pin-red.svg",
                        iconSize: [48, 48],
                    });

                    var content = '<div id="iw-container">' +
                        '<div class="iw-content"> ' +
                        '<strong>#' + $scope.route.station.name + '</strong></div></div>';

                    var stationMarker = L.marker([Number($scope.route.station.latitude), Number($scope.route.station.longitude)], {
                            icon: icon
                        })
                        .addTo($scope.map)
                        .bindPopup(content);
                    boundMarkers.push(stationMarker);
                }
                var group = new L.featureGroup(boundMarkers);
                $scope.map.fitBounds(group.getBounds());

                // renderRoute($scope.route, '#FF6633');

                $scope.searchTerms = '';
                $scope.searchIdTerms = '';
                if ($routeParams.term) {
                    $scope.searchIdTerms = $routeParams.term;
                }
                if ($routeParams.qr) {
                    $scope.searchTerms = $routeParams.qr;
                }

            }).error(function(data) {
                Messenger().post({
                    message: data.message,
                    type: "error"
                });
            });
        }

        $scope.zoomIn = function(lat, lng) {
            $scope.map.setView([lat, lng], 16);
        }

        function renderRoute(data, color) {
            var waypts = [];
            if (data.orders.length == 0) {
                return false;
            }
            data.orders.forEach((element, index) => {
                var lat = element.location.latitude;
                var lng = element.location.longitude;
                waypts.push({
                    location: {
                        lat: parseFloat(lat),
                        lng: parseFloat(lng)
                    },
                    stopover: true
                });

                var label = {
                    color: '#fff',
                    fontWeight: 'bold',
                    fontSize: '14px',
                    text: (index + 1).toString()
                };
                $scope.orderMarkers[element.id].setLabel(label);
                // data.orders[element].newSort = index;
            });

            $scope.directionsService = new google.maps.DirectionsService();
            $scope.directionsDisplay = new google.maps.DirectionsRenderer({
                map: $scope.map,
                suppressMarkers: true,
                polylineOptions: {
                    strokeColor: '#19BFCA',
                    strokeOpacity: 1.0,
                    strokeWeight: 6
                }
            });
            var request = {
                origin: {
                    lat: parseFloat(data.station.latitude),
                    lng: parseFloat(data.station.longitude)
                },
                destination: {
                    lat: parseFloat(data.orders[0].location.latitude),
                    lng: parseFloat(data.orders[0].location.longitude)
                },
                waypoints: waypts,
                travelMode: 'DRIVING',
                optimizeWaypoints: false,
                provideRouteAlternatives: false,
            };

            var markers = [];
            $scope.directionsService.route(request, function(response, status) {
                if (status == google.maps.DirectionsStatus.OK) {
                    $scope.directionsDisplay.setDirections(response);
                    if (response.routes.length == 0) {
                        return true;
                    }
                    var my_route = response.routes[0];
                    var totalDistance = 0;
                    var totalDuration = 0;
                    // console.log(my_route)
                    for (var i = 0; i < my_route.legs.length; i++) {
                        // console.log(my_route)
                        totalDistance += my_route.legs[i].distance.value;
                        totalDuration += my_route.legs[i].duration.value;
                        // totalDuration += (data.stop_time * 60)
                        // var label = { color: '#333', fontWeight: 'bold', fontSize: '16px', text: 123 };
                        // marker.setLabel(label);
                        // console.log(my_route.legs[i])
                        // markers[i] = new SlidingMarker({
                        //   position: my_route.legs[i].start_location,
                        //   icon: pinSymbol(color),
                        //   label: {
                        //     text: "" + (i + 1),
                        //     color: "white"
                        //   },
                        //   map: $scope.map,
                        //   visible : true
                        // });
                        // google.maps.event.addListener(markers[i], 'click', function () {
                        //   $scope.selectedMarkers.push(markers[i])
                        //   console.log(markers[i])
                        //   markers[i].setIcon('https://chart.googleapis.com/chart?chst=d_map_pin_letter&chld=%7C333333');
                        // });

                    }
                    // my_route.waypoint_order.forEach((element, index) => {
                    //   var label = {
                    //     color: '#fff',
                    //     fontWeight: 'bold',
                    //     fontSize: '14px',
                    //     text: (index + 1).toString()
                    //   };
                    //   $scope.orderMarkers[data.orders[element].id].setLabel(label);
                    //   data.orders[element].newSort = index;
                    // });
                    data.distance = (parseFloat(totalDistance) / 1610).toFixed(2);
                    var totalRouteTime = totalDuration * 1000; // ms
                    var totalStopTime = data.stop_time * 60 * 1000 * (data.order_count - 1); // ms
                    var totalTime = Number(totalRouteTime) + Number(totalStopTime);
                    data.time = $scope.humanizeDuration(totalTime);
                    setTimeout(() => {
                        $scope.$apply();
                    }, 300);
                }
            });
        }

        $scope.createOrderMarker = function(order, key) {
            var markerIconClass = 'order-standart-icon';

            if (order.location) {

                var location = [Number(order.location.latitude), Number(order.location.longitude)];
                if (order.status && completed.indexOf(order.status.value) > -1) {
                    markerIconClass = 'order-completed-icon';
                } else if (order.status && fail.indexOf(order.status.value) > -1) {
                    markerIconClass = 'order-fail-icon';
                } else {
                    markerIconClass = 'order-standart-icon';
                }

                var icon = L.divIcon({
                    className: markerIconClass,
                    shadowUrl: '/img/marker-shadow.png',
                    iconSize: [57, 79],
                    shadowSize: [79, 84],
                    iconAnchor: [27, 78],
                    shadowAnchor: [18, 83],
                    popupAnchor: [0, -82],
                    html: '<span class="center-pin-number">' + (key + 1) + '</span>'
                })

                var content = '<div id="iw-container">' +
                    '<div class="iw-content"> ' +
                    '<strong>#' + order.id + '</strong>';

                if (order.special_value)
                    content += ' - <strong>' + order.special_value + '</strong>';

                content += '<hr style="margin:17px 0">' +
                    '<p>' + order.customer.customer_name + ' </p>' +
                    '<p>' + order.location.name + ' </p>' +
                    '<p>' + order.location.address + '</p>' +
                    '<div class="row"><div class="col-md-6 pull-left">' +
                    '<a href="#" onclick="angular.element(this).scope().zoomIn(' + order.location.latitude + ',' + order.location.longitude + ')" class="bovo-button blue btn-xs"><i class="fa fa-search-plus"></i></a> </div>' +
                    '<div class="col-md-6 pull-left"><a href="#" onclick="angular.element(this).scope().getOrderDetails(' + order.id + ')" class="bovo-button blue btn-xs pull-right"><i class="fa fa-eye"></i></a> </div></div>'

                if (order.worker)
                    content += '<p>' + order.worker.full_name + '</p>';
                content += '</div></div>';

                var omarker = L.marker(location, {
                        icon: icon
                    })
                    .addTo($scope.map)
                    .bindPopup(content);

                $scope.orderMarkers.push(omarker);
            }
        }

        $scope.createWorkerMarker = function(worker) {

            if (worker.lat.length == 0 || worker.lon.length == 0) {
                return
            }

            if ($scope.workerMarker) {
                var location = new L.LatLng(Number(worker.lat), Number(worker.lon));
                $scope.workerMarker.setLatLng(location)
                $scope.workerMarker._popup.setContent(infoWindow(worker))
                return;
            }

            var icon = L.icon({
                iconUrl: '/img/marker-truck.png',
                shadowUrl: '/img/marker-shadow.png',
                iconSize: [57, 79],
                shadowSize: [79, 84],
                iconAnchor: [27, 78],
                shadowAnchor: [18, 83],
                popupAnchor: [0, -82]
            });

            $scope.workerMarker = L.marker([Number(worker.lat), Number(worker.lon)], {
                    icon: icon
                })
                .addTo($scope.map)
                .bindPopup(infoWindow(worker));
        }

        $scope.$on('location_update', function(event, data, nextParams, fromState) {
            if ($scope.route.worker) {
                if (data.worker.id == $scope.route.worker.id) {
                    $scope.route.worker = data.worker
                    $scope.createWorkerMarker(data.worker);
                }
            }
        });

        function infoWindow(data) {
            try {
                var extra = angular.fromJson(data.location_info);
            } catch (error) {
                var extra = {
                    accuracy: '-',
                    timestamp: '-',
                };
            }
            return content = '<div id="iw-container">' +
                '<div class="iw-content">' +
                '<div class="iw-subTitle" style="display: contents;">' + data.full_name + '</div>' +
                '<img src="https://s3.eu-central-1.amazonaws.com/bovo/' + data.profile_photo + '" class="img-circle" height="44" width="44">' +
                // '<p><strong> Şarj : </strong>'+Math.round(extra.battery * 100 )+' %</p>' +
                '<p><strong> Accuary : </strong>' + Math.round(extra.accuracy) + ' Meter</p>' +
                '<p><strong> Last Location Update : </strong>' + moment(extra.timestamp).format('D MMM, HH:mm') + '</p>' +
                // '<hr style="margin:17px 0">' +
                // '<div class="row"><div class="col-md-6 pull-left">' +
                // '<a href="#" onclick="angular.element(this).scope().zoomIn(' + data.lat + ',' + data.lon + ')" class="btn btn-xs btn-warning"><i class="fa fa-search-plus"></i></a> </div>' +
                // '<div class="col-md-6 pull-left"><a href="#" onclick="angular.element(this).scope().getWorkerDetails(' + data.id + ')" class="btn btn-xs btn-primary"><i class="fa fa-eye"></i></a> </div>'
                '</div></div></div>'
        }

        $scope.toggleSelection = function toggleSelection(id) {
            var idx = $scope.deleted.indexOf(id);
            if (idx > -1) {
                $scope.deleted.splice(idx, 1);
            } else {
                $scope.deleted.push(id);
            }
        };

        $rootScope.totalOrders = {
            todayTotal: null,
            todayCompletedTotal: null,

        }

        $scope.totalOrder = function() {
            RouteList.totalOrder().success(function(data) {
                $rootScope.totalOrders.todayTotal = data.todayTotal
                $rootScope.totalOrders.todayCompletedTotal = data.todayCompletedTotal
            })
        }

        $scope.downloadExcel = function() {
            $scope.filterData.export = true;
            RouteList.all($scope.filterData).success(function(data, status, headers, config) {
                window.location.href = data['url'];
                delete $scope.filterData.export;
            });
        };

        $scope.addOrderData = {}
        $scope.addOrder = function() {
            $scope.addOrderData.route_optimization_id = $scope.route.id
            Order.addRouteOrder($scope.addOrderData).success(function(data, status, headers, config) {
                $scope.addOrderData.code = null
                Messenger().post(data.message);
                successSound.play()
            }).error(function(data) {
                $scope.addOrderData.code = null
                Messenger().post({
                    message: data.message,
                    type: "error"
                });
            });
        };

    }
]);