bovoApp.
  factory('Product', ['$http', function($http) {
    return {
      all: function(data) {
        return $http.post('api/products', data);
      },
      get: function(id) {
        return $http.get('api/product/' + id);
      },
      new: function(id) {
        return $http.get('api/new-product');
      },
      delete: function(id) {
        return $http.delete('api/delete-product/' + id);
      },
      save: function(data) {
        return $http.post('api/save-product',data);
      },
      update: function(data) {
        return $http.post('api/update-product',data);
      },
    };
}]);