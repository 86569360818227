bovoApp.controller('TransferController', ['$scope', 'Transfer', '$location', '$routeParams', '$filter', '$translate',
  function ($scope, Transfer, $location, $routeParams, $filter, $translate) {
    $scope.deleted = [];
    var componentForm = {
      street_number: 'short_name',
      route: 'long_name',
      locality: 'long_name',
      administrative_area_level_1: 'short_name',
      administrative_area_level_2: 'short_name',
      country: 'long_name',
      postal_code: 'short_name'
    };
    var mapping = {
      street_number: 'number',
      route: 'street',
      locality: 'state',
      administrative_area_level_1: 'city',
      administrative_area_level_2: 'district',
      country: 'country',
      postal_code: 'zip'
    };

    $scope.all = function () {
      Transfer.all().success(function (data, status, headers, config) {
        $scope.data = data;
      });
    }

    $scope.openDetail = function (item) {
      Transfer.get(item.id).success(function (data, status, headers, config) {
        $('#transferModal').modal('show');
        $scope.transfer = data.transfer;
        var center = new google.maps.LatLng(Number(data.transfer.latitude), Number(data.transfer.longitude));
        $scope.map = new google.maps.Map(document.getElementById('mapTransfer'), {
          zoom: 13,
          center: center,
          mapTypeId: google.maps.MapTypeId.ROADMAP
        });
        $scope.marker = new SlidingMarker({
          position: center,
          map: $scope.map,
          visible: true,
          draggable: true
        });
        $scope.marker.addListener('dragend', function (event) {
          var latlng = new google.maps.LatLng(event.latLng.lat(), event.latLng.lng());
          var geocoder = new google.maps.Geocoder();
          geocoder.geocode({
            'latLng': latlng
          }, function (results, status) {
            if (status === google.maps.GeocoderStatus.OK) {
              if (results[0]) {
                var place = results[0];
                var details = place.geometry && place.geometry.location ? {
                  latitude: place.geometry.location.lat(),
                  longitude: place.geometry.location.lng()
                } : {};
                for (var i = 0; i < place.address_components.length; i++) {
                  var addressType = place.address_components[i].types[0];
                  if (componentForm[addressType]) {
                    var val = place.address_components[i][componentForm[addressType]];
                    details[mapping[addressType]] = val;
                  }
                }
                details.formatted = place.formatted_address;
                details.placeId = place.place_id;
                details.address = place.formatted_address;
                // angular.merge($scope.transfer, details);
                $scope.transfer.city = details.state;
                $scope.transfer.district = details.city;
                $scope.transfer.address = details.formatted;
                $scope.transfer.zipcode = details.zip;
                $scope.states.forEach(element => {
                  if (element.abbreviation == details.city) {
                    $scope.transfer.district = element.name;
                    return true;
                  }
                });
                $scope.$apply();
              }
            }
          });
          $scope.transfer.latitude = event.latLng.lat();
          $scope.transfer.longitude = event.latLng.lng();
        });
      });
    }

    $scope.$on('new_data', function () {
      $('#transferModal').modal('show');
      $scope.new();
    }, true);

    $scope.new = function () {
      Transfer.new().success(function (data, status, headers, config) {
        $scope.transfer = {
          'city': 'İstanbul'
        };
        var center = new google.maps.LatLng(Number($scope.authenticatedUser.location.lat), Number($scope.authenticatedUser.location.lon));
        $scope.map = new google.maps.Map(document.getElementById('mapTransfer'), {
          zoom: 13,
          center: center,
          mapTypeId: google.maps.MapTypeId.ROADMAP
        });
        $scope.marker = new SlidingMarker({
          position: center,
          map: $scope.map,
          visible: true,
          draggable: true
        });
        $scope.marker.addListener('dragend', function (event) {
          var latlng = new google.maps.LatLng(event.latLng.lat(), event.latLng.lng());
          var geocoder = new google.maps.Geocoder();

          geocoder.geocode({
            'latLng': latlng
          }, function (results, status) {
            if (status === google.maps.GeocoderStatus.OK) {
              if (results[0]) {
                var place = results[0];
                var details = place.geometry && place.geometry.location ? {
                  latitude: place.geometry.location.lat(),
                  longitude: place.geometry.location.lng()
                } : {};
                for (var i = 0; i < place.address_components.length; i++) {
                  var addressType = place.address_components[i].types[0];
                  if (componentForm[addressType]) {
                    var val = place.address_components[i][componentForm[addressType]];
                    details[mapping[addressType]] = val;
                  }
                }
                details.formatted = place.formatted_address;
                details.placeId = place.place_id;
                details.address = place.formatted_address;
                // angular.merge($scope.transfer, details);
                $scope.transfer.city = details.state;
                $scope.transfer.district = details.city;
                $scope.transfer.address = details.formatted;
                $scope.transfer.zipcode = details.zip;
                $scope.states.forEach(element => {
                  if (element.abbreviation == details.city) {
                    $scope.transfer.district = element.name;
                    return true;
                  }
                });
                $scope.$apply();
              }
            }
          });

          $scope.transfer.latitude = event.latLng.lat();
          $scope.transfer.longitude = event.latLng.lng();
        });
      });
    }

    $scope.getAddress = function (address) {
      if (typeof address.latitude !== 'undefined') {
        var location = new google.maps.LatLng(address.latitude, address.longitude);
        $scope.map.setCenter(location);
        $scope.marker.setDuration(1000);
        $scope.marker.setEasing("swing");
        $scope.marker.setPosition(location);
        // angular.merge($scope.transfer, address);
        if ($scope.transfer) {
          angular.merge($scope.transfer, address);
          $scope.transfer.city = address.city;
          $scope.transfer.district = address.state;
          $scope.transfer.address = address.address;
          $scope.transfer.zipcode = address.zipcode ? address.zipcode : address.zip;
          $scope.states.forEach(element => {
            if (element.abbreviation == address.city) {
              $scope.transfer.district = element.name;
              return true;
            }
          });
        } else {
          angular.merge($scope.transfer, address);
          $scope.transfer.city = address.city;
          $scope.transfer.district = address.state;
          $scope.transfer.address = address.address;
          $scope.transfer.zipcode = address.zipcode ? address.zipcode : address.zip;
          $scope.states.forEach(element => {
            if (element.abbreviation == address.city) {
              $scope.transfer.district = element.name;
              return true;
            }
          });
        }
      }
    }

    $scope.delete = function (type) {
      if (type == 'set') {
        $('#delete').modal('show');
      } else if (type == 'delete') {
        Transfer.delete($scope.deleted).success(function (data, status, headers, config) {
          Messenger().post($scope.translate(data.message.toString()));
          for (var i = $scope.deleted.length - 1; i >= 0; i--) {
            var index = $scope.data.transfers.indexOf($filter('filter')($scope.data.transfers, {
              id: $scope.deleted[i]
            })[0]);
            if (index > -1) {
              $scope.data.transfers.splice(index, 1);
            }
          }
          $scope.deleted = [];
          $('#delete').modal('hide');
        }).error(function (data) {
          Messenger().post({
            message: data.message,
            type: "error"
          });
        });
      }
    }

    $scope.postForm = function (user) {
      if ($scope.transfer.id) {
        $scope.update();
      } else {
        $scope.save();
      }
    }

    $scope.save = function () {
      Transfer.save($scope.transfer).success(function (data, status, headers, config) {
        $scope.data = data;
        Messenger().post('Başarılı');
        $('#transferModal').modal('hide');
        $scope.all();
      }).error(function (data) {
        Messenger().post({
          message: data.message,
          type: "error"
        });
      });
    }

    $scope.update = function () {
      Transfer.update($scope.transfer).success(function (data, status, headers, config) {
        $scope.data = data;
        Messenger().post('Başarılı');
        $('#transferModal').modal('hide');
        $scope.all();
      }).error(function (data) {
        Messenger().post({
          message: data.message,
          type: "error"
        });
      });
    }

    $scope.toggleSelection = function toggleSelection(id) {
      var idx = $scope.deleted.indexOf(id);
      if (idx > -1) {
        $scope.deleted.splice(idx, 1);
      } else {
        $scope.deleted.push(id);
      }
    };

  }
]);