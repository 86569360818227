bovoApp.controller('DashboardAnalyticController', ['$scope', '$rootScope', 'Order', 'Analytic', 'Account', '$location', 'FileUploader', '$http',
	function ($scope, $rootScope, Order, Analytic, Account, $location, FileUploader, $http) {

		$scope.filterProp = [];

		$scope.filterData = {
			export: false,
			customers: [],
			workers: [],
			workSteps: [],
			city: [],
			page: 1,
			date_type: 'estimated_delivery_date',
			scheduled_time: {
				startDate: null,
				endDate: null
			},
		}

		$scope.$on('refresh_data', function () {
			console.log('refreshing order reports...')
			$scope.init();
		}, true);

		$scope.clearFilter = function (page) {
			$scope.filterData = {
				export: false,
				customers: [],
				workers: [],
				workSteps: [],
				city: [],
				page: 1,
				date_type: 'estimated_delivery_date',
				scheduled_time: {
					startDate: null,
					endDate: null
				}
			}
		}

		$scope.getTotal = function (data, index) {
			var total = 0;
			data.forEach(element => {
				total = Number(total) + element[index]
			});
			return total;
		}

		$scope.options = {
			tooltips: {
				enabled: false
			},
			plugins: {
				datalabels: {
					formatter: (value, ctx) => {
						let sum = 0;
						let dataArr = ctx.chart.data.datasets[0].data;
						dataArr.map(data => {
							sum += data;
						});
						let percentage = (value * 100 / sum).toFixed(2) + "%";
						return percentage;
					},
					color: '#fff',
				}
			}
		}

		$scope.goToOrder = function (item) {
			$scope.filterData.city.push(item)
			console.log($scope.filterData.city);

			Order.orders($scope.filterData).success(function (data) {
				console.log(data);
			})

			// const filterOrder = $.post('http://localhost:8000/api/orders', { 'city[]': $scope.filterData.city }, function (d) {
			// 	var newWindow = window.open('http://localhost:8000/orders');
			// 	$(newWindow.document.body).append(d)
			// })
			$scope.filterData.city.splice(0, 1)
			console.log($scope.filterData.city);
		}

		$scope.countData = {}

		$scope.init = function (type) {
			Analytic.orderDashboard($scope.filterData).success(function (data, status, headers, config) {
				$scope.dashboard = data;
				// $scope.orderDeliveryRate = {
				// 	type: "pie",
				// 	backgroundColor: 'none',
				// 	plotarea: {
				// 		backgroundColor: 'transparent'
				// 	},
				// 	plot: {
				// 		borderColor: "#2B313B",
				// 		borderWidth: 5,
				// 		valueBox: {
				// 			placement: 'out',
				// 			text: '%t\n%npv%',
				// 			fontFamily: "Open Sans"
				// 		},
				// 		tooltip: {
				// 			fontSize: '18',
				// 			fontFamily: "Open Sans",
				// 			padding: "5 10",
				// 			text: "%npv%"
				// 		},
				// 		animation: {
				// 			effect: 2,
				// 			method: 5,
				// 			speed: 900,
				// 			sequence: 1,
				// 			delay: 3000
				// 		},
				// 	},
				// 	title: {
				// 		fontColor: "#8e99a9",
				// 		text: 'Kargo Teslim/Gün Oranı',
				// 		align: "center",
				// 		offsetX: 10,
				// 		fontSize: 25
				// 	},
				// 	plotarea: {
				// 		margin: "20 0 0 0"
				// 	},
				// 	series: [
				// 		{
				// 			text: 'Erken',
				// 			values: [ $scope.dashboard.count.one_day.length ]
				// 		},
				// 		{
				// 			text: 'Zamanında Teslim',
				// 			values: [ $scope.dashboard.count.two_day.length ]
				// 		},
				// 		{
				// 			text: 'Geç Teslim',
				// 			values: [ $scope.dashboard.count.three_day.length ]
				// 		},
				// 	],
				// };
				// $scope.customerRate = {
				// 	type: "pie",
				// 	backgroundColor: 'none',
				// 	plotarea: {
				// 		backgroundColor: 'transparent'
				// 	},
				// 	plot: {
				// 		borderColor: "#2B313B",
				// 		borderWidth: 5,
				// 		valueBox: {
				// 			placement: 'out',
				// 			text: '%t\n%npv%',
				// 			fontFamily: "Open Sans"
				// 		},
				// 		tooltip: {
				// 			fontSize: '18',
				// 			fontFamily: "Open Sans",
				// 			padding: "5 10",
				// 			text: "%npv%"
				// 		},
				// 		animation: {
				// 			effect: 2,
				// 			method: 5,
				// 			speed: 900,
				// 			sequence: 1,
				// 			delay: 3000
				// 		},
				// 	},
				// 	title: {
				// 		fontColor: "#8e99a9",
				// 		text: 'Kargo Müşteri Oranı',
				// 		align: "center",
				// 		offsetX: 10,
				// 		fontSize: 25
				// 	},
				// 	plotarea: {
				// 		margin: "20 0 0 0"
				// 	},
				// 	series: data.customer_rate,
				// };
				// $scope.gauge = {
				// 	type: "gauge",
				// 	backgroundColor: 'none',
				// 	plotarea: {
				// 		backgroundColor: 'transparent'
				// 	},
				// 	title: {
				// 		fontColor: "#8e99a9",
				// 		text: 'Bovo Başarı Oranı',
				// 		align: "center",
				// 		offsetX: 0,
				// 		fontSize: 25,
				// 	},
				// 	globals: {
				// 		fontSize: 25
				// 	},
				// 	plotarea: {
				// 		marginTop: 180
				// 	},
				// 	plot: {
				// 		size: '100%',
				// 		valueBox: {
				// 			placement: 'center',
				// 			text: '%v',
				// 			fontSize: 35,
				// 			rules: [{
				// 				rule: '%v >= 90',
				// 				text: '%v<br>Mükemmel'
				// 			},
				// 			{
				// 				rule: '%v < 60 && %v > 90',
				// 				text: '%v<br>İyi'
				// 			},
				// 			{
				// 				rule: '%v < 20 && %v > 60',
				// 				text: '%v<br>Kötü'
				// 			},
				// 			{
				// 				rule: '%v <  0',
				// 				text: '%v<br>Berbat'
				// 			}
				// 			]
				// 		}
				// 	},
				// 	tooltip: {
				// 		borderRadius: 5
				// 	},
				// 	scaleR: {
				// 		aperture: 180,
				// 		minValue: 0,
				// 		maxValue: 100,
				// 		step: 1,
				// 		center: {
				// 			visible: false
				// 		},
				// 		tick: {
				// 			visible: true
				// 		},
				// 		item: {
				// 			offsetR: 0,
				// 			rules: [{
				// 				rule: '%i == 9',
				// 				offsetX: 15
				// 			}]
				// 		},
				// 		labels: ['0', '', '', '', '', '', '20', '60', '90', '', '100'],
				// 		ring: {
				// 			size: 50,
				// 			rules: [{
				// 				rule: '%v <= 20',
				// 				backgroundColor: '#E53935'
				// 			},
				// 			{
				// 				rule: '%v > 20 && %v < 60',
				// 				backgroundColor: '#EF8535'
				// 			},
				// 			{
				// 				rule: '%v >= 60 && %v < 90',
				// 				backgroundColor: '#29B6F6'
				// 			},
				// 			{
				// 				rule: '%v >= 90',
				// 				backgroundColor: '#63BC46'
				// 			}
				// 			]
				// 		}
				// 	},
				// 	refresh: {
				// 		type: "feed",
				// 		transport: "js",
				// 		url: "feed()",
				// 		interval: 1500,
				// 		resetTimeout: 1000
				// 	},
				// 	series: [{
				// 		values: [$scope.successRate], // starting value
				// 		backgroundColor: 'black',
				// 		indicator: [10, 10, 10, 10, 0.75],
				// 		animation: {
				// 			effect: 2,
				// 			method: 1,
				// 			sequence: 4,
				// 			speed: 900
				// 		},
				// 	}]
				// }
				// $scope.orderChart = {
				// 	type: "bar",
				// 	backgroundColor: '#fff',
				// 	title: {
				// 		fontColor: "#8e99a9",
				// 		text: 'Günlük Kargo Sayısı (Son 14 gün)',
				// 		align: "center",
				// 		offsetX: 10,
				// 		fontSize: 25,
				// 		marginBottom: 30
				// 	},
				// 	plot: {
				// 		valueBox: {
				// 			fontColor: '#000'
				// 		},
				// 		animation: {
				// 			delay: 550,
				// 			effect: 'ANIMATION_EXPAND_VERTICAL',
				// 			method: 'ANIMATION_BOUNCE_EASE_OUT',
				// 			speed: 800
				// 		},
				// 		"hover-state": {
				// 			"border-width": "4px",
				// 			"border-color": "#000"
				// 		},
				// 		stacked: true,
				// 		tooltip: {
				// 			visible:false
				// 		}
				// 	},
				// 	// tooltip: {
				// 	// 	text: 'Kargo Sayısı : %v',
				// 	// 	fontColor: 'black',
				// 	// 	fontFamily: 'Georgia',
				// 	// 	backgroundColor: 'white',
				// 	// 	borderColor: 'black',
				// 	// 	borderWidth: 1,
				// 	// 	borderRadius: '5px',
				// 	// 	alpha: 0.7,
				// 	// 	paddingLeft: '5%',
				// 	// 	marginLeft: '-10%',
				// 	// },
				// 	"scale-y": {
				// 		"line-color": "#f6f7f8",
				// 		"shadow": 0,
				// 		"guide": {
				// 			"line-style": "dashed"
				// 		},
				// 		"label": {
				// 			"text": "Kargo Sayısı",
				// 		},
				// 		"minor-ticks": 0,
				// 		"thousands-separator": ","
				// 	},
				// 	"crosshair-x": {
				// 		"line-color": "#efefef",
				// 		"plot-label": {
				// 			"border-radius": "5px",
				// 			"border-width": "1px",
				// 			"border-color": "#f6f7f8",
				// 			"padding": "10px",
				// 			"font-weight": "bold"
				// 		},
				// 		"scale-label": {
				// 			"font-color": "#000",
				// 			"background-color": "#f6f7f8",
				// 			"border-radius": "5px"
				// 		}
				// 	},
				// 	"scaleX": {
				// 		"values": data.chart.labels,
				// 		"placement": "default",
				// 		"tick": {
				// 			"size": 58,
				// 			"placement": "cross"
				// 		},
				// 		"itemsOverlap": true,
				// 		"item": {
				// 			"offsetY": -55
				// 		}
				// 	},
				// 	"series": [{
				// 		"values": data.chart.values,

				// 		"text": "Kargo Sayısı",
				// 		"line-color": "#007790",
				// 		"legend-item": {
				// 			"background-color": "#007790",
				// 			"borderRadius": 5,
				// 			"font-color": "white"
				// 		},
				// 		"legend-marker": {
				// 			"visible": false
				// 		},
				// 		"marker": {
				// 			"background-color": "#007790",
				// 			"border-width": 1,
				// 			"shadow": 0,
				// 			"border-color": "#69dbf1"
				// 		},
				// 		"highlight-marker": {
				// 			"size": 6,
				// 			"background-color": "#007790",
				// 		}
				// 	}]
				// }
				// $scope.successRate = Number((100 - ((100 * data.count.fail) / data.count.total)).toFixed(2));
				// if ($scope.successRate < 1) {
				// 	$scope.successRate = 100;
				// }
			});

			// $scope.getData('order_data');
		};

		$scope.avrOrder = function () {
			Analytic.orderDashboard($scope.filterData).success(function (data) {
				$scope.data = data
				console.log($scope.data);
			})
		}

		$scope.openDetail = function (item) {
			Analytic.showOrders({ ids: item }).success(function (data, status, headers, config) {
				$scope.data = data;
				$('#orderModal').modal('show');
			});
		}

		$scope.total = function () {
			Analytic.orderDashboard($scope.filterData).success(function (data, status, headers, config) {

				var order = data.wait.data[0]

				console.log(order);

				var date = data.wait.period

				const ctx = document.getElementById('myChart').getContext('2d');
				const myChart = new Chart(ctx, {
					type: 'line',
					data: {
						labels: date,
						datasets: [{
							label: 'Toplam Kargo',
							data: order,
							backgroundColor: 'rgba(255, 159, 64, 0.2)',
							borderColor: 'rgba(255, 159, 64, 1)',
							borderWidth: 1
						}]
					},
					options: {
						scales: {
							y: {
								beginAtZero: true
							}
						}
					}

				});
			})
		}

		$rootScope.customerData = [];

		$scope.customer = function () {
			Analytic.orderDashboard($scope.filterData).success(function (data, status, headers, config) {

				var order = data.wait.data

				console.log(order);

				var date = data.wait.period

				order.forEach(element => {

					$rootScope.customerData.push(
						{
							label: element[0].slice(0, 5),
							data: element,
							backgroundColor: 'rgba(255, 159, 64, 0.2)',
							borderColor: 'rgba(255, 159, 64, 1)',
							borderWidth: 1
						}
					)

				});

				const ctx = document.getElementById('myChart').getContext('2d');
				const myChart = new Chart(ctx, {
					type: 'line',
					data: {
						labels: date,
						datasets: $rootScope.customerData
					},
					options: {
						scales: {
							y: {
								beginAtZero: true
							}
						}
					}

				});

			})

		}

		$scope.getData = function (type) {
			var filter = {
				customers: $scope.filterData.customers,
				export: $scope.filterData.export,
				page: $scope.filterData.page,
				scheduled_time: $scope.filterData.scheduled_time,
				workSteps: $scope.filterData.workSteps,
				workers: $scope.filterData.workers,
			};
			filter.type = type;
			if (type === 'order_data') {
				filter.date_type = 'created_at';
				filter.real_order = true;
			}
			Analytic.orderDashboardSingle(filter).success(function (data, status, headers, config) {
				$scope.countData[type] = data.order_data;
			});
		};

		$scope.getPage = function (page) {
			$scope.filterData.page = page;
			$scope.filter();
		}

		$scope.export = function (page) {
			$scope.filterData.export = true;
			$scope.init('export');
		}

		$scope.sort = function (field, sort) {
			$scope.filterData.field = field;
			$scope.filterData.sort = sort;
			$scope.filter();
		}

		$scope.filter = function (type) {
			if (type == 'clear') {
				$scope.filterData = {
					export: false,
					customers: [],
					workers: [],
					workSteps: [],
					page: 1,
					created_at: {
						startDate: null,
						endDate: null
					},
					scheduled_time: {
						startDate: null,
						endDate: null
					}
				}
			}
			$scope.init();
		}


	}
]);
