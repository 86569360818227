bovoApp.controller('MngController', ['$scope', 'Mng', '$location','$filter','$routeParams','$rootScope','$ngConfirm',
	function ($scope, Mng, $location, $filter, $routeParams, $rootScope, $ngConfirm) {

		$scope.filterProp = [];
		$scope.selectedItem = [];
 
		$scope.filterData = {
			date : { 
				start: moment().subtract(29, 'days'), 
				end: moment() 
			},
			type : 'HAYIR',
			status : 'OLUMSUZ'
		}

		$scope.opts = {
            locale: {
                applyClass: 'btn-green',
                applyLabel: "Seç",
                fromLabel: "From",
                format: "DD-MM-YYYY",
                toLabel: "To",
                cancelLabel: 'İptal',
                customRangeLabel: 'Özel Aralık'
            },
            ranges: {
                'Bugün': [moment(), moment()],
                'Dün': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'Son 7 Gün': [moment().subtract(6, 'days'), moment()],
                'Son 30 Gün': [moment().subtract(29, 'days'), moment()],
                'Bu Ay': [moment().startOf('month'), moment().endOf('month')],
                'Geçen Ay': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
            }
        }

		$scope.filter = function (type) {
			if(type == 'clear') {
				$scope.filterData = {
					customers: [],
					workers: [],
					workSteps: [],
					page: 1,
					created_at : { 
						startDate: null, 
						endDate: null 
					},
					scheduled_time : { 
						startDate: null, 
						endDate: null 
					}
				}
				$scope.filterProp = [];
			}else {
				$scope.filterData.filterProp = $scope.filterProp;
				if(typeof $scope.filterData.created_at.endDate !== 'undefined' && $scope.filterData.created_at.endDate !== null) {
					$scope.filterData.filterProp.created_at = 'created_at';
				}
				if(typeof $scope.filterData.scheduled_time.endDate !== 'undefined' && $scope.filterData.scheduled_time.endDate !== null) {
					$scope.filterData.filterProp.scheduled_time = 'scheduled_time';
				}
			}
			$scope.orders();
		}

		$scope.$on('refresh_data', function() {
			console.log('refreshing list...')
			$scope.orders();
		}, true);

		$scope.searchOrder = function(term) {
			$scope.filterData.term = term;
			$scope.orders(); 
		}

		$scope.clearFilter = function(page) {
			$scope.filterData = {
				term: '',
				workers: [],
				workSteps: [],
				page: 1,
				created_at : { 
					startDate: null, 
					endDate: null 
				},
				scheduled_time : { 
					startDate: null, 
					endDate: null
				}
			}
			$scope.filterProp = [];
			$scope.orders();
		}

		$scope.orders = function () {
			Mng.fails($scope.filterData).success(function(data, status, headers, config) {
				$scope.data = data;
				angular.merge($scope.filterData, $routeParams);
				if(typeof $scope.filterData.filterProp !== 'undefined') {
					$scope.filterProp = [];
					if(angular.isArray($scope.filterData.filterProp)) {
						for (var i = $scope.filterData.filterProp.length - 1; i >= 0; i--) {
							$scope.filterProp.push($scope.filterData.filterProp[i])
						}
					}else {
						$scope.filterProp.push($scope.filterData.filterProp);
					}
				}
			});
		};

		$scope.responseFailOrder = function(PROBLEM_TICKET_ID) {
			var data = { 
				id : PROBLEM_TICKET_ID
			};
			$ngConfirm({
				title: 'Gönderi Teslimat Problem Yanıtı ',
				contentUrl: 'fail_reason.html',
				buttons: {
					iptal: function () {},
					send: {
						text: 'Gönder',
						btnClass: 'btn-success',
						action: function (scope) {
							if(scope.reason && scope.result) {
								data.result = scope.result;
								data.reason = scope.reason;
								Mng.responseFailOrder(data).success(function(data, status, headers, config) {
									Messenger().post({message: 'Başarıyla Yanıtlandı',type: "success"});
									$scope.orders();
								});
                            }else {
								alert('Lütfen sizden istenen bilgileri giriniz!');
								return false;
								// $scope.responseFailOrder(data.id)
                            }
						}
					}
				}
			})
		}
		
	}
]);