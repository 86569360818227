bovoApp.controller('CustomerOrderAnalyticController', ['$scope', 'Analytic', 'Account', '$location', 'FileUploader', '$http',
	function ($scope, Analytic, Account, $location, FileUploader, $http) {

		$scope.filterProp = [];

		$scope.filterData = {
			export: false,
			customers: [],
			workers: [],
			workSteps: [],
			page: 1,
			created_at: {
				startDate: null,
				endDate: null
			},
			scheduled_time: {
				// startDate: moment().subtract(30, 'days'),
				// endDate: moment()
				startDate: null,
				endDate: null
			}
		}

		$scope.$on('refresh_data', function () {
			console.log('refreshing order reports...')
			$scope.init();
		}, true);

		$scope.clearFilter = function (page) {
			$scope.filterData = {
				export: false,
				customers: [],
				workers: [],
				workSteps: [],
				page: 1,
				created_at: {
					startDate: null,
					endDate: null
				},
				scheduled_time: {
					startDate: null,
					endDate: null
				}
			}
		}

		$scope.init = function (type) {
			Analytic.customerOrders($scope.filterData).success(function (data, status, headers, config) {
				if (typeof type !== undefined && type == 'export') {
					$scope.filterData.export = false;
					window.location.href = data.url;
				} else {
					$scope.dashboard = data;
					$scope.orderDeliveryRate = {
						type: "pie",
						backgroundColor: 'none',
						plotarea: {
							backgroundColor: 'transparent'
						},
						plot: {
							borderColor: "#2B313B",
							borderWidth: 5,
							valueBox: {
								placement: 'out',
								text: '%t\n%npv%',
								fontFamily: "Open Sans"
							},
							tooltip: {
								fontSize: '18',
								fontFamily: "Open Sans",
								padding: "5 10",
								text: "%npv%"
							},
							animation: {
								effect: 2,
								method: 5,
								speed: 900,
								sequence: 1,
								delay: 3000
							},
						},
						title: {
							fontColor: "#8e99a9",
							text: 'Kargo Teslim/Gün Oranı',
							align: "center",
							offsetX: 10,
							fontSize: 25
						},
						plotarea: {
							margin: "20 0 0 0"
						},
						series: [
							{
								text: '1 Günde Teslim',
								values: [ $scope.dashboard.count.one_day.length ]
							},
							{
								text: '2 Günde Teslim',
								values: [ $scope.dashboard.count.two_day.length ]
							},
							{
								text: '3 Günde Teslim',
								values: [ $scope.dashboard.count.three_day.length ]
							},
							{
								text: '3 Gün Üzerinde Teslim',
								values: [ $scope.dashboard.count.tree_and_above.length ]
							},
						],
					};

					// $scope.series = ['Tamamlanan','Devam Eden','İptal Edilen','Toplam'];
					// $scope.series = ['Kargo Sayısı'];
					
					// $scope.chartData = [data.count.success, data.count.other, data.count.fail, data.count.total];
					// $scope.successRate = Number(100 - ((100 * data.count.fail) / data.count.total)).toFixed(2);
					// if ($scope.successRate < 1) {
					// 	$scope.successRate = 100;
					// }
					// $scope.barChartData = [
					// 	// data.chart.count.success,
					// 	// data.chart.count.other,
					// 	// data.chart.count.fail,
					// 	data.chart.count.total
					// ];
				}
			});
		};

		$scope.openDetail = function (item) {
			Analytic.showCustomerOrders({ ids: item }).success(function (data, status, headers, config) {
				$scope.data = data;
				$('#orderModal').modal('show');
			});
		}

		$scope.getPage = function (page) {
			$scope.filterData.page = page;
			$scope.filter();
		}

		$scope.export = function (page) {
			$scope.filterData.export = true;
			$scope.init('export');
		}

		$scope.sort = function (field, sort) {
			$scope.filterData.field = field;
			$scope.filterData.sort = sort;
			$scope.filter();
		}

		$scope.filter = function (type) {
			if (type == 'clear') {
				$scope.filterData = {
					export: false,
					customers: [],
					workers: [],
					workSteps: [],
					page: 1,
					created_at: {
						startDate: null,
						endDate: null
					},
					scheduled_time: {
						startDate: null,
						endDate: null
					}
				}
			}
			$scope.init();
		}

	}
]);
