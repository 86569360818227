bovoApp.controller('PriceListController', ['$scope', 'PriceList', '$location', '$routeParams', '$filter', '$translate',
  function ($scope, PriceList, $location, $routeParams, $filter, $translate) {
    $scope.deleted = [];

    $scope.all = function () {
      PriceList.all().success(function (data, status, headers, config) {
        $scope.data = data;
      });
    }

    $scope.openDetail = function (item) {
      PriceList.get(item.id).success(function (data, status, headers, config) {
        $('#priceListModal').modal('show');
        $scope.priceList = data.priceList;
        $scope.workTypes = data.workTypes;
        // var datas = $scope.priceList.company_customers;
        // $scope.priceList.company_customers = [];
        // datas.forEach(element => {
        //   $scope.priceList.company_customers.push({
        //     company_customer_id: element.id
        //   });
        // });
      });
    }

    $scope.$on('new_data', function () {
      $('#priceListModal').modal('show');
      $scope.new();
    }, true);

    $scope.new = function () {
      PriceList.new().success(function (data, status, headers, config) {
        $scope.workTypes = data.workTypes;
        $scope.priceList = {
          default: 0,
          is_km: 0,
          status: 'active',
          price_calculation_type: 'per_price',
          prices: []
        };
      });
    }

    $scope.delete = function (type) {
      if (type == 'set') {
        $('#delete').modal('show');
      } else if (type == 'delete') {
        PriceList.delete($scope.deleted).success(function (data, status, headers, config) {
          Messenger().post($scope.translate(data.message.toString()));
          for (var i = $scope.deleted.length - 1; i >= 0; i--) {
            var index = $scope.data.priceList.indexOf($filter('filter')($scope.data.priceList, {
              id: $scope.deleted[i]
            })[0]);
            if (index > -1) {
              $scope.data.priceList.splice(index, 1);
            }
          }
          $scope.deleted = [];
          $('#delete').modal('hide');
        }).error(function (data) {
          Messenger().post({
            message: data.message,
            type: "error"
          });
        });
      }
    }

    $scope.postForm = function (user) {
      if ($scope.priceList.id) {
        $scope.update();
      } else {
        $scope.save();
      }
    }

    $scope.save = function () {
      PriceList.save($scope.priceList).success(function (data, status, headers, config) {
        $scope.data = data;
        Messenger().post('Başarılı');
        $('#priceListModal').modal('hide');
        $scope.all();
      }).error(function (data) {
        Messenger().post({
          message: data.message,
          type: "error"
        });
      });
    }

    $scope.update = function () {
      PriceList.update($scope.priceList).success(function (data, status, headers, config) {
        $scope.data = data;
        Messenger().post('Başarılı');
        $('#priceListModal').modal('hide');
        $scope.all();
      }).error(function (data) {
        Messenger().post({
          message: data.message,
          type: "error"
        });
      });
    }

    $scope.toggleSelection = function toggleSelection(id) {
      var idx = $scope.deleted.indexOf(id);
      if (idx > -1) {
        $scope.deleted.splice(idx, 1);
      } else {
        $scope.deleted.push(id);
      }
    };

    $scope.addPrice = function (workType, type) {
      $scope.priceList.prices.push({
        min_weight: null,
        type: 'weight',
        work_type: workType,
        price_type: type
      });
    }

    $scope.removePrice = function (item) {
      var index = $scope.priceList.prices.findIndex(function (elm) {
        return elm.$$hashKey == item.$$hashKey
      })
      $scope.priceList.prices.splice(index, 1);
    }

  }
]);