bovoApp.
  factory('FailOrder', ['$http', function($http) {
    return {
      all: function(data) {
        return $http.post('api/fail-orders', data);
      },
      get: function(id) {
        return $http.get('api/fail-order/' + id);
      },
      new: function(id) {
        return $http.get('api/new-fail-order');
      },
      addOrder: function(data) {
        return $http.post('api/add-fail-order',data);
      },
      delete: function(id) {
        return $http.delete('api/delete-fail-order/' + id);
      },
      save: function(data) {
        return $http.post('api/save-fail-order',data);
      },
      update: function(data) {
        return $http.post('api/update-fail-order',data);
      },
      approve: function(data) {
        return $http.post('api/approve-fail-order',data);
      },
      export: function(data) {
        return $http.post('api/export-fail-order',data);
      },
    };
}]);