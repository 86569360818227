bovoApp.controller('IntegrationErrorController', ['$scope', 'Order', '$location', '$routeParams', '$filter', '$translate',
  function ($scope, Order, $location, $routeParams, $filter, $translate) {
    $scope.deleted = [];


    $scope.selectAll = function (select) {
      if (select) {
        $scope.deleted = [];
        for (let i = 0; i < $scope.data.pallettes.data.length; i++) {
          $scope.deleted.push($scope.data.pallettes.data[i].id);
        }
      } else {
        $scope.deleted = [];
      }
    };

    $scope.filterData = {
      type: [],
      page: 1,
      date: {
        startDate: null,
        endDate: null
      }
    }

    $scope.clearFilter = function (page) {
      $scope.filterData = {
        type: [],
        page: 1,
        date: {
          startDate: null,
          endDate: null
        }
      }
      $scope.all();
    }

    $scope.$on('refresh_data', function () {
      console.log('refreshing map...')
      $scope.all();
    }, true);


    $scope.getPage = function (page) {
      $scope.filterData.page = page;
      $scope.all();
    }

    $scope.all = function (type) {
      Order.integrationErrors($scope.filterData).success(function (data, status, headers, config) {
        $scope.data = data;
      });
    }

    $scope.toggleSelection = function toggleSelection(id) {
      var idx = $scope.deleted.indexOf(id);
      if (idx > -1) {
        $scope.deleted.splice(idx, 1);
      } else {
        $scope.deleted.push(id);
      }
    };

  }
]);