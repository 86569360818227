bovoApp.controller('TaskController', ['$scope', 'Task', '$location','$filter',
	'ngAudio','$routeParams','$rootScope','$window','$http','dragulaService','FileUploader',
	function ($scope, Task, $location, $filter, ngAudio, $routeParams, $rootScope, $window, $http, dragulaService, FileUploader) {

		dragulaService.options($scope, 'order-bag', {
			copy: false,
			revertOnSpill: false,
			moves: function (el, container, handle) {
				return handle.className !== 'list-card p10 text-center new-task' && 
						handle.className !== 'fa fa-plus fs24';
			}
		});
		dragulaService.options($scope, 'steps-bag', {
			moves: function (el, container, handle) {
				return handle.className === 'handle fa fa-arrows fs14';
			}
		});

		$scope.imageFormats = ['jpg','png','jpeg','bmp','gif'];
		$scope.wordFormats = ['doc','docx'];
		$scope.excelFormats = ['xls','xlsx'];
		$scope.pdfFormats = ['pdf'];
		$scope.project_name = '';

		$scope.$on('order-bag.drop', function (e, el, target, source) {
			if(typeof el.parent().parent().attr('data') !== 'undefined') {
				var data = el.parent().parent().attr('data');
			}else if (typeof el.parent().parent().parent().attr('data') !== 'undefined') {
				var data = el.parent().parent().parent().attr('data');
			}
			var newData = {
				id : el.attr('id'),
				status : JSON.parse(data)
			};
			$scope.changingTask = newData.id;
			Task.changeTaskStatus(newData).success(function(data) {
			}).error(function() {
				Messenger().post({message: "Bir hata ile oluştu. Lütfen daha sonra yeniden deneyiniz.",type: "error"});
			});
		});

		$scope.$on('steps-bag.drop', function (e, el, target, source) {
			Task.changeTaskStepOrders($scope.data.steps).success(function(data) {
			}).error(function() {
				Messenger().post({message: "Bir hata ile oluştu. Lütfen daha sonra yeniden deneyiniz.",type: "error"});
			});
		});
	
		$scope.filterPanel = false;
		$scope.menuPanel = false;

		$scope.filterData = {
			workers: [],
			workSteps: [],
			created_at : { 
				startDate: null, 
				endDate: null 
			},
			scheduled_time : { 
				startDate: null, 
				endDate: null 
			}
		}

		$scope.projects = function (type) {
			Task.projects().success(function(data, status, headers, config) {
				$scope.data = data;
			});
		}
		
		$scope.saveProject = function (type) {
			Task.saveProject($scope.project).success(function(data, status, headers, config) {
				var index = $scope.data.projects.indexOf($filter('filter')($scope.data.projects, {id: data.id})[0]);
				if (index > -1) {
					$scope.data.projects[index] = data;
				}else {
					$scope.data.projects.push(data);
				}
				$('#newProject').modal('hide');
			});
		}

	    $scope.editProject = function (item) {
			$scope.project = item;
			$('#newProject').modal('show');
		}

	    $scope.newProject = function (item) {
			$scope.project = {
				color: $scope.colorList[0]
			};
		}

		$scope.$on('refresh_data', function() {
			console.log('refreshing tasks...')
			if($location.path() !== '/projeler')
			$scope.init();
		}, true);

		$scope.$watch('filterData', function() {
			if($location.path() !== '/projeler')
			$scope.init();
		}, true);
		
		$scope.findProject = function(value) {
			$scope.project_name = value;
		}
		
		$scope.init = function (type) {
			if(typeof type !== 'undefined' && type == 'clear') {
				$scope.filterData = {
					workers: [],
					workSteps: [],
					created_at : { 
						startDate: null, 
						endDate: null 
					},
					scheduled_time : { 
						startDate: null, 
						endDate: null 
					}
				}
			}
			$scope.filterData.project_id = $routeParams.id;
			Task.dashboard($scope.filterData).success(function(data, status, headers, config) {
				$scope.data = data;
				$scope.data.importance = [
					{
						id : 'low',
						name : 'Düşük'
					},
					{
						id : 'middle',
						name : 'Orta'
					},
					{
						id : 'high',
						name : 'Yüksek'
					}
				];
			});
		}

		$scope.newOrUpdateTask = function(newWork) {
			if(newWork) {
				$('#newTask').modal('hide');
				$scope.newTaskData.project_id = $routeParams.id;
				$scope.newTaskData.allfiles = $scope.newTaskData.files;
				Task.newTask($scope.newTaskData).success(function(data, status, headers, config) {
					$scope.$broadcast('new_task', data);
				}).error(function(data) {
					$scope.info = {
						title : "Dikkat",
						message : data.message,
						class : 'danger'
					};
					$('#orderInfo').modal('show');
				});
			}
		};

		$scope.getExtensions = function(filename) {
			var ext = filename.split('.').pop();
			if(ext == filename) return "";
			return ext;
		}

		$scope.$on('new_order', function (event,data,nextParams,fromState) {
			var order = find($scope.data.orders, data.order.id);
			if(typeof order !== 'undefined')
				angular.merge(order, data.order);
			else
				$scope.data.orders.unshift(data.order);
		});

		$scope.$on('updateOrder', function (event,data,nextParams,fromState) {
			var order = find($scope.data.orders, data.id);
			angular.merge(order, data);
		});

		$scope.$on('deleteOrder', function (event,data,nextParams,fromState) {
			var index = $scope.data.orders.indexOf($filter('filter')($scope.data.orders, {id: data.id})[0]);
			if (index > -1) 
				$scope.data.orders.splice(index, 1);
		});

		$scope.$on('worker_answer', function (event,data,nextParams,fromState) {
			var order = find($scope.data.orders,data.order.id);
			angular.merge(order, data.order);
			Messenger().post(data.order.id + ' Nolu Kargonun durumu "'+ order.status.name +'" olarak değiştirildi.', 'İş Durumu!');
		});

		$scope.$on('deleteTask', function (event,data,nextParams,fromState) {
			var index = $scope.data.orders.indexOf($filter('filter')($scope.data.orders, {id: data.order.id})[0]);
			if (index > -1) 
				$scope.data.orders.splice(index, 1);
		});

		$scope.$on('update_order', function (event,data,nextParams,fromState) {
			if($scope.changingTask != data.order.id) {
				var index = $scope.data.orders.indexOf($filter('filter')($scope.data.orders, {id: data.order.id})[0]);
				if(index > -1) {
					$scope.data.orders[index] = data.order;
				}
			}else {
				$scope.changingTask = 0;
			}
		});

		function find(data,find) {
			var index = data.indexOf($filter('filter')(data, {id: find})[0]);
			if (index > -1)
				return data[index];
		}

		$scope.toggleMenuPanel = function() {
			$scope.menuPanel = !$scope.menuPanel;
		}

		$scope.getActivities = function() {
			Task.getActivities($routeParams.id).success(function(data, status, headers, config) {
				$scope.data.activities = data.activities;
				$scope.menuPanel = true;
			});
		}

		$scope.addNotification = function () {
			if(typeof $scope.newTaskData.notification === 'undefined') {
				$scope.newTaskData.notification = [];
			}
			$scope.newTaskData.notification.push({
                type : 'email',
                time: 10,
                period: 'minute'
            });
		}

        $scope.removeNotification = function(index) {
            $scope.newTaskData.notification.splice(index, 1);
        }

		$scope.deleteFileFromNewTask = function(file) {
			var index = $scope.newTaskData.files.indexOf(file);
			$scope.newTaskData.files.splice(index, 1);
		}

		$scope.getTaskDetails = function (work) {
            $rootScope.loadingWork = true;
			var id = work.id;
			if(typeof id == 'undefined') {
				var id = work;
			}
			Task.task(id).success(function(data, status, headers, config) {
                $rootScope.loadingWork = false;
				$rootScope.selectedWork = data.order;
				$('#taskDetail').modal('show');
			});
		}

		$scope.addAttr = function (attr) {
			$scope.showAttr.push(attr);
		}

		$scope.sendComment = function (description) {
			if(typeof description === 'undefined' || description.length == 0) {
				alert('Please fill note field!');
				return false;
			}
            $rootScope.loadingWork = true;
			var data = {
				description : description,
				order_id : $rootScope.selectedWork.id
			}
			Task.sendComment(data).success(function(data, status, headers, config) {
				$rootScope.loadingWork = false;
				$('textarea.chat_input').val('');
				$rootScope.selectedWork.comments.unshift(data);
			});
		} 

		$scope.updateTask = function() {
			$scope.newOfficeTaskModal(function(data) {
				$scope.newTaskData = $rootScope.selectedWork;
			});
		}

        $scope.newOfficeTaskModal = function (_callback,step) {
			$scope.newTaskData = {
				importance: 'low',
				address : {},
				type : 1,
				files : [],
				worker_id : $scope.authenticatedUser.worker,
				scheduled_time : moment().add(1, 'hours').startOf('hour').format('DD/MM/YYYY hh:mm'),
				notification : []
			};
			$scope.showAttr = [];
			jQuery.datetimepicker.setLocale('en');
			jQuery('#datetimepickerTask').datetimepicker({
				mask:true,
				format:'d/m/Y H:i',
				startDate: $scope.newTaskData.scheduled_time,
				minDate: new Date(),
				inline: true,
				timepickerScrollbar: false,
				scrollMonth	:false,
				onChangeDateTime:function(dp,$input){
					$scope.newTaskData.scheduled_time = moment($input.val(),'DD/MM/YYYY hh:mm').startOf('hour').format('DD/MM/YYYY hh:mm');
				}
			});
			if(typeof step !== 'undefined')
				$scope.newTaskData.status_id = step.id;

			Task.newTaskData($routeParams.id).success(function(data) {
				$scope.orderData = data;
				if(typeof _callback !== 'undefined' ) _callback(data);
			});
		}

		$scope.updateOrder = function (e) {
			Task.updateTask($scope.editOrderData).success(function(data, status, headers, config) {
				var index = $scope.data.orders.indexOf($filter('filter')($scope.data.orders, {id: $scope.editOrderData.id})[0]);
				if (index > -1) {
					$scope.data.orders[index] = data;
					$('#orderEdit').modal('hide');
				}
			}).error(function() {
				alert('Şuan bir hata oluştu lütfen daha sonra yeniden deneyiniz.');
			});
		};

		$scope.newTaskStep = function () {
			$scope.task_step = {
				color: $scope.colorList[0]
			};
			$('#newTaskStep').modal('show');
		}
	
		$scope.saveTaskStep = function () {
			$('#newTaskStep').modal('hide');
			$scope.task_step.project_id = $routeParams.id;
			Task.saveTaskStep($scope.task_step).success(function(data, status, headers, config) {
				
				var index = $scope.data.steps.indexOf($filter('filter')($scope.data.steps, {id: data.id})[0]);
				if (index > -1) {
					$scope.data.steps[index] = data;
				}else {
					$scope.data.steps.push(data);
				}
				$scope.task_step = {
					color: '#C52C66'
				};
			}).error(function(data) {
				$('#newTaskStep').modal('show');
				Messenger().post({message: data.message,type: "error"});
			});
		}

		$scope.changeNotification = function(step) {
			Task.changeNotification(step).success(function(data, status, headers, config) {
				var index = $scope.data.steps.indexOf($filter('filter')($scope.data.steps, {id: data.id})[0]);
				if (index > -1)
					$scope.data.steps[index].notification = data.notification;
			}).error(function(data) {
				$('#newTaskStep').modal('show');
				Messenger().post({message: data.message,type: "error"});
			});
		}

	    $scope.editTaskStep = function (item) {
			$('#newTaskStep').modal('show');
			$scope.task_step = item;	
			setTimeout(function(){
				$scope.$apply();
			},100);	
		}

		$scope.deleteTaskStep = function (id,type) {
			if(type == 'set') {
				$('#deleteTask').modal('show');
				$scope.deleteStepId = id;
			}else if(type == 'delete') {
				Task.deleteTaskStep($scope.deleteStepId).success(function(data, status, headers, config) {
				Messenger().post(data.message);
				$scope.data.steps = data.steps;
				$('#deleteTask').modal('hide');
			}).error(function(data) {
					Messenger().post({message: data.message,type: "error"});
				});
			} 
		}

		$scope.deleteProject = function (id,type) {
			if(type == 'set') {
				$('#deleteProject').modal('show');
				$scope.deleteProjectId = id;
			}else if(type == 'delete') {
				Task.deleteProject($scope.deleteProjectId).success(function(data, status, headers, config) {
				Messenger().post(data.message);
				$scope.data.projects = data.projects;
				$('#deleteProject').modal('hide');
			}).error(function(data) {
					Messenger().post({message: data.message,type: "error"});
				});
			} 
		}
		var taskFiles = $scope.taskFiles = new FileUploader({
			url: '/api/upload-files-task',
			headers: {'X-CSRF-TOKEN': CSRF_TOKEN},
			autoUpload: true
		});

		taskFiles.filters.push({
			name: 'formatFilter',
			fn: function(item /*{File|FileLikeObject}*/, options) {
				var type = '|' + item.type.slice(item.type.lastIndexOf('/') + 1) + '|';
				if('|jpg|png|jpeg|bmp|gif|pdf|doc|docx|xls|xlsx|'.indexOf(type) === -1) {
					alert('Sadece şu formatlar desteklenmektedir. (jpg, png, jpeg, bmp, gif, .pdf, .doc, .docx, .xls, .xlsx)');
					return false;
				};
				return true;
			}
		});

		taskFiles.onAfterAddingFile = function(fileItem) {
			$scope.progress = true;
			$scope.pending = true;
		};

		taskFiles.onCompleteItem = function(item, response, status, headers){
			$scope.progress = false;
			$scope.newTaskData.files.push(response);
			if(response.status == 'success') $scope.pending = false;
		}

		$scope.deleteImgFromNewOrder = function(image) {
			var index = $scope.newTaskData.files.indexOf(image);
			$scope.newTaskData.files.splice(index, 1);
		}

	}
]);