bovoApp.controller('VehicleRouteContoller', ['$scope', 'VehicleRoute', '$location', '$routeParams', '$filter', '$translate',
  function ($scope, VehicleRoute, $location, $routeParams, $filter, $translate) {
    $scope.deleted = [];

    $scope.all = function () {
      VehicleRoute.all().success(function (data, status, headers, config) {
        $scope.data = data;
      });
    }

    $scope.openDetail = function (item) {
      VehicleRoute.get(item.id).success(function (data, status, headers, config) {
        $('#vehicleRouteModal').modal('show');
        $scope.vehicleRoute = data.vehicleRoute;
        $scope.data.transfers = data.transfers;
        $scope.data.stations = data.stations;
      });
    }

    $scope.$on('new_data', function () {
      $('#vehicleRouteModal').modal('show');
      $scope.new();
    }, true);

    $scope.new = function () {
      VehicleRoute.new().success(function (data, status, headers, config) {
        $scope.data.transfers = data.transfers;
        $scope.data.stations = data.stations;
        $scope.vehicleRoute = {
          start_type: 'transfer',
          end_type: 'station'
        }
      });
    }

    $scope.delete = function (type) {
      if (type == 'set') {
        $('#delete').modal('show');
      } else if (type == 'delete') {
        VehicleRoute.delete($scope.deleted).success(function (data, status, headers, config) {
          Messenger().post($scope.translate(data.message.toString()));
          for (var i = $scope.deleted.length - 1; i >= 0; i--) {
            var index = $scope.data.vehicleRoutes.indexOf($filter('filter')($scope.data.vehicleRoutes, {
              id: $scope.deleted[i]
            })[0]);
            if (index > -1) {
              $scope.data.vehicleRoutes.splice(index, 1);
            }
          }
          $scope.deleted = [];
          $('#delete').modal('hide');
        }).error(function (data) {
          Messenger().post({
            message: data.message,
            type: "error"
          });
        });
      }
    }

    $scope.postForm = function (user) {
      if ($scope.vehicleRoute.id) {
        $scope.update();
      } else {
        $scope.save();
      }
    }

    $scope.save = function () {
      VehicleRoute.save($scope.vehicleRoute).success(function (data, status, headers, config) {
        $scope.data = data;
        Messenger().post('Başarılı');
        $('#vehicleRouteModal').modal('hide');
        $scope.all();
      }).error(function (data) {
        Messenger().post({
          message: data.message,
          type: "error"
        });
      });
    }

    $scope.update = function () {
      VehicleRoute.update($scope.vehicleRoute).success(function (data, status, headers, config) {
        $scope.data = data;
        Messenger().post('Başarılı');
        $('#vehicleRouteModal').modal('hide');
        $scope.all();
      }).error(function (data) {
        Messenger().post({
          message: data.message,
          type: "error"
        });
      });
    }

    $scope.toggleSelection = function toggleSelection(id) {
      var idx = $scope.deleted.indexOf(id);
      if (idx > -1) {
        $scope.deleted.splice(idx, 1);
      } else {
        $scope.deleted.push(id);
      }
    };

  }
]);