bovoApp.controller('InvoiceAnalyticController', ['$scope', 'Analytic', 'Account', '$location', 'FileUploader', '$http',
	function ($scope, Analytic, Account, $location, FileUploader, $http) {

		$scope.filterProp = [];

		$scope.filterData = {
			export: false,
			customers: [],
			workers: [],
			workSteps: [],
			page: 1,
			created_at: {
				startDate: null,
				endDate: null
			},
			scheduled_time: {
				startDate: null,
				endDate: null
			}
		}
		$scope.$on('refresh_data', function () {
			console.log('refreshing order reports...')
			$scope.init();
		}, true);

		$scope.$watch('filterData', function () {
			$scope.init();
		}, true);


		$scope.clearFilter = function (page) {
			$scope.filterData = {
				export: false,
				customers: [],
				workers: [],
				workSteps: [],
				page: 1,
				created_at: {
					startDate: null,
					endDate: null
				},
				scheduled_time: {
					startDate: null,
					endDate: null
				}
			}
		}

		$scope.chartColors = [
			'#dbe4e5', 
			'#63BC46', 
			'#63BC46', 
		];
		$scope.init = function (type) {
			Analytic.invoices($scope.filterData).success(function (data, status, headers, config) {
				if (typeof type !== undefined && type == 'export') {
					$scope.filterData.export = false;
					window.location.href = data.url;
				} else {
					$scope.data = data;
					$scope.series = ['Toplam', 'Odenen', 'Bekleyen'];
					$scope.barChartData = [
						data.chart.count.total,
						data.chart.count.paid,
						data.chart.count.open
					];
					$scope.chartData = [
						data.count.total,
						data.count.paid,
						data.count.open,
					];
				}
			});
		};

		$scope.getPage = function (page) {
			$scope.filterData.page = page;
			$scope.filter();
		}

		$scope.export = function (page) {
			$scope.filterData.export = true;
			$scope.init('export');
		}

		$scope.sort = function (field, sort) {
			$scope.filterData.field = field;
			$scope.filterData.sort = sort;
			$scope.filter();
		}

		$scope.filter = function (type) {
			if (type == 'clear') {
				$scope.filterData = {
					export: false,
					customers: [],
					workers: [],
					workSteps: [],
					page: 1,
					created_at: {
						startDate: null,
						endDate: null
					},
					scheduled_time: {
						startDate: null,
						endDate: null
					}
				}
			}
			$scope.init();
		}

	}
]);
