bovoApp.controller('OrderInvoiceController', ['$scope', 'Order', '$location', '$filter', '$routeParams', '$rootScope',
	function ($scope, Order, $location, $filter, $routeParams, $rootScope) {

		$scope.filterProp = [];
		$scope.selectedItem = [];

		$scope.calendarView = 'month';

		$scope.addSelectedItem = function (item) {

			if ($scope.selectedItem.indexOf(item) > -1) {
				$scope.selectedItem.splice($scope.selectedItem.indexOf(item), 1);
			} else {
				$scope.selectedItem.push(item);
			}
		}

		$scope.filterData = {
			customers: [],
			workers: [],
			workSteps: [],
			page: 1,
			created_at: {
				startDate: moment().subtract(29, 'days'),
				endDate: moment()
			},
			scheduled_time: {
				startDate: null,
				endDate: null
			}
		}

		$scope.addFilterProps = function (item) {
			if ($scope.filterProp.indexOf(item) === -1)
				$scope.filterProp.push(item);
			else {
				var index = $scope.filterProp.indexOf(item);
				$scope.filterProp.splice(index, 1);
				if (item != 'term') {
					$scope.filterData[item] = [];
				} else {
					$scope.filterData[item] = "";
				}
				if (item == 'date') {
					delete $scope.filterData.startDate;
					delete $scope.filterData.endDate;
				}
				if ($scope.filterProp.length == 0) {
					$scope.filter('clear');
				}
			}
		}

		$scope.getPage = function (page) {
			$scope.filterData.page = page;
			$scope.filter();
		}

		$scope.sort = function (field, sort) {
			$scope.filterData.field = field;
			$scope.filterData.sort = sort;
			$scope.filter();
		}

		$scope.filter = function (type) {
			if (type == 'clear') {
				$scope.filterData = {
					customers: [],
					workers: [],
					workSteps: [],
					page: 1,
					created_at: {
						startDate: null,
						endDate: null
					},
					scheduled_time: {
						startDate: null,
						endDate: null
					}
				}
				$scope.filterProp = [];
			} else {
				$scope.filterData.filterProp = $scope.filterProp;
				if (typeof $scope.filterData.created_at.endDate !== 'undefined' && $scope.filterData.created_at.endDate !== null) {
					$scope.filterData.filterProp.created_at = 'created_at';
				}
				if (typeof $scope.filterData.scheduled_time.endDate !== 'undefined' && $scope.filterData.scheduled_time.endDate !== null) {
					$scope.filterData.filterProp.scheduled_time = 'scheduled_time';
				}
			}
			$scope.invoiceInit();
		}

		$scope.$on('refresh_data', function () {
			console.log('refreshing orderInvoices...')
			$scope.invoiceInit();
		}, true);

		$scope.clearFilter = function (page) {
			$scope.filterData = {
				workers: [],
				workSteps: [],
				page: 1,
				created_at: {
					startDate: null,
					endDate: null
				},
				scheduled_time: {
					startDate: null,
					endDate: null
				}
			}
			$scope.invoiceInit();
		}

		var paginationOptions = {
			pageNumber: 1,
			pageSize: 1000,
			sort: null
		};

		$scope.invoiceInit = function () {
			if ($routeParams.type) {
				$scope.filterData.type = $routeParams.type;
			}
			$scope.filterData.page = paginationOptions.pageNumber;
			$scope.filterData.pageSize = paginationOptions.pageSize;
			
			Order.orderInvoices($scope.filterData).success(function (data, status, headers, config) {
				$scope.data = data;
				$scope.formatters = {};
				$scope.selectedItems = [];

				$rootScope.gridOptionsComplex = {
					enableFiltering: true,
					showGridFooter: true,
					showColumnFooter: true,
					paginationPageSizes: [1000, 10000, 100000],
					paginationPageSize: 1000,
					enableGridMenu: true,
					enableSelectAll: true,
					useExternalPagination: true,
					exporterCsvFilename: 'Fatura.csv',
					exporterPdfDefaultStyle: { fontSize: 9 },
					exporterPdfTableStyle: { margin: [30, 30, 30, 30] },
					exporterPdfTableHeaderStyle: { fontSize: 10, bold: true, italics: true, color: 'red' },
					exporterPdfHeader: { text: "My Header", style: 'headerStyle' },
					exporterPdfFooter: function (currentPage, pageCount) {
						return { text: currentPage.toString() + ' of ' + pageCount.toString(), style: 'footerStyle' };
					},
					exporterPdfCustomFormatter: function (docDefinition) {
						docDefinition.styles.headerStyle = { fontSize: 22, bold: true };
						docDefinition.styles.footerStyle = { fontSize: 10, bold: true };
						return docDefinition;
					},
					exporterPdfOrientation: 'portrait',
					exporterPdfPageSize: 'LETTER',
					exporterPdfMaxGridWidth: 500,
					exporterCsvLinkElement: angular.element(document.querySelectorAll(".custom-csv-link-location")),
					exporterExcelFilename: 'Fatura.xlsx',
					exporterExcelSheetName: 'Sheet1',
					exporterExcelCustomFormatters: function (grid, workbook, docDefinition) {
						var stylesheet = workbook.getStyleSheet();
						var stdStyle = stylesheet.createFontStyle({
							size: 9, fontName: 'Calibri'
						});
						var boldStyle = stylesheet.createFontStyle({
							size: 9, fontName: 'Calibri', bold: true
						});
						var aFormatDefn = {
							"font": boldStyle.id,
							"alignment": { "wrapText": true }
						};
						var formatter = stylesheet.createFormat(aFormatDefn);
						// save the formatter
						$scope.formatters['bold'] = formatter;
						var dateFormatter = stylesheet.createSimpleFormatter('date');
						$scope.formatters['date'] = dateFormatter;

						aFormatDefn = {
							"font": stdStyle.id,
							"fill": { "type": "pattern", "patternType": "solid" },
							"alignment": { "wrapText": true }
						};
						formatter = stylesheet.createFormat(aFormatDefn);
						// save the formatter
						$scope.formatters['red'] = formatter;
						Object.assign(docDefinition.styles, $scope.formatters);

						return docDefinition;
					},
					exporterColumnScaleFactor: 4.5,
					exporterFieldApplyFilters: true,
					columnDefs: [
						{ name: 'ID', field: 'order_id' },
						{ name: 'Ent No', field: 'special_tracking_number' },
						{ name: 'Müşteri', field: 'customer_name' },
						{ name: 'Tür', field: 'work_type' },
						{ name: '3rd', field: 'third_party_company' },

						{ name: 'Kargo Trh.', field: 'cargo_date' },
						// { name: 'Varış İli', field: 'order.location.city' },
						// { name: 'Kargo Türü', field: 'order.work_type.name' },
						{ name: 'Durum', field: 'status_name' },
						{ name: 'St Desi', field: 'system_weight' },
						{ name: 'St Fiyatı', field: 'system_price' },

						{ name: 'BV Ftr. No', field: 'bovo_invoice_number' },
						{ name: 'BV Desi', field: 'bovo_weight' },
						{ name: 'BV Fiyat', field: 'bovo_price' },
						{ name: 'BV - St Desi', field: 'weight_diff_bovo_system' },
						{ name: 'BV - St Fiyat', field: 'price_diff_bovo_system' },

						{ name: '3rd Ftr. No', field: 'third_party_invoice_number' },
						{ name: '3rd Desi', field: 'third_party_weight' },
						{ name: '3rd Fiyat', field: 'third_party_price' },
						{ name: '3rd - BV Desi', field: 'weight_diff_3rd_bovo' },
						{ name: '3rd - Bovo Fiyat', field: 'price_diff_3rd_bovo' },
						{ name: 'Tazmin', field: 'compensation_price' },

						{ name: '3rd 2 Desi', field: 'third_party_2_weight' },
						{ name: '3rd 2 Fiyat', field: 'third_party_2_price' },
						{ name: '3rd 2 Ftr. No', field: 'third_party_2_invoice_number' },

					],
					data: data.invoices.data
				};

				var totalItems = 1200000;
				$rootScope.gridOptionsComplex.totalItems = totalItems;
				$rootScope.gridOptionsComplex.pageSize = totalItems / paginationOptions.pageSize;
				$rootScope.gridOptionsComplex.pageNumber = data.invoices.current_page;

				paginationOptions.pageSize = totalItems / paginationOptions.pageSize;
				paginationOptions.pageNumber = data.invoices.current_page;

				$rootScope.gridOptionsComplex.onRegisterApi = function (gridApi) {
					//set gridApi on scope
					$scope.gridApi = gridApi;

					gridApi.pagination.on.paginationChanged($scope, function (newPage, pageSize) {
						paginationOptions.pageNumber = newPage;
        				paginationOptions.pageSize = pageSize;
						$scope.invoiceInit();
					});

					gridApi.selection.on.rowSelectionChanged($scope, function (row) {
						if (row.isSelected) {
							$scope.selectedItems.push(row.entity.id)
						} else {
							var index = $scope.selectedItems.indexOf(row.entity.id);
							if (index > -1) {
								$scope.selectedItems.splice(index, 1);
							}
						}
					});

					gridApi.selection.on.rowSelectionChangedBatch($scope, function (rows) {
						rows.forEach(element => {
							if (element.isSelected) {
								$scope.selectedItems.push(element.entity.id)
							} else {
								var index = $scope.selectedItems.indexOf(element.entity.id);
								if (index > -1) {
									$scope.selectedItems.splice(index, 1);
								}
							}
						});
					});
				};
			});
		};

		$scope.updateStatus = function () {
			var control = confirm('Tamamlamak istediğinize emin misiniz ? Tamamlandığı zaman bu veriler listede görünmeyecektir ama Database üzerinde kalmaya devam edecektir.');
			if (control) {
				Order.updateStatusInvoice($scope.selectedItems).success(function (data, status, headers, config) {
					location.reload();
				});
			}

		};

	}
]);