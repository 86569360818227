bovoApp.controller('NewOrderController',
    ['$scope', '$location', '$rootScope', 'User', '$timeout', 'Order', 'CustomerOrder', 'FileUploader', '$filter', '$http', '$routeParams',
        function ($scope, $location, $rootScope, User, $timeout, Order, CustomerOrder, FileUploader, $filter, $http, $routeParams) {
            $rootScope.proccesCount = 1;

            $scope.newOrderData = {
                type: 1,
                address: {},
                scheduled_time: '',
                status: 2,
                location: {},
                order_payment: 'offline',
                payment_type: 'credit_card',
                flexible_date: 0,
                referance_number_remember: false,
                waybill_number_remember: false,
                personal_number_remember: false,
                work_type: 1,
                buyer_pay: 0,
                use_title: 0,
                use_address: 0,
                location_invoice: {},
                items: [{
                    weight: null,
                }]
            };

            $scope.startMarker = null;
            $scope.endMarker = null;

            var componentForm = {
                street_number: 'short_name',
                route: 'long_name',
                locality: 'long_name',
                administrative_area_level_1: 'short_name',
                administrative_area_level_2: 'short_name',
                country: 'long_name',
                postal_code: 'short_name'
            };

            var mapping = {
                street_number: 'number',
                route: 'street',
                locality: 'city',
                administrative_area_level_1: 'state',
                administrative_area_level_2: 'district',
                country: 'country',
                postal_code: 'zip'
            };

            $scope.$on('city_selected', function (event, data) {
                $scope.newOrderData.location.city = data.name
            }, true);

            $scope.$on('district_selected', function (event, data) {
                $scope.newOrderData.location.district = data.name
                $scope.newOrderData.location.state = data.name
            }, true);

            $rootScope.print = false

            $scope.printControl = function () {
                $rootScope.print = !$rootScope.print
            }

            $scope.printBarcode = function (zpl, orderId, weight) {
                /* if(!localStorage.getItem("printerName")) {
                    alert('Yazıcı Adı girip kaydetmeniz gerekmektedir!');
                    return true;
                } */
                var config = qz.configs.create(localStorage.getItem("printerName"));
                var data = [];   // Raw commands (ZPL provided)
                zpl = JSON.parse(zpl)
                // zpl.forEach(element => {
                //     data.push(element);
                // });
                console.log(data);
                qz.print(config, zpl).then(function () {
                    console.log("Sent data to printer");
                    $scope.cleanInput();
                    // updateStatus(orderId, weight);
                });
            }

            $scope.updateStatus = function (orderId, weight) {
                $.post({
                    url: "/update-mng-status",
                    data: {
                        orderId: orderId,
                        weight: weight
                    },
                    success: function (data) {
                        console.log(data)
                    },
                    error: function (data, xhr) {
                        console.log(data)
                    }
                });
            }

            $scope.cleanInput = function () {
                $("#orderId").removeAttr("disabled");
                $("#orderId").val('');
                $("#orderId").focus();
            }

            $scope.startConnection = function (config) {
                if (!qz.websocket.isActive()) {
                    $scope.updateState('Bekleniyor...', 'default');

                    qz.websocket.connect(config).then(function () {
                        $scope.updateState('Active', 'success');
                        $scope.findPrinters();
                        // cleanInput();
                    }).catch($scope.handleConnectionError());
                } else {
                    $scope.displayMessage('Bağlantı aktif.', 'alert-warning');
                }
            }

            $scope.displayMessage = function (msg, css) {
                Messenger().post({ message: msg, type: "warning" });
            }

            $scope.findPrinters = function () {
                qz.printers.find().then(function (data) {
                    console.log(data)
                    $('#printerName')
                        .find('option')
                        .remove()
                        .end()
                        .append('<option value="">Seçim Yapınız</option>')
                        .val('whatever');
                    for (var i = 0; i < data.length; i++) {
                        $scope.addOption(data[i])
                    }
                    $scope.displayMessage("Yazıcı listesi güncellendi");
                    if (localStorage.getItem("printerName")) {
                        let element = document.getElementById('printerName');
                        element.value = localStorage.getItem("printerName");
                    }
                }).catch(function (e) { console.error(e); });
            }

            $scope.setPrinterName = function (value) {
                if (value) {
                    var name = value;
                } else {
                    var name = $.trim($("#printerName").val());
                }
                localStorage.setItem("printerName", name);
                Messenger().post('Yazıcı Kaydedildi');
            }

            $scope.addOption = function (value) {
                var x = document.getElementById("printerName");
                var option = document.createElement("option");
                option.value = value;
                option.text = value;
                x.add(option);
            }

            $scope.updateState = function (text, css) {
                $("#qz-status").html(text);
            }

            $scope.handleConnectionError = function (err) {
                $scope.updateState('Bağlantı Hatası', 'danger');

                if (err.target != undefined) {
                    if (err.target.readyState >= 2) { //if CLOSING or CLOSED
                        $scope.displayError("QZ bağlantısı sonlandırıldı.");
                    } else {
                        $scope.displayError("Bağlantı sağlanırken bir hata oluştu.");
                        console.error(err);
                    }
                } else {
                    $scope.displayError(err);
                }
            }

            $scope.displayError = function (err) {
                console.error(err);
                $scope.displayMessage(err, 'alert-danger');
            }

            $scope.init = function () {
                Order.newOrderData().success(function (data) {
                    $scope.orderData = data;
                    $scope.tempOrderData = $scope.newOrderData;
                    $scope.newOrderData = {
                        type: 1,
                        address: {},
                        scheduled_time: '',
                        status: 2,
                        location: {},
                        order_payment: 'offline',
                        payment_type: 'credit_card',
                        flexible_date: 0,
                        buyer_pay: 0,
                        location_invoice: {},
                        items: [{
                            weight: null,
                        }],
                        work_type: $scope.orderData.workTypes[0].id
                    };

                    if ($scope.tempOrderData.referance_number_remember) {
                        $scope.newOrderData.referance_number_remember = true;
                        $scope.newOrderData.referance_number = $scope.tempOrderData.referance_number;
                    }

                    if ($scope.tempOrderData.waybill_number_remember) {
                        $scope.newOrderData.waybill_number_remember = true;
                        $scope.newOrderData.waybill_number = $scope.tempOrderData.waybill_number;
                    }

                    if ($scope.tempOrderData.personal_number_remember) {
                        $scope.newOrderData.personal_number_remember = true;
                        $scope.newOrderData.personal_number = $scope.tempOrderData.personal_number;
                    }

                    if ($scope.tempOrderData.company_customer_id) {
                        $scope.newOrderData.company_customer_id = true;
                        $scope.newOrderData.company_customer_id = $scope.tempOrderData.company_customer_id;
                    }

                    // createMapCustomer()

                    if ($routeParams.order_id) {
                        Order.order($routeParams.order_id).success(function (data, status, headers, config) {
                            $scope.newOrderData = data.order
                            $scope.newOrderData.work_type = data.order.work_type ? data.order.work_type.id : $scope.orderData.workTypes[0].id;
                            $scope.locationSelectedCustomer(data.order.location, 'order')
                            if ($scope.newOrderData.location.city_id) {
                                $scope.newOrderData.location.city_id = $scope.newOrderData.location.city_id.toString();
                                setTimeout(function () {
                                    $scope.newOrderData.location.city_id = $scope.newOrderData.location.city_id.toString();
                                    $scope.$apply();
                                }, 300);
                            }
                            if ($scope.newOrderData.location.district_id) {
                                $scope.newOrderData.location.district_id = $scope.newOrderData.location.district_id.toString();
                            }
                            $scope.getAllDistricts($scope.newOrderData.location.city_id)
                        });
                    }

                });
            }

            $scope.selectCustomerId = function (id) {
                $rootScope.company_customer_id = id;
            }

            $scope.setDeliveryType = function (order) {
                if($scope.newOrderData.delivery_type.delivery_type == 'delivery') {
                    $scope.newOrderData.delivery_type.return_type = null;
                    $scope.newOrderData.work_type = 1
                }
                if($scope.newOrderData.delivery_type.delivery_type == 'return' && $scope.newOrderData.delivery_type.return_type == 'return_station') {
                    $scope.newOrderData.work_type = 100
                }
                if($scope.newOrderData.delivery_type.delivery_type == 'return' && $scope.newOrderData.delivery_type.return_type == 'return_door') {
                    $scope.newOrderData.work_type = 115
                }
            }

            $scope.submitNewOrder = function () {
                if (!$scope.newOrderData.location.name) {
                    $('.location-name input').focus();
                } else {
                    setTimeout(() => {
                        document.getElementById('newWorkBtn').click()
                    }, 300);
                }
            }

            $scope.addBox = function () {
                $scope.newOrderData.items.push({
                    weight: null,
                });
            }

            $scope.removeBox = function (index) {
                $scope.newOrderData.items.splice(index, 1);
            }

            $scope.handlePiece = function () {
                if ($scope.newOrderData.piece) {
                    $scope.newOrderData.items = [];
                    for (let index = 0; index < $scope.newOrderData.piece; index++) {
                        $scope.addBox();
                    }
                }
            }

            $scope.handleWeight = function () {
                var weight = $scope.newOrderData.weight / $scope.newOrderData.items.length
                $scope.newOrderData.items.forEach(element => {
                    element.weight = weight
                });
            }

            $scope.useUp = function (type) {
                if (type == 'use_title') {
                    if ($scope.newOrderData.use_title) {
                        $scope.newOrderData.use_title = false
                    } else {
                        $scope.newOrderData.use_title = true
                    }
                }
                if (type == 'use_address') {
                    if ($scope.newOrderData.use_address) {
                        $scope.newOrderData.use_address = false
                    } else {
                        $scope.newOrderData.use_address = true
                    }
                }

                if (type == 'use_title' && $scope.newOrderData.use_title) {
                    $scope.newOrderData.location_invoice.title = $scope.newOrderData.location.name
                } else if (type == 'use_title' && !$scope.newOrderData.use_title) {
                    $scope.newOrderData.location_invoice.title = ''
                }

                if (type == 'use_address' && $scope.newOrderData.use_address) {
                    $scope.newOrderData.location_invoice.address = $scope.newOrderData.location.address
                } else if (type == 'use_address' && !$scope.newOrderData.use_address) {
                    $scope.newOrderData.location_invoice.address = ''
                }

            }

            $scope.newOrder = function (newWork) {
                if (newWork) {
                    $scope.disabledBtn = true;
                    Order.newOrder($scope.newOrderData).success(function (data, status, headers, config) {
                        $rootScope.newOrder = data;
                        $rootScope.newOrderText = 'Kargo No : ' + $rootScope.newOrder.id;
                        if($scope.newOrderData.work_type == 100) {
                            $rootScope.newOrderText += '  <br> Kargo İade Kodu : ' + $rootScope.newOrder.id  + '  <br> MNG Cari Kodu: ' + data.orders[0].mng_return_order_map.customer_number;
                        }
                        if($scope.newOrderData.work_type == 115) {
                            $rootScope.newOrderText += ' <br> Kargo İade Kodu : ' + $rootScope.newOrder.id;
                            $rootScope.newOrderText += ' <br> Kapıdan Alım Tarihi : ' + moment($rootScope.newOrder.orders[0].scheduled_time).format('DD/MM/YYYY');
                        }
                        $('#successBox').modal('show');
                        $scope.init();

                        $scope.disabledBtn = false;
                        // $scope.getOrderDetails(data);

                    }).error(function (data) {
                        Messenger().post({ message: data.message, type: "error" });
                        $scope.disabledBtn = false;
                    });
                }
            }

            $scope.updateOrder = function (newWork) {
                $scope.disabledBtn = true;
                Order.updateOrder($scope.newOrderData).success(function (data, status, headers, config) {
                    Messenger().post('Başarıyla Güncellendi!');
                    $scope.disabledBtn = false;
                    $rootScope.newOrder = data;
                    $rootScope.newOrderText = 'Kargo No : ' + $rootScope.newOrder.id
                    if($scope.newOrderData.work_type == 100) {
                        $rootScope.newOrderText += ' | Kargo İade Kodu : ' + $rootScope.newOrder.id  + ' | MNG Cari Kodu: ' + data.mng_return_order_map.customer_number;
                    }
                    $rootScope.procces = 'update';
                    $('#successBox').modal('show');
                    $scope.init();

                }).error(function (data) {
                    $scope.disabledBtn = false;
                    Messenger().post({ message: data.message, type: "error" });
                });
            };

            $scope.locationSelectedCustomer = function (selected, type) {
                if (typeof type === 'undefined') {
                    if (typeof selected !== 'undefined') {
                        var customer = selected.originalObject;
                    } else {
                        var customer = [];
                    }
                } else {
                    var customer = selected;
                }
                if (typeof customer !== 'undefined' && typeof customer.latitude !== 'undefined') {
                    var location = new google.maps.LatLng(customer.latitude, customer.longitude);
                    setTimeout(function () {
                        // $scope.searchMap.setCenter(location);
                        // $scope.marker.setDuration(1000);
                        // $scope.marker.setEasing("swing");
                        // $scope.marker.setPosition(location);
                        angular.merge($scope.newOrderData.location, customer);
                        $scope.newOrderData.location.city = customer.city;
                        $scope.newOrderData.location.state = customer.state;
                        if (type && type == 'search') {
                            var address = ''
                            if (customer.street) {
                                address += customer.street + ' '
                            }
                            if (customer.number) {
                                address += 'No:' + customer.number + ' '
                            }
                            $scope.newOrderData.location.address = address;
                        } else {
                            $scope.newOrderData.location.address = customer.address;
                        }
                        $scope.newOrderData.location.zipcode = customer.zipcode ? customer.zipcode : customer.zip;
                        $scope.states.forEach(element => {
                            if (element.abbreviation == customer.city) {
                                $scope.newOrderData.location.state = element.name;
                                return true;
                            }
                        });
                        if ($scope.newOrderData.location.city_id) {
                            $scope.newOrderData.location.city_id = $scope.newOrderData.location.city_id.toString();
                            setTimeout(function () {
                                $scope.newOrderData.location.city_id = $scope.newOrderData.location.city_id.toString();
                                $scope.$apply();
                            }, 300);
                        }

                        if ($scope.newOrderData.location.district_id) {
                            $scope.newOrderData.location.district_id = $scope.newOrderData.location.district_id.toString();
                        }
                        $scope.getAllDistricts($scope.newOrderData.location.city_id)
                        $scope.$apply();
                    }, 300);
                }
            }

            $scope.selectedAddress = async function (selected) {
                var city = selected.originalObject.city
                var district = selected.originalObject.district
                var latitude = selected.originalObject.latitude
                var longitude = selected.originalObject.longitude

                $scope.newOrderData.location.district = await district
                $scope.newOrderData.location.city = await city
                $scope.newOrderData.location.latitude = await latitude
                $scope.newOrderData.location.longitude = await longitude


            }

            function createMapCustomer() {
                if (typeof $scope.searchMap !== 'undefined') {
                    return;
                }
                var location = [Number($scope.authenticatedUser.location.lat), Number($scope.authenticatedUser.location.lon)]
                $scope.searchMap = L.map('customerMapOrder', {
                    maxZoom: 18,
                    minZoom: 6
                }).setView(location, 12);

                L.tileLayer('https://bms.basarsoft.com.tr/Service/api/v1/map/ProMap?accId=VcLyowNd1kaSzkvxDKA8WQ&appCode=m1Dm5_9HskqpAoKI0i_baA&x={x}&y={y}&z={z}', {
                    attribution: '&copy; <a href="https://www.basarsoft.com.tr">Basarsoft</a>'
                }).addTo($scope.searchMap);

                $scope.marker = L.marker(location, {
                    draggable: true,
                });
                $scope.marker.on('dragend', function (event) {
                    var marker = event.target;
                    var position = marker.getLatLng();
                    marker.setLatLng(new L.LatLng(position.lat, position.lng), { draggable: 'true' });
                    $scope.searchMap.panTo(new L.LatLng(position.lat, position.lng))

                    Order.reverseGeo({ latitude: position.lat, longitude: position.lng }).success(function (data, status, headers, config) {
                        if (data.length > 0) {
                            angular.merge($scope.newOrderData.location, {
                                address: data[0].ANNAME + ' ' + data[0].ADI + ' ' + data[0].SAG_MAH,
                                city: data[0].SAG_IL,
                                state: data[0].SAG_ILCE,
                                latitude: data[0].CENT.LatY,
                                longitude: data[0].CENT.LongX
                            });
                            setTimeout(() => {
                                $scope.$apply();
                            }, 200);
                        }
                    }).error(function (data) {
                        Messenger().post({ message: data.message, type: "error" });
                    });

                });
                $scope.searchMap.addLayer($scope.marker);

                if (navigator.geolocation && !$scope.newOrderData.id) {
                    navigator.geolocation.getCurrentPosition(function (position) {
                        var location = new L.LatLng(position.coords.latitude, position.coords.longitude);
                        $scope.marker.setLatLng(location);
                        $scope.searchMap.panTo(location)
                    });
                } else {
                    console.log("Geolocation is not supported by this browser.");
                }
            }
        }
    ]);