bovoApp.controller('CustomerConsensusController', ['$scope', 'CustomerOrder', '$location', '$filter', '$routeParams', '$rootScope', 'ngAudio',
	function ($scope, CustomerOrder, $location, $filter, $routeParams, $rootScope, ngAudio) {

		$scope.customerNoteCount = 0;
		$scope.beepSound = ngAudio.load("sounds/beep.mp3");
		$scope.$on('new_customer_note', function () {
			$scope.customerNoteCount += 1;
			$scope.beepSound.play();
		}, true);

		$scope.filterProp = [];
		$scope.selectedItem = [];

		$scope.calendarView = 'month';

		$scope.addSelectedItem = function (item) {

			if ($scope.selectedItem.indexOf(item) > -1) {
				$scope.selectedItem.splice($scope.selectedItem.indexOf(item), 1);
			} else {
				$scope.selectedItem.push(item);
			}
		}

		$scope.filterData = {
			customers: [],
			workers: [],
			workSteps: [],
			page: 1,
			created_at: {
				startDate: null,
				endDate: null
			},
			scheduled_time: {
				startDate: null,
				endDate: null
			}
		}

		$scope.addFilterProps = function (item) {
			if ($scope.filterProp.indexOf(item) === -1)
				$scope.filterProp.push(item);
			else {
				var index = $scope.filterProp.indexOf(item);
				$scope.filterProp.splice(index, 1);
				if (item != 'term') {
					$scope.filterData[item] = [];
				} else {
					$scope.filterData[item] = "";
				}
				if (item == 'date') {
					delete $scope.filterData.startDate;
					delete $scope.filterData.endDate;
				}
				if ($scope.filterProp.length == 0) {
					$scope.filter('clear');
				}
			}
		}

		$scope.getPage = function (page) {
			$scope.filterData.page = page;
			$scope.filter();
		}

		$scope.sort = function (field, sort) {
			$scope.filterData.field = field;
			$scope.filterData.sort = sort;
			$scope.filter();
		}

		$scope.filter = function (type) {
			if (type == 'clear') {
				$scope.filterData = {
					customers: [],
					workers: [],
					workSteps: [],
					page: 1,
					created_at: {
						startDate: null,
						endDate: null
					},
					scheduled_time: {
						startDate: null,
						endDate: null
					}
				}
				$scope.filterProp = [];
			} else {
				$scope.filterData.filterProp = $scope.filterProp;
				if (typeof $scope.filterData.created_at.endDate !== 'undefined' && $scope.filterData.created_at.endDate !== null) {
					$scope.filterData.filterProp.created_at = 'created_at';
				}
				if (typeof $scope.filterData.scheduled_time.endDate !== 'undefined' && $scope.filterData.scheduled_time.endDate !== null) {
					$scope.filterData.filterProp.scheduled_time = 'scheduled_time';
				}
			}
			$scope.init();
		}

		$scope.$on('refresh_data', function () {
			console.log('refreshing init...')
			$scope.init();
		}, true);

		$scope.clearFilter = function (page) {
			$scope.filterData = {
				workers: [],
				workSteps: [],
				page: 1,
				created_at: {
					startDate: null,
					endDate: null
				},
				scheduled_time: {
					startDate: null,
					endDate: null
				}
			}
		}

		$scope.init = function (problem) {
			if ($routeParams.type) {
				$scope.filterData.type = $routeParams.type;
			}
			if (problem) {
				$scope.filterData.problem = problem;
			}
			CustomerOrder.consensus($scope.filterData).success(function (data, status, headers, config) {
				$scope.data = data;
			});
		};

		
		$scope.approve = function(voyage) {
			if (confirm('Teslim al süreci tamamlanacaktır. Devam etmek istiyor musunuz ?')) {
				CustomerOrder.approveConsensus(voyage).success(function(response, status, headers, config) {	
					$scope.init();
					Messenger().post({message: 'Teslim alma süreci başarıyla tamamlandı!',type: "success"});
				});
			}
		}

		$scope.downloadExcel = function () {
			CustomerOrder.generateExcelReportNotification($scope.filterData).success(function (data, status, headers, config) {
				window.location.href = data['url'];
			});
		};

	}
]);