bovoApp.controller('CollectionController', ['$scope', 'Collection', '$location', '$routeParams', '$filter', '$translate',
  function ($scope, Collection, $location, $routeParams, $filter, $translate) {
    $scope.deleted = [];

    $scope.collectionType = $location.url().replace(/^\/|\/$/g, '');

    $scope.selectAll = function (select) {
      if (select) {
        $scope.deleted = [];
        for (let i = 0; i < $scope.data.collections.data.length; i++) {
          $scope.deleted.push($scope.data.collections.data[i].id);
        }
      } else {
        $scope.deleted = [];
      }
    };

    $scope.filterData = {
      station_id: [],
      price: '',
      vehicle: [],
      page: 1,
      collection_type: $scope.collectionType,
      date: {
        startDate: null,
        endDate: null
      }
    }

    $scope.clearFilter = function (page) {
      $scope.filterData = {
        station_id: [],
        price: '',
        vehicle: [],
        page: 1,
        collection_type: $scope.collectionType,
        date: {
          startDate: null,
          endDate: null
        }
      }
      $scope.all();
    }

    $scope.$on('refresh_data', function () {
      console.log('refreshing map...')
      $scope.all();
    }, true);

    $scope.$on('new_data', function () {
      $scope.collection = {}
      $scope.new();
      $('#collectionDetail').modal('hide');
      $('#newCollection').modal('show');
    }, true);

    $scope.editCollection = function (page) {
      $scope.new();
      $('#collectionDetail').modal('hide');
      $('#newCollection').modal('show');
    }

    $scope.getPage = function (page) {
      $scope.filterData.page = page;
      $scope.all();
    }

    $scope.all = function (type) {
      Collection.all($scope.filterData).success(function (data, status, headers, config) {
        $scope.data = data;

        if (type && type == 'open') {
          var tempCollection = $scope.data.collections.data.find(function (element) {
            return element.id == $scope.collection.id;
          })
          $scope.openDetail(tempCollection);
        }
      });
    }

    $scope.get = function () {
      Collection.get($routeParams.id).success(function (data, status, headers, config) {
        $scope.data = data;
        jQuery('#date').datetimepicker({
          timepicker: false,
          datepicker: true,
          format: 'Y-m-d'
        });
      });
    }

    $scope.new = function () {
      Collection.new().success(function (data, status, headers, config) {
        jQuery('#date').datetimepicker({
          timepicker: false,
          datepicker: true,
          format: 'Y-m-d'
        });
      });
    }

    $scope.delete = function (type) {
      if (type == 'set') {
        $('#delete').modal('show');
      } else if (type == 'delete') {
        Collection.delete($scope.deleted).success(function (data, status, headers, config) {
          Messenger().post(data.message);
          for (var i = $scope.deleted.length - 1; i >= 0; i--) {
            var index = $scope.data.collections.data.indexOf($filter('filter')($scope.data.collections.data, {
              id: $scope.deleted[i]
            })[0]);
            if (index > -1)
              $scope.data.collections.data.splice(index, 1);
          }
          $scope.deleted = [];
          $('#delete').modal('hide');
        }).error(function (data) {
          Messenger().post({
            message: data.message,
            type: "error"
          });
        });
      }
    }

    $scope.save = function () {
      if ($scope.collection.id) {
        $scope.update();
      } else {
        Collection.save($scope.collection).success(function (data, status, headers, config) {
          $scope.data = data;
          Messenger().post(data.message);
          $('#newCollection').modal('hide');
          $scope.all();
        }).error(function (data) {
          Messenger().post({
            message: data.message,
            type: "error"
          });
        });
      }
    }

    $scope.update = function () {
      Collection.update($scope.collection).success(function (data, status, headers, config) {
        $scope.data = data;
        Messenger().post(data.message);
        $('#newCollection').modal('hide');
        $scope.all('open');

      }).error(function (data) {
        Messenger().post({
          message: data.message,
          type: "error"
        });
      });
    }

    $scope.openDetail = function (data) {
      $scope.collection = data;
      $('#collectionDetail').modal('show');
    }

    $scope.toggleSelection = function toggleSelection(id) {
      var idx = $scope.deleted.indexOf(id);
      if (idx > -1) {
        $scope.deleted.splice(idx, 1);
      } else {
        $scope.deleted.push(id);
      }
    };
    $scope.collection = {
      count: 3,
      collections: []
    }
    jQuery('#date').datetimepicker({
      timepicker: false,
      datepicker: true,
      format: 'Y-m-d'
    });

    $scope.showCompletedOrders = function (collection) {
      if($scope.collectionType == 'collection') {
        var data = {
          collection_id: collection.id,
        }
      }else {
        var data = {
          collection_id: collection.id,
        }
      }
      var url = 'orders?data=' + JSON.stringify(data);
      window.open(url, '_blank');
    }

    $scope.openPayment = function (payment) {
      $scope.payment = payment;
      jQuery('#payment_date').datetimepicker({
        timepicker: false,
        datepicker: true,
        format: 'Y-m-d'
      });
      $('#collectionDetail').modal('hide');
      $('#newCollectionPayment').modal('show');
    }

    $scope.newPayment = function () {
      $scope.payment = {};
      jQuery('#payment_date').datetimepicker({
        timepicker: false,
        datepicker: true,
        format: 'Y-m-d'
      });
      $('#collectionDetail').modal('hide');
      $('#newCollectionPayment').modal('show');
    }

    $scope.addPayment = function () {
      if ($scope.payment.id) {
        $scope.updatePayment();
      } else {
        $scope.payment.collection_id = $scope.collection.id;
        $scope.payment.price = $scope.payment.rate * $scope.payment.qty;
        Collection.addPayment($scope.payment).success(function (data, status, headers, config) {
          $scope.data = data;
          Messenger().post(data.message);
          $('#newCollectionPayment').modal('hide');
          $scope.all('open');
        }).error(function (data) {
          Messenger().post({
            message: data.message,
            type: "error"
          });
        });
      }
    }

    $scope.updatePayment = function () {
      $scope.payment.price = $scope.payment.rate * $scope.payment.qty;
      Collection.updatePayment($scope.payment).success(function (data, status, headers, config) {
        $scope.data = data;
        Messenger().post(data.message);
        $('#newCollectionPayment').modal('hide');
        $scope.all('open');
      }).error(function (data) {
        Messenger().post({
          message: data.message,
          type: "error"
        });
      });
    }

    $scope.deletePayment = function (payment) {
      if (confirm($translate.instant("ARE_U_SURE_DELETE"))) {
        Collection.deletePayment(payment.id).success(function (data, status, headers, config) {
          Messenger().post(data.message);
          $scope.all('open');
        }).error(function (data) {
          Messenger().post({
            message: data.message,
            type: "error"
          });
        });
      }
    }

    $scope.getPrice = function(payment) {
      try {
        $scope.payment.price = $scope.payment.rate * $scope.payment.qty;
      } catch (error) {
      }
    }

  }
]);