bovoApp.
  factory('Mng', ['$http', function ($http) {
    return {
      fails: function (data) {
        return $http.post('api/mng-fails', data);
      },
      responseFailOrder: function (data) {
        return $http.post('api/mng-fail-response', data);
      }
    };
  }]);