bovoApp.
factory('User', ['$http', function($http) {
    return {
      login: function(data) {
        return $http.post('login',data);
      },
      register: function(data) {
        return $http.post('register',data);
      },
      forgot: function(data) {
        return $http.post('forgot',data);
      },
      password: function(data) {
        return $http.post('password',data);
      },
      getUser: function() {
        return $http.get('api/user');
      },
      firstLook: function() {
        return $http.post('api/first-look');
      },
      updates: function() {
        return $http.post('api/updates');
      },
      completeDocusign: function(data) {
        return $http.post('complete-docusign', data);
      },
      registerInit: function() {
        return $http.get('register-init');
      },
      setStorage: function(data) {
        return $http.post('set-storage', data);
      },
      getStorage: function(data) {
        return $http.post('get-storage', data);
      },
    };
}]);