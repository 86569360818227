bovoApp.
factory('FieldProject', ['$http', function($http) {
    return {
      all: function() {
        return $http.get('api/field-projects');
      },
      get: function(id) {
        return $http.get('api/field-project/' + id);
      },
      delete: function(id) {
        return $http.delete('api/delete-field-project/' + id);
      },
      save: function(data) {
        return $http.post('api/save-field-project',data);
      },
      update: function(data) {
        return $http.post('api/update-field-project',data);
      }
    };
}]);