bovoApp.controller('SupportListController', ['$scope', 'Order','Support', '$location','$filter','$routeParams','$rootScope','ngAudio',
	function ($scope, Order, Support, $location, $filter, $routeParams, $rootScope, ngAudio) {

		$scope.filterProp = [];
		$scope.selectedItem = [];
		$scope.noteCount = 0;
		$scope.beepSound = ngAudio.load("sounds/beep.mp3");

		$scope.$on('new_note', function() {
			$scope.noteCount += 1;
			$scope.beepSound.play();
		}, true);

		$scope.addSelectedItem = function(item) {
			
			if($scope.selectedItem.indexOf(item) > -1) {
				$scope.selectedItem.splice($scope.selectedItem.indexOf(item), 1);
			}else {
				$scope.selectedItem.push(item);
			}
		}
 
		$scope.filterData = {
			customers: [],
			workers: [],
			workSteps: [],
			page: 1,
			support_status: null,
			created_at : { 
				startDate: null, 
				endDate: null 
			},
			scheduled_time : { 
				startDate: null, 
				endDate: null 
			}
		}

		$scope.opts = {
            locale: {
                applyClass: 'btn-green',
                applyLabel: "Seç",
                fromLabel: "From",
                format: "DD-MM-YYYY",
                toLabel: "To",
                cancelLabel: 'İptal',
                customRangeLabel: 'Özel Aralık'
            },
            ranges: {
                'Bugün': [moment(), moment()],
                'Dün': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'Son 7 Gün': [moment().subtract(6, 'days'), moment()],
                'Son 30 Gün': [moment().subtract(29, 'days'), moment()],
                'Bu Ay': [moment().startOf('month'), moment().endOf('month')],
                'Geçen Ay': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
            }
        }

		$scope.getPage = function(page) {
			$scope.filterData.page = page;
			$scope.filter();
		}

		$scope.sort = function(field,sort) {
			$scope.filterData.field = field;
			$scope.filterData.sort = sort;
			$scope.filter();
		}

		$scope.filter = function (type) {
			if(type == 'clear') {
				$scope.filterData = {
					customers: [],
					workers: [],
					workSteps: [],
					page: 1,
					support_status: null,
					created_at : { 
						startDate: null, 
						endDate: null 
					},
					scheduled_time : { 
						startDate: null, 
						endDate: null 
					}
				}
				$scope.filterProp = [];
			}else {
				$scope.filterData.filterProp = $scope.filterProp;
				if(typeof $scope.filterData.created_at.endDate !== 'undefined' && $scope.filterData.created_at.endDate !== null) {
					$scope.filterData.filterProp.created_at = 'created_at';
				}
				if(typeof $scope.filterData.scheduled_time.endDate !== 'undefined' && $scope.filterData.scheduled_time.endDate !== null) {
					$scope.filterData.filterProp.scheduled_time = 'scheduled_time';
				}
			}
			$scope.orders();
		}

		$scope.$on('refresh_data', function() {
			console.log('refreshing list...')
			$scope.orders();
		}, true);

		$scope.searchOrder = function(term) {
			$scope.filterData.term = term;
			$scope.orders(); 
		}

		$scope.clearFilter = function(page) {
			$scope.filterData = {
				term: '',
				workers: [],
				workSteps: [],
				page: 1,
				support_status: null,
				created_at : { 
					startDate: null, 
					endDate: null 
				},
				scheduled_time : { 
					startDate: null, 
					endDate: null
				}
			}
			$scope.filterProp = [];
			$scope.orders();
		}

		$scope.orders = function () {
			Support.orders($scope.filterData).success(function(data, status, headers, config) {
				$scope.data = data;
				$scope.data.steps.unshift({
					id : 0,
					name : 'Atandı'
				});
			});
		};


		$scope.toggleSelection = function toggleSelection(event) {
          
	        if(event.target.checked) {

	         	$scope.selectedItem = [];
	         	
	         	for (var i = 0; i < $scope.data.orders.data.length ; i++) {
	         		
	         		$scope.selectedItem.push($scope.data.orders.data[i].id);
	         	}
	        }else{

				$scope.selectedItem = [];
				
			}
        };

		$scope.bulkComplete = function() {
			if (confirm('Seçilen kargolar tamamlandı olarak güncellenecektir. Devam etmek istiyor musunuz ?')) {
				Order.bulkComplete({orders: $scope.selectedItem}).success(function(response, status, headers, config) {	
					$scope.orders();
					Messenger().post({message: 'Seçilen kargolar başarıyla tamamlandı!',type: "success"});
					$scope.selectedItem = [];
				});
			}
		}

	}
]);