bovoApp.controller('VoyageController', ['$scope', 'Voyage', '$location', '$routeParams', '$filter', '$translate', 'ngAudio', 'OrderMovement',
    function ($scope, Voyage, $location, $routeParams, $filter, $translate, ngAudio, OrderMovement) {
        $scope.deleted = [];
        $scope.orderType = 'all';

        var successSound = ngAudio.load("sounds/success.mp3");
        var errorSound = ngAudio.load("sounds/error.mp3");

        $scope.filterData = {
            customers: [],
            workers: [],
            workSteps: [],
            page: 1,
            created_at: {
                startDate: moment(),
                endDate: moment()
            },
            scheduled_time: {
                startDate: null,
                endDate: null
            }
        }

        $scope.getPage = function (page) {
            $scope.filterData.page = page;
            $scope.filter();
        }

        $scope.sort = function (field, sort) {
            $scope.filterData.field = field;
            $scope.filterData.sort = sort;
            $scope.filter();
        }

        $scope.filter = function (type) {
            if (type == 'clear') {
                $scope.filterData = {
                    customers: [],
                    workers: [],
                    workSteps: [],
                    page: 1,
                    created_at: {
                        startDate: null,
                        endDate: null
                    },
                    scheduled_time: {
                        startDate: null,
                        endDate: null
                    }
                }
                $scope.filterProp = [];
            }
            $scope.all();
        }

        $scope.$on('refresh_data', function () {
            console.log('refreshing customers...')
            $scope.all();
        }, true);

        $scope.all = function () {
            Voyage.all($scope.filterData).success(function (data, status, headers, config) {
                $scope.data = data;
            });
        }

        $scope.openDetail = function (item) {
            Voyage.get(item.id).success(function (data, status, headers, config) {
                $('#voyageDetail').modal('show');
                $scope.voyage = data.voyage;
                if (!$scope.data) {
                    $scope.data = [];
                }
                $scope.data.transfers = data.transfers;
                $scope.data.stations = data.stations;
                $scope.data.customers = data.customers;
                $scope.data.lineVehicles = data.lineVehicles;
                $scope.data.orders = data.orders;
                $scope.data.out = data.out;
                $scope.allOrders = data.orders;
                $scope.notReadedOrders = data.not_readed_orders;
            });
        }

        $scope.readInit = function () {
            Voyage.get($routeParams.id).success(function (data, status, headers, config) {
                $scope.voyage = data.voyage;
                $scope.orderCount = data.orders.total;
            });
        }

        $scope.updateAndRead = function () {
            if (!$scope.voyage.end_km) {
                Messenger().post({
                    message: 'Bitiş km giriniz',
                    type: "error"
                });
                return;
            }
            $scope.selectedVoyage.end_km = $scope.voyage.end_km;

            Voyage.update($scope.selectedVoyage).success(function (data, status, headers, config) {
                $scope.data = data;
                $location.path('/voyages/' + data.data.id).search('read_type', 'down');
                Messenger().post('Başarılı');
            }).error(function (data) {
                Messenger().post({
                    message: data.message,
                    type: "error"
                });
            });
        }

        $scope.saveAndSend = function () {
            // if (!$scope.voyage.end_km) {
            //   Messenger().post({
            //     message: 'Bitiş km giriniz',
            //     type: "error"
            //   });
            //   return;
            // }
            if (confirm('Bu araç okutmaya kapatılıp yolda durumuna geçecektir. Devam etmek istiyor musunuz ?')) {
                $scope.voyage.status = 'on_way';
                $scope.voyage.no_km = true;
                Voyage.update($scope.voyage).success(function (data, status, headers, config) {
                    $location.path('/voyages/');
                    $scope.openVoyageDetail($scope.voyage.id);
                    Messenger().post('Başarılı');
                }).error(function (data) {
                    Messenger().post({
                        message: data.message,
                        type: "error"
                    });
                });
            }

        }

        $scope.readOrder = function () {
            if ($routeParams.read_type) {
                $scope.movement.read_type = $routeParams.read_type
            }
            $scope.readType = $routeParams.read_type
            $scope.movement.voyage_id = $routeParams.id;
            Voyage.readOrder($scope.movement).success(function (data, status, headers, config) {
                $scope.movement.code = null
                $scope.movement.force = false;
                $scope.orderCount = data.data.total_order
                Messenger().post(data.message);
                $scope.readInit();
                successSound.play()
            }).error(function (data) {
                if (data.type && data.type === 'batch_voyage') {
                    successSound.play()
                    $scope.errorMessage = data.message
                    $('#wrongStation').modal('show');
                    setTimeout(() => {
                        document.getElementById("wrongStation").focus();
                    }, 300);
                } else if (data.type && data.type === 'wrong_station') {
                    errorSound.play()
                    $scope.errorMessage = data.message
                    $('#wrongStation').modal('show');
                    setTimeout(() => {
                        document.getElementById("wrongStation").focus();
                    }, 300);
                } else {
                    errorSound.play();
                    Messenger().post({
                        message: data.message,
                        type: "error"
                    });
                }

            });
        };

        $scope.continue = function () {
            $scope.movement.force = true;
            $('#wrongStation').modal('hide');
            $scope.readOrder();
        }

        $scope.$on('new_data', function () {
            $('#voyageModal').modal('show');
            $scope.new();
        }, true);

        $scope.new = function () {
            Voyage.new().success(function (data, status, headers, config) {
                if (!$scope.data) {
                    $scope.data = []
                }
                $scope.data.transfers = data.transfers;
                $scope.data.stations = data.stations;
                $scope.data.customers = data.customers;
                $scope.data.lineVehicles = data.lineVehicles;
                $scope.data.companies = data.companies;
                $scope.voyage = {
                    start_type: 'transfer',
                    end_type: 'station'
                }
            });
        }

        $scope.setMovementType = function () {
            if ($scope.voyage.movement_type == 'load_vehicle') {
                // Müşteriden teslim al
                $scope.voyage.start_type = 'customer';
            }
            if ($scope.voyage.movement_type == 'load_line_vehicle') {
                // Ring Aracına yükle
                $scope.voyage.start_type = 'customer';
            }
            if ($scope.voyage.movement_type == 'ground_cargo' || $scope.voyage.movement_type == 'create_group_barcode') {
                $scope.voyage.start_type = '';
                $scope.voyage.end_type = '';
            }
            if ($scope.voyage.movement_type == 'group_cargo') {
                $scope.voyage.start_type = '';
                $scope.voyage.end_type = '';
            }
        }

        $scope.delete = function (type) {
            if (type == 'set') {
                $('#delete').modal('show');
            } else if (type == 'delete') {
                Voyage.delete($scope.deleted).success(function (data, status, headers, config) {
                    Messenger().post($scope.translate(data.message.toString()));
                    for (var i = $scope.deleted.length - 1; i >= 0; i--) {
                        var index = $scope.data.voyages.indexOf($filter('filter')($scope.data.voyages, {
                            id: $scope.deleted[i]
                        })[0]);
                        if (index > -1) {
                            $scope.data.voyages.splice(index, 1);
                        }
                    }
                    $scope.deleted = [];
                    $('#delete').modal('hide');
                }).error(function (data) {
                    Messenger().post({
                        message: data.message,
                        type: "error"
                    });
                });
            }
        }

        $scope.postForm = function (user) {
            if ($scope.voyage.movement_type == 'load_line_vehicle') {
                if (!$scope.voyage.line_vehicle_id) {
                    Messenger().post({
                        message: 'Ring Aracı Seçmeniz Gerekiyor!',
                        type: "error"
                    });
                    return;
                }
            }
            if ($scope.voyage.movement_type === 'group_barcode') {
                $scope.groupBarcode();
                return;
            }

            if ($scope.voyage.id) {
                $scope.update();
            } else {
                $scope.save();
            }
        }

        $scope.save = function () {
            if ($scope.voyage.movement_type === "create_group_barcode" && $scope.voyage.start_relation_id) {
                $scope.voyage.start_type = 'station';
            }
            Voyage.save($scope.voyage).success(function (data, status, headers, config) {
                $scope.data = data;
                Messenger().post('Başarılı');
                $('#voyageModal').modal('hide');
                $scope.all();
                $location.path('/voyages/' + data.data.id);
            }).error(function (data) {
                Messenger().post({
                    message: data.message,
                    type: "error"
                });
            });
        }

        $scope.update = function () {
            Voyage.update($scope.voyage).success(function (data, status, headers, config) {
                $scope.data = data;
                Messenger().post('Başarılı');
                $('#voyageModal').modal('hide');
                $scope.all();
            }).error(function (data) {
                Messenger().post({
                    message: data.message,
                    type: "error"
                });
            });
        }

        $scope.groupBarcode = function () {
            Voyage.groupBarcode($scope.voyage).success(function (data, status, headers, config) {
                $scope.data = data;
                Messenger().post('Başarılı');
            }).error(function (data) {
                Messenger().post({
                    message: data.message,
                    type: "error"
                });
            });
        }

        $scope.toggleSelection = function toggleSelection(id) {
            var idx = $scope.deleted.indexOf(id);
            if (idx > -1) {
                $scope.deleted.splice(idx, 1);
            } else {
                $scope.deleted.push(id);
            }
        };

        $scope.findLineVehicle = function () {
            OrderMovement.findLineVehicle({
                line_vehicle_unique_id: $scope.voyage.line_vehicle_unique_id
            }).success(function (data, status, headers, config) {
                $scope.lineVehicle = data.lineVehicle;
                $scope.voyage.line_vehicle_id = data.lineVehicle.id;
            }).error(function (data) {
                $scope.lineVehicle = null;
                $scope.voyage.line_vehicle_id = null;

                // Messenger().post({
                //   message: data.message,
                //   type: "error"
                // });
            });
        };

        $scope.findVoyage = function () {
            Voyage.findVoyage($scope.voyage).success(function (data, status, headers, config) {
                $scope.selectedVoyage = data.voyage;
            }).error(function (data) {
                $scope.selectedVoyage = null;
            });
        }

        $scope.getTransferVoyages = function () {
            Voyage.getTransferVoyages($scope.voyage).success(function (data, status, headers, config) {
                $scope.voyages = data.voyages;
            }).error(function (data) {
                $scope.selectedVoyage = null;
            });
        }

        $scope.setSelectedVoyage = function (item) {
            $scope.selectedVoyage = item;
        }

    }
]);