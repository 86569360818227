bovoApp.
factory('Pricing', ['$http', function($http) {
    return {
      all: function() {
        return $http.get('api/pricings');
      },
      get: function(id) {
        return $http.get('api/pricing/' + id);
      },
      new: function(id) {
        return $http.get('api/new-pricing');
      },
      delete: function(id) {
        return $http.delete('api/delete-pricing/' + id);
      },
      save: function(data) {
        return $http.post('api/save-pricing',data);
      },
      update: function(data) {
        return $http.post('api/update-pricing',data);
      },
      getTables: function(data) {
        return $http.post('api/pricing-tables',data);
      },
    };
}]);