bovoApp.
  factory('ReturnOrder', ['$http', function($http) {
    return {
      all: function(data) {
        return $http.post('api/return-orders', data);
      },
      get: function(id) {
        return $http.get('api/return-order/' + id);
      },
      new: function(id) {
        return $http.get('api/new-return-order');
      },
      addOrder: function(data) {
        return $http.post('api/add-return-order',data);
      },
      delete: function(id) {
        return $http.delete('api/delete-return-order/' + id);
      },
      save: function(data) {
        return $http.post('api/save-return-order',data);
      },
      update: function(data) {
        return $http.post('api/update-return-order',data);
      },
    };
}]);