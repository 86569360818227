bovoApp.
factory('LineVehicle', ['$http', function($http) {
    return {
      all: function(type) {
        return $http.get('api/line-vehicles?type=' + type);
      },
      get: function(id) {
        return $http.get('api/line-vehicle/' + id);
      },
      new: function(id) {
        return $http.get('api/new-line-vehicle');
      },
      delete: function(id) {
        return $http.delete('api/delete-line-vehicle/' + id);
      },
      save: function(data) {
        return $http.post('api/save-line-vehicle',data);
      },
      update: function(data) {
        return $http.post('api/update-line-vehicle',data);
      }
    };
}]);