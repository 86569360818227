bovoApp.
  factory('Customer', ['$http', function ($http) {
    return {
      init: function (data) {
        return $http.post('api/customers', data);
      },
      passive: function (data) {
        return $http.post('api/passive-customers', data);
      },
      initMap: function (data) {
        return $http.post('api/customers-map', data);
      },
      newCustomer: function () {
        return $http.get('api/new-customer');
      },
      sendNote: function (data) {
        return $http.post('api/send-customer-note', data);
      },
      show: function (e) {
        return $http.get('api/customer/' + e);
      },
      saveCustomer: function (data) {
        return $http.post('api/save-customer', data);
      },
      deleteCustomer: function (data) {
        return $http.post('api/delete-customer', data);
      },
      restoreCustomer: function (data) {
        return $http.post('api/restore-customers', data);
      },
      updateAddress: function (data) {
        return $http.post('api/update-address', data);
      },
      deleteAddress: function (data) {
        return $http.post('api/delete-address', data);
      },
      addAddress: function (data) {
        return $http.post('api/add-address', data);
      },
      updateCustomer: function (data) {
        return $http.post('api/update-customer', data);
      },
      importCustomerList: function (data) {
        return $http.post('api/import-customer-list', data);
      },
      donwloadSampleExcel: function () {
        return $http.get('api/download-sample-excel');
      },
      removeDownloadFile: function (data) {
        return $http.post('api/remove-download-file', data);
      },
      generateExcelReport: function (data) {
        return $http.post('api/download-customers-reports-in-excel', data);
      }
    };
  }]);