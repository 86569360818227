(function (module) {
    module.directive('googlePlace', function () {
        var componentForm = {
            street_number: 'short_name',
            route: 'long_name',
            locality: 'long_name',
            administrative_area_level_1: 'short_name',
            administrative_area_level_2: 'short_name',
            country: 'long_name',
            postal_code: 'short_name'
        };
        var mapping = {
            street_number: 'number',
            route: 'street',
            locality: 'city',
            administrative_area_level_1: 'city',
            administrative_area_level_2: 'state',
            country: 'country',
            postal_code: 'zip'
        };
        return {
            require: 'ngModel',
            scope: {
                ngModel: '=',
                details: '=?'
            },
            link: function (scope, element, attrs, model) {
                var options = {
                    types: attrs.googlePlace !== "" ? attrs.googlePlace.split(',') : []
                };

                scope.gPlace = new google.maps.places.Autocomplete(element[0], options);

                google.maps.event.addListener(scope.gPlace, 'place_changed', function () {
                    var place = scope.gPlace.getPlace();
                    var details = place.geometry && place.geometry.location ? {
                        latitude: place.geometry.location.lat(),
                        longitude: place.geometry.location.lng()
                    } : {};
                    for (var i = 0; i < place.address_components.length; i++) {
                        var addressType = place.address_components[i].types[0];
                        if (componentForm[addressType]) {
                            var val = place.address_components[i][componentForm[addressType]];
                            details[mapping[addressType]] = val;
                        }
                    }
                    details.formatted = place.formatted_address;
                    details.address = place.formatted_address;
                    details.placeId = place.place_id;
                    details.city = details.city;
                    details.state = details.state;
                    scope.$apply(function () {
                        scope.details = details;
                        model.$setViewValue(details);
                    });

                });
            }
        };
    });
}(angular.module("google.places", [])));