bovoApp.controller('OfferController', ['$scope', 'Offer', '$location', '$routeParams', '$filter',
  function ($scope, Offer, $location, $routeParams, $filter) {
    $scope.deleted = [];
    $scope.filterData = {
      station_id: [],
      price: '',
      vehicle: [],
      page: 1,
      date: {
        startDate: null,
        endDate: null
      }
    }

    $scope.clearFilter = function (page) {
      $scope.filterData = {
        station_id: [],
        price: '',
        vehicle: [],
        page: 1,
        date: {
          startDate: null,
          endDate: null
        }
      }
      $scope.all();
    }

    $scope.offer = {
      count: 3,
      offers: []
    }


    $scope.selectAll = function (select) {
      if (select) {
        $scope.deleted = [];
        for (let i = 0; i < $scope.data.offers.data.length; i++) {
          $scope.deleted.push($scope.data.offers.data[i].id);
        }
      } else {
        $scope.deleted = [];
      }
    };

    $scope.$on('refresh_data', function () {
      console.log('refreshing map...')
      $scope.all();
    }, true);

    $scope.$on('new_data', function () {
      $('#newOffer').modal('show');
      $scope.new();
    }, true);

    $scope.getPage = function (page) {
      $scope.filterData.page = page;
      $scope.all();
    }

    $scope.all = function () {
      Offer.all($scope.filterData).success(function (data, status, headers, config) {
        $scope.data = data;
      });
    }

    $scope.sendToApp = function (id) {
      Offer.sendToApp({ offer_id: id }).success(function (data, status, headers, config) {
        Messenger().post(data.message);
      });
    }

    $scope.editOffer = function (offer) {
      $scope.offer = offer;
      $('#newOffer').modal('show');
      $('#offerDetail').modal('hide');
      Offer.new().success(function (data, status, headers, config) {
        $scope.stations = data.stations;
        jQuery('#startoffer').datetimepicker({
          format: 'H:i',
          formatTime: 'H:i',
          datepicker: false,
          step: 30,
        });
        jQuery('#endoffer').datetimepicker({
          format: 'H:i',
          formatTime: 'H:i',
          datepicker: false,
          step: 30,
        })
      });
    }

    $scope.get = function () {
      Offer.get($routeParams.id).success(function (data, status, headers, config) {
        $scope.data = data;
        $scope.data.offer.station_id = $scope.data.offer.station_id + "";
        jQuery('#dateoffer').datetimepicker({
          timepicker: false,
          datepicker: true,
          format: 'Y-m-d'
        });
        jQuery('#startoffer').datetimepicker({
          format: 'H:i',
          formatTime: 'H:i',
          datepicker: false,
          step: 30,
        });
        jQuery('#endoffer').datetimepicker({
          format: 'H:i',
          formatTime: 'H:i',
          datepicker: false,
          step: 30,
        });
      });
    }

    $scope.new = function () {
      Offer.new().success(function (data, status, headers, config) {
        $scope.offer = {
          receiver: 1
        };
        $scope.stations = data.stations;
        jQuery('#dateoffer').datetimepicker({
          timepicker: false,
          datepicker: true,
          format: 'Y-m-d'
        });
        jQuery('#startoffer').datetimepicker({
          format: 'H:i',
          formatTime: 'H:i',
          datepicker: false,
          step: 30,
        });
        jQuery('#endoffer').datetimepicker({
          format: 'H:i',
          formatTime: 'H:i',
          datepicker: false,
          step: 30,
        })
      });
    }

    $scope.delete = function (type) {
      if (type == 'set') {
        $('#delete').modal('show');
      } else if (type == 'delete') {
        Offer.delete($scope.deleted).success(function (data, status, headers, config) {
          Messenger().post(data.message);
          for (var i = $scope.deleted.length - 1; i >= 0; i--) {
            var index = $scope.data.offers.data.indexOf($filter('filter')($scope.data.offers.data, {
              id: $scope.deleted[i]
            })[0]);
            if (index > -1)
              $scope.data.offers.data.splice(index, 1);
          }
          $scope.deleted = [];
          $('#delete').modal('hide');
        }).error(function (data) {
          Messenger().post({
            message: data.message,
            type: "error"
          });
        });
      }
    }

    $scope.removeWorker = function (offer, worker_id) {
      if (confirm('This driver will remove, are you sure ?')) {
        Offer.removeWorker({ offer_id: offer.id, worker_id: worker_id }).success(function (data, status, headers, config) {
          Messenger().post(data.message);
          $scope.openDetail(offer);
          $scope.all();
        }).error(function (data) {
          Messenger().post({
            message: data.message,
            type: "error"
          });
        });
      }
    }

    $scope.addWorker = function (worker_id) {
      Offer.addWorker({ offer_id: $scope.offer.id, worker_id: worker_id }).success(function (data, status, headers, config) {
        Messenger().post(data.message);
        $('#newOfferWorker').modal('hide');
        $scope.openDetail($scope.offer);
        $scope.all();
      }).error(function (data) {
        Messenger().post({
          message: data.message,
          type: "error"
        });
      });
    }

    $scope.save = function () {
      if ($scope.offer.id) {
        $scope.update();
      } else {
        Offer.save($scope.offer).success(function (data, status, headers, config) {
          $scope.data = data;
          Messenger().post(data.message);
          $('#newOffer').modal('hide');
          $scope.all();
        }).error(function (data) {
          Messenger().post({
            message: data.message,
            type: "error"
          });
        });
      }
    }

    $scope.update = function () {
      Offer.update($scope.offer).success(function (data, status, headers, config) {
        $scope.openDetail($scope.offer)
        Messenger().post(data.message);
        $('#newOffer').modal('hide');
        $scope.all();
      }).error(function (data) {
        Messenger().post({
          message: data.message,
          type: "error"
        });
      });
    }

    $scope.openDetail = function (data) {
      Offer.get(data.id).success(function (data, status, headers, config) {
        $scope.stations = data.stations;
        $scope.offer = data.offer;
        $('#offerDetail').modal('show');
      });
    }

    $scope.toggleSelection = function toggleSelection(id) {
      var idx = $scope.deleted.indexOf(id);
      if (idx > -1) {
        $scope.deleted.splice(idx, 1);
      } else {
        $scope.deleted.push(id);
      }
    };

    $scope.calculatePrice = function calculatePrice(id) {
      var start = moment(moment().format('MM-DD-YYYY') + ' ' + $scope.offer.start_time, 'MM-DD-YYYY hh:mm');
      var end = moment(moment().format('MM-DD-YYYY') + ' ' + $scope.offer.end_time, 'MM-DD-YYYY hh:mm');
      var duration = moment.duration(end.diff(start));
      var hours = duration.asHours();
      $scope.offer.price = $scope.offer.payout * hours;
    };
  }
]);