bovoApp.
factory('PriceList', ['$http', function($http) {
    return {
      all: function() {
        return $http.get('api/price-lists');
      },
      get: function(id) {
        return $http.get('api/price-list/' + id);
      },
      new: function(id) {
        return $http.get('api/new-price-list');
      },
      delete: function(id) {
        return $http.delete('api/delete-price-list/' + id);
      },
      save: function(data) {
        return $http.post('api/save-price-list',data);
      },
      update: function(data) {
        return $http.post('api/update-price-list',data);
      }
    };
}]);