bovoApp.controller('StationController', ['$scope', 'Station', '$location', '$routeParams', '$filter', '$translate', 'Address',
  function ($scope, Station, $location, $routeParams, $filter, $translate, Address) {

    $scope.deleted = [];

    $scope.type = 'station';
    if ($location.path() == '/area-management') {
      $scope.type = 'management'
    }

    var componentForm = {
      street_number: 'short_name',
      route: 'long_name',
      locality: 'long_name',
      administrative_area_level_1: 'short_name',
      administrative_area_level_2: 'short_name',
      country: 'long_name',
      postal_code: 'short_name'
    };
    var mapping = {
      street_number: 'number',
      route: 'street',
      locality: 'state',
      administrative_area_level_1: 'city',
      administrative_area_level_2: 'district',
      country: 'country',
      postal_code: 'zip'
    };

    $scope.all = function () {
      Station.all($scope.type).success(function (data, status, headers, config) {
        $scope.data = data;
      });
    }

    $scope.openDetail = function (item) {
      Station.get(item.id).success(function (data, status, headers, config) {
        $('#stationModal').modal('show');
        $scope.station = data.station;
        $scope.data.cities = data.cities;
        $scope.data.distritcs = data.distritcs;
        $scope.data.dataStations = data.stations;
      });
    }

    $scope.$on('new_data', function () {
      $('#stationModal').modal('show');
      $scope.new();
    }, true);

    $scope.new = function () {
      Station.new().success(function (data, status, headers, config) {
        $scope.station = {
          city: 'İstanbul',
          type: $scope.type
        };
        $scope.data.cities = data.cities;
        $scope.data.distritcs = data.distritcs;
        $scope.data.dataStations = data.stations;
      });
    }

    $scope.addressSelected = function (selected) {
      $scope.station.state = selected.originalObject[5];
      $scope.station.city = selected.originalObject[6];
      $scope.station.latitude = selected.originalObject[7];
      $scope.station.longitude = selected.originalObject[7];
      $scope.station.plate = selected.originalObject[9];
    }

    $scope.delete = function (type) {
      if (type == 'set') {
        $('#delete').modal('show');
      } else if (type == 'delete') {
        Station.delete($scope.deleted).success(function (data, status, headers, config) {
          Messenger().post($scope.translate(data.message.toString()));
          for (var i = $scope.deleted.length - 1; i >= 0; i--) {
            var index = $scope.data.stations.indexOf($filter('filter')($scope.data.stations, {
              id: $scope.deleted[i]
            })[0]);
            if (index > -1) {
              $scope.data.stations.splice(index, 1);
            }
          }
          $scope.deleted = [];
          $('#delete').modal('hide');
        }).error(function (data) {
          Messenger().post({
            message: data.message,
            type: "error"
          });
        });
      }
    }

    $scope.postForm = function (user) {
      if ($scope.station.id) {
        $scope.update();
      } else {
        $scope.save();
      }
    }

    $scope.save = function () {
      Station.save($scope.station).success(function (data, status, headers, config) {
        $scope.data = data;
        Messenger().post('Başarılı');
        $('#stationModal').modal('hide');
        $scope.all();
      }).error(function (data) {
        Messenger().post({
          message: data.message,
          type: "error"
        });
      });
    }

    $scope.update = function () {
      Station.update($scope.station).success(function (data, status, headers, config) {
        $scope.data = data;
        Messenger().post('Başarılı');
        $('#stationModal').modal('hide');
        $scope.all();
      }).error(function (data) {
        Messenger().post({
          message: data.message,
          type: "error"
        });
      });
    }

    $scope.toggleSelection = function toggleSelection(id) {
      var idx = $scope.deleted.indexOf(id);
      if (idx > -1) {
        $scope.deleted.splice(idx, 1);
      } else {
        $scope.deleted.push(id);
      }
    };

    $scope.addItem = function () {
      $scope.station.zipcodes.push({
        zipcode: null,
      });
    }

    $scope.removeItem = function (index) {
      $scope.station.zipcodes.splice(index, 1);
    }

  }
]);