bovoApp.
factory('Payment', ['$http', function($http) {
    return {
      init: function() {
        return $http.get('api/payments');
      },
      payment: function(data) {
        return $http.post('create-package',data);
      },
      requestOffer: function(data) {
        return $http.post('request-offer',data);
      }
    };
}]);