bovoApp.controller('CustomerSupportController', ['$scope', 'Support', '$location', '$routeParams', '$filter', '$translate',
    function ($scope, Support, $location, $routeParams, $filter, $translate) {

        $scope.support = {
            success: false,
            priorty: $translate.instant('LOW'),
            title: '',
            body: '',
        };

        $scope.showForm = function () {
            $scope.support.success = false;
        }


        $scope.save = function() {
            Support.save($scope.support).success(function(data, status, headers, config) {
                $scope.support = {
                    priorty: 'Low',
                    title: '',
                    body: '',
                    success: true
                };
                Messenger().post(data.message);
            }).error(function(data) {
                Messenger().post({ message: data.message, type: "error" });
            });
        }
    }
]);