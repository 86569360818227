bovoApp.controller('ChatController', ['$scope', 'Chat', '$location', '$routeParams', '$filter', 'ngAudio', '$rootScope', 'FileUploader',
  function ($scope, Chat, $location, $routeParams, $filter, ngAudio, $rootScope, FileUploader) {
    $scope.deleted = [];
    $scope.beepSound = ngAudio.load("sounds/beep.mp3");
    $scope.filterData = {
      term: null
    }
    $scope.clearFilter = function (page) {
      $scope.filterData = {
        term: null
      }
      $scope.all();
    }

    $rootScope.messageCount = 0;

    $scope.conversation = null
    $scope.newConversation = {}
    $scope.formData = {
      message: null
    };

    $scope.$on('refresh_data', function () {
      console.log('refreshing map...')
      $scope.all();
    }, true);

    $scope.$on('new_data', function () {
      $scope.conversation = {};
      $scope.newConversation = {}
      $scope.new();
      $('#newChat').modal('show');
    }, true);

    $scope.$on('new_message', function (event, data, nextParams, fromState) {
      $scope.beepSound.play();
      var convIndex = $scope.data.conversations.findIndex(function(item) {
        return item.id == data.message.conversation_id;
      });
      console.log('convIndex : ' + convIndex);

      if (convIndex > -1) {
        
        if ($scope.data.conversations[convIndex].new_message) {
          $scope.data.conversations[convIndex].new_message += 1;
        } else {
          $scope.data.conversations[convIndex].new_message = 1;
        }

        if ($scope.conversation && $scope.conversation.id == $scope.data.conversations[convIndex].id) {
          $scope.conversation.messages.push(data.message)
          window.scrollTo(0,document.querySelector(".messages").scrollHeight);
          // $(".messages").animate({ scrollTop: $(document).height() }, 1000);
          $scope.conversation.last_message = data.message;
        } else {
          $scope.data.conversations[convIndex].last_message = data.message;
        }
      } else {
        $scope.all();
      }
      // $scope.refreshOrders();
    });


    function find(data, find) {
      var convIndex = data.findIndex(function(item) {
        return item.id == find;
      });
      if (convIndex > -1)
        return convIndex;
    }

    $scope.getPage = function (page) {
      $scope.filterData.page = page;
      $scope.all();
    }

    $scope.all = function () {
      Chat.all($scope.filterData).success(function (data, status, headers, config) {
        $scope.data = data;
        if ($scope.data.conversations && $scope.data.conversations.length > 0) {
          $scope.get($scope.data.conversations[0].id);
        }
      });
    }

    $scope.editChat = function (conversation) {
      $scope.conversation = conversation;
      $('#newChat').modal('show');
      $('#conversationDetail').modal('hide');
      Chat.new().success(function (data, status, headers, config) {
        $scope.stations = data.stations;
        jQuery('#date').datetimepicker({
          timepicker: false,
          datepicker: true,
          format: 'Y-m-d'
        });
        jQuery('#start').datetimepicker({
          datepicker: false,
          allowTimes: $scope.allowTimes,
          format: 'H:i'
        });
        jQuery('#end').datetimepicker({
          datepicker: false,
          allowTimes: $scope.allowTimes,
          format: 'H:i'
        })
      });
    }

    $scope.get = function (id) {
      Chat.get(id).success(function (data, status, headers, config) {
        $scope.conversation = data.conversation;
        setTimeout(() => {
          if (document.getElementById("messageInput")) {
            document.getElementById("messageInput").focus();
          }
        }, 600);

        var index = find($scope.data.conversations, id);
        if (index > -1) {
          $scope.data.conversations[index].new_message = 0
        }
      });
    }

    $scope.new = function () {
      Chat.new().success(function (data, status, headers, config) {
        $scope.workers = data.workers;
      });
    }

    $scope.delete = function (type) {
      if (type == 'set') {
        $('#delete').modal('show');
      } else if (type == 'delete') {
        Chat.delete($scope.deleted).success(function (data, status, headers, config) {
          Messenger().post(data.message);
          for (var i = $scope.deleted.length - 1; i >= 0; i--) {
            var index = $scope.data.conversations.data.indexOf($filter('filter')($scope.data.conversations.data, {
              id: $scope.deleted[i]
            })[0]);
            if (index > -1)
              $scope.data.conversations.data.splice(index, 1);
          }
          $scope.deleted = [];
          $('#delete').modal('hide');
        }).error(function (data) {
          Messenger().post({
            message: data.message,
            type: "error"
          });
        });
      }
    }

    $scope.sendMessage = function (chatMessage) {
      $scope.formData.conversation_id = $scope.conversation.id;
      Chat.sendMessage($scope.formData).success(function (data, status, headers, config) {
        data.new = true;
        $scope.conversation.messages.push(data)
        $scope.formData = {
          message: null,
          file: null,
          conversation_id: $scope.conversation.id
        }
        // $(".messages").animate({ scrollTop: $('.messages').height() }, 1000);
        window.scrollTo(0,document.querySelector(".messages").scrollHeight);
      }).error(function (data) {
        Messenger().post({
          message: data.message,
          type: "error"
        });
      });
    }

    $scope.save = function () {
      if ($scope.conversation.id) {
        $scope.update();
      } else {
        Chat.save($scope.newConversation).success(function (data, status, headers, config) {
          $scope.data = data;
          Messenger().post(data.message);
          $('#newChat').modal('hide');
          $scope.all();
        }).error(function (data) {
          Messenger().post({
            message: data.message,
            type: "error"
          });
        });
      }
    }

    $rootScope.chatFile = new FileUploader({
      url: '/api/upload-chat-file',
      headers: { 'X-CSRF-TOKEN': CSRF_TOKEN },
      autoUpload: true
    });

    $rootScope.chatFile.filters.push({
      name: 'formatFilter',
      fn: function (item /*{File|FileLikeObject}*/, options) {
        var type = '|' + item.type.slice(item.type.lastIndexOf('/') + 1) + '|';
        return '|pdf|docx|doc|jpg|png|jpeg|bmp|gif|'.indexOf(type) !== -1;
      }
    });

    $rootScope.chatFile.onAfterAddingFile = function (fileItem) {
      $rootScope.proccessProfile = true;
      $scope.pending = true;
    };

    $rootScope.chatFile.onCompleteItem = function (item, response, status, headers) {
      $rootScope.proccessProfile = false;
      $scope.formData.file = response.file;
      if (response.status == 'success')
        $scope.pending = false;
    }
  }
]);