var bovoApp = angular.module('bovoApp', [
  'ngRoute',
  'ngSanitize',
  'ngResource',
  'ngStorage',
  'angularFileUpload',
  'angularMoment',
  'ngAudio',
  'chart.js',
  'gavruk.card',
  'ui.mask',
  'angucomplete-alt',
  'angular-loading-bar',
  'ui.select',
  'google.places',
  'enterStroke',
  'fsCounter',
  'convertToNumber',
  'uploadDocument',
  'cut',
  'toastr',
  'angular-tour',
  'ui.sortable',
  'daterangepicker',
  'ngIdle',
  'mgo-angular-wizard',
  'angularUtils.directives.dirPagination',
  'mm.acl',
  angularDragula(angular),
  'mwl.calendar',
  'ui.bootstrap',
  'angularCancelOnNavigateModule',
  'bootstrapLightbox',
  'xeditable',
  'monospaced.qrcode',
  'textAngular',
  'pascalprecht.translate',
  'angularjs-gauge',
  'zingchart-angularjs',
  'ui.grid', 'ui.grid.edit', 'ui.grid.resizeColumns', 'ui.grid.pagination', 'ui.grid.selection', 'ui.grid.exporter'
])

  .config(['TitleProvider', 'IdleProvider', function (TitleProvider, IdleProvider) {
    IdleProvider.idle(3600);
    TitleProvider.enabled(false);
  }])

  .config(['calendarConfig', function (calendarConfig) {
    calendarConfig.showTimesOnWeekView = true;
    calendarConfig.dateFormatter = 'moment'; // use moment to format dates
  }])

  .filter("trust", ['$sce', function ($sce) {
    return function (htmlCode) {
      return $sce.trustAsHtml(htmlCode);
    }
  }])

  .run(['Idle', 'editableOptions', function (Idle, editableOptions) {
    Idle.watch();
    editableOptions.theme = 'bs3';
    editableOptions.icon_set = 'font-awesome';
  }])


  .config(function (uiSelectConfig, toastrConfig, cfpLoadingBarProvider) {
    toastrConfig.closeButton = true;
    toastrConfig.progressBar = true;
    toastrConfig.autoDismiss = false;

    toastrConfig.positionClass = 'toast-bottom-right';
    uiSelectConfig.theme = 'select2';

    cfpLoadingBarProvider.includeSpinner = true;
    cfpLoadingBarProvider.includeBar = false;
    //cfpLoadingBarProvider.spinnerTemplate = '<div id="global_loader" class="glb-ldr-prt"><div class="gl-ldr-cld"><div class="gl-ldr-ctr"><div class="gl-loader"></div></div></div></div>';
  })

  .filter("trust", ['$sce', function ($sce) {
    return function (htmlCode) {
      return $sce.trustAsHtml(htmlCode);
    }
  }])

  .filter('trustThisUrl', ["$sce", function ($sce) {
    return function (val) {
        return $sce.trustAsResourceUrl(val);
    };
  }])

  .directive("formatDate", function () {
    return {
      require: 'ngModel',
      link: function (scope, elem, attr, modelCtrl) {
        modelCtrl.$formatters.push(function (modelValue) {
          return new Date(modelValue);
        })
      }
    }
  })
   
  .directive('myCustomDropdown', function() {
    return {
      template: '<select class="form-control" style="height: 24px;" ng-model="colFilter.term" ng-options="option.id as option.value for option in colFilter.options"></select>'
    };
  })

  .directive(
    'dateInput',
    function (dateFilter) {
      return {
        require: 'ngModel',
        template: '<input type="date"></input>',
        replace: true,
        link: function (scope, elm, attrs, ngModelCtrl) {
          ngModelCtrl.$formatters.unshift(function (modelValue) {
            return dateFilter(modelValue, 'yyyy-MM-dd');
          });

          ngModelCtrl.$parsers.unshift(function (viewValue) {
            return new Date(viewValue);
          });
        },
      };
    })
  .filter('tel', function () {
    return function (tel) {

      var cleaned = ('' + tel).replace(/\D/g, '')
      var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
      if (match) {
        var intlCode = (match[1] ? '+1 ' : '')
        return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
      }
      return null
    };
  })

  .directive('stringToNumber', function () {
    return {
      require: 'ngModel',
      link: function (scope, element, attrs, ngModel) {
        ngModel.$parsers.push(function (value) {
          return '' + value;
        });
        ngModel.$formatters.push(function (value) {
          return parseFloat(value, 10);
        });
      }
    };
  })

  .directive('uiSelectRequired', function () {
    return {
      require: 'ngModel',
      link: function (scope, elm, attrs, ctrl) {
        ctrl.$validators.uiSelectRequired = function (modelValue, viewValue) {
          return modelValue && modelValue.length;
        };
      }
    };
  })

  .filter('cut', function () {
    return function (value, wordwise, max, tail) {
      if (!value) return '';

      max = parseInt(max, 10);
      if (!max) return value;
      if (value.length <= max) return value;

      value = value.substr(0, max);
      if (wordwise) {
        var lastspace = value.lastIndexOf(' ');
        if (lastspace != -1) {
          //Also remove . and , so its gives a cleaner result.
          if (value.charAt(lastspace - 1) == '.' || value.charAt(lastspace - 1) == ',') {
            lastspace = lastspace - 1;
          }
          value = value.substr(0, lastspace);
        }
      }

      return value + (tail || ' …');
    };
  })

  .filter('propsFilter', function () {
    return function (items, props) {
      var out = [];

      if (angular.isArray(items)) {
        var keys = Object.keys(props);

        items.forEach(function (item) {
          var itemMatches = false;

          for (var i = 0; i < keys.length; i++) {
            var prop = keys[i];
            var text = props[prop].toLowerCase();
            if (item[prop].toString().toLowerCase().indexOf(text) !== -1) {
              itemMatches = true;
              break;
            }
          }

          if (itemMatches) {
            out.push(item);
          }
        });
      } else {
        // Let the output be the input untouched
        out = items;
      }

      return out;
    };
  })

  .filter('trusted', ['$sce', function ($sce) {
    return $sce.trustAsResourceUrl;
  }])

  .run(['$location', '$rootScope', function ($location, $rootScope) {
    $rootScope.$on('$routeChangeSuccess', function (event, current, previous) {
      if (current.hasOwnProperty('$$route')) {
        $rootScope.title = current.$$route.title;
      }
    });
  }])

  .directive("fileread", [
    function () {
      return {
        scope: {
          fileread: "="
        },
        link: function (scope, element, attributes) {
          element.bind("change", function (changeEvent) {
            var reader = new FileReader();
            reader.onload = function (loadEvent) {
              scope.$apply(function () {
                scope.fileread = loadEvent.target.result;
              });
            }
            reader.readAsDataURL(changeEvent.target.files[0]);
          });
        }
      }
    }
  ])

  .factory('socket', function ($rootScope) {
    var socket = io.connect(socket_url, { query: "token=" + user_token });
    return {
      on: function (eventName, callback) {
        socket.on(eventName, function () {
          var args = arguments;
          $rootScope.$apply(function () {
            callback.apply(socket, args);
          });
        });
      },
      emit: function (eventName, data, callback) {
        socket.emit(eventName, data, function () {
          var args = arguments;
          $rootScope.$apply(function () {
            if (callback) {
              callback.apply(socket, args);
            }
          });
        })
      },
      connect: function (data) {
        socket.disconnect();
        setTimeout(function () {
          socket = io.connect(socket_url, { query: "token=" + data });
        }, 500);
      }

    };
  })

  .directive('selectWatcher', function ($timeout) {
    return {
      link: function (scope, element, attr) {
        var last = attr.last;
        if (last === "true") {
          $timeout(function () {
            if ($(element) && $(element).parent()) {
              $(element).parent().selectpicker({
                iconBase: 'fa',
                tickIcon: 'fa-check'
              });
              $(element).parent().selectpicker('refresh');
            }

          });
        }
      }
    };
  })

  .directive('fixedTableHeaders', ['$timeout', function ($timeout) {
    return {
      restrict: 'A',
      link: function (scope, element, attrs) {
        $timeout(function () {

          container = element.parentsUntil(attrs.fixedTableHeaders);
          element.stickyTableHeaders({ scrollableArea: container, "fixedOffset": 0 });

        }, 0);
      }
    }
  }])

  .directive('scrollToBottom', function ($timeout, $window) {
    return {
      scope: {
        scrollToBottom: "="
      },
      restrict: 'A',
      link: function (scope, element, attr) {
        scope.$watchCollection('scrollToBottom', function (newVal) {
          if (newVal) {
            $timeout(function () {
              element[0].scrollTop = element[0].scrollHeight;
            }, 0);
          }

        });
      }
    };
  })

  .directive('ngEnter', function () {
    return function (scope, element, attrs) {
      element.bind("keydown keypress", function (event) {
        if (event.which === 13) {
          scope.$apply(function () {
            scope.$eval(attrs.ngEnter);
          });
          event.preventDefault();
        }
      });
    };
  })

  .directive('focusMe', ['$timeout', '$parse', function ($timeout, $parse) {
    return {
      //scope: true,   // optionally create a child scope
      link: function (scope, element, attrs) {
        var model = $parse(attrs.focusMe);
        scope.$watch(model, function (value) {
          console.log('value=', value);
          if (value === true) {
            $timeout(function () {
              element[0].focus();
            });
          }
        });
        // to address @blesh's comment, set attribute value to 'false'
        // on blur event:
        element.bind('blur', function () {
          console.log('blur');
          // scope.$apply(model.assign(scope, false));
        });
      }
    };
  }])

  .run(['AclService', function (AclService) {
    var abilities = {
      user: []
    };
    if (!AclService.resume()) {
      AclService.setAbilities(abilities);
      AclService.attachRole('user');
    }
  }])

  .filter('highlightText', ['$sce', function ($sce) {
    return function (input, match, className) {
      var output = $sce.valueOf(input);
      if (match) {
        output = output.replace(new RegExp(match, 'gi'), '<span id="highlighted" class="' + (className || 'highlighted') + '">$&</span>');
      }
      return $sce.trustAsHtml(output);
    };
  }])

  .filter('numberFormat', ['$sce', function ($sce) {
    return function (input, match, className) {
      return new Intl.NumberFormat('tr-TR', { maximumSignificantDigits: 3 }).format(input)
    };
  }])



  .directive('highlightText', ['$filter', function ($filter) {
    return {
      restrict: 'A',
      scope: { highlightText: '=' },
      link: function (scope, element, attrs) {
        scope.$watch('highlightText', function (options) {
          return element.html($filter('highlightText')(options.text, options.match, attrs.highlightClass));
        });
      }
    };
  }])

  .directive('scrollIf', function ($document) {
    return function (scope, element, attributes) {
      setTimeout(function () {
        if (scope.$eval(attributes.scrollIf)) {
          if ($('#highlighted').length == 0) {
            return;
          }
          $('#scrollDiv').animate({
            scrollTop: $('#highlighted').offset().top - 140
          }, 100);
        }
      });
    }
  })

  .factory('httpInterceptor', ['$q', '$rootScope',
    function ($q, $rootScope) {
      var loadingCount = 0;

      return {
        request: function (config) {
          if (++loadingCount === 1) $rootScope.$broadcast('loading:progress');
          return config || $q.when(config);
        },

        response: function (response) {
          if (--loadingCount === 0) $rootScope.$broadcast('loading:finish');
          return response || $q.when(response);
        },

        responseError: function (response) {
          if (--loadingCount === 0) $rootScope.$broadcast('loading:finish');
          return $q.reject(response);
        }
      };
    }
  ])
  .config(['$httpProvider', function ($httpProvider) {
    $httpProvider.interceptors.push('httpInterceptor');
  }])

  .config(function (LightboxProvider) {
    LightboxProvider.fullScreenMode = true;
    LightboxProvider.getImageUrl = function (image) {
      return 'https://s3.eu-central-1.amazonaws.com/bovo/' + image.url;
    };

    LightboxProvider.getImageCaption = function (image) {
      return image.created_at;
    };
  });

var maps = {
  'acl': ['$q', 'AclService', '$location', 'User', function ($q, AclService, $location, User) {
    /*User.getUser().success(function(data, status, headers, config) {
      if(data.authorization.maps) true;
      
      return $q.reject('Unauthorized');		
    });
    */
    return true;
  }]
},
  office = {
    'acl': ['$q', 'AclService', '$location', 'User', function ($q, AclService, $location, User) {
      /*User.getUser().success(function(data, status, headers, config) {
        if(data.authorization.office) true;
        
        return $q.reject('Unauthorized');
      });
      */
      return true;
    }]
  },
  customers = {
    'acl': ['$q', 'AclService', '$location', 'User', function ($q, AclService, $location, User) {
      /*User.getUser().success(function(data, status, headers, config) {
        if(data.authorization.customers) true;
        
        return $q.reject('Unauthorized');				
      });
      */
      return true;
    }]
  },
  users = {
    'acl': ['$q', 'AclService', '$location', 'User', function ($q, AclService, $location, User) {
      /*User.getUser().success(function(data, status, headers, config) {
        if(data.authorization.users) true;
        
        return $q.reject('Unauthorized');								
      });
      */
      return true;
    }]
  },
  settings = {
    'acl': ['$q', 'AclService', '$location', 'User', function ($q, AclService, $location, User) {
      /*User.getUser().success(function(data, status, headers, config) {
        if(data.authorization.settings) true;
        
        return $q.reject('Unauthorized');							
      });
      */
      return true;
    }]
  },
  reports = {
    'acl': ['$q', 'AclService', '$location', 'User', function ($q, AclService, $location, User) {
      /*User.getUser().success(function(data, status, headers, config) {
        if(data.authorization.reports) true;
        
        return $q.reject('Unauthorized');											
      });
      */
      return true;
    }]
  },
  stocks = {
    'acl': ['$q', 'AclService', '$location', 'User', function ($q, AclService, $location, User) {
      /*User.getUser().success(function(data, status, headers, config) {
        if(data.authorization.reports) true;
        
        return $q.reject('Unauthorized');											
      });
      */
      return true;
    }]
  },
  customer = {
    'acl': ['$q', 'AclService', '$location', function ($q, AclService, $location) {
      return true;
    }]
  };

moment.tz.setDefault("Europe/Istanbul");