bovoApp.controller('FailOrderController', ['$scope', 'FailOrder', '$location', '$routeParams', '$filter', '$translate', 'ngAudio',
	function ($scope, FailOrder, $location, $routeParams, $filter, $translate, ngAudio) {
		$scope.deleted = [];
		var successSound = ngAudio.load("sounds/success.mp3");
		var errorSound = ngAudio.load("sounds/error.mp3");

		$scope.order_id = null;

		$scope.failOrder = {
			orders: []
		}

		$scope.filterData = {
			station_id: [],
			price: '',
			vehicle: [],
			page: 1,
			date: {
				startDate: null,
				endDate: null
			}
		}

		$scope.clearFilter = function (page) {
			$scope.filterData = {
				station_id: [],
				price: '',
				vehicle: [],
				page: 1,
				date: {
					startDate: null,
					endDate: null
				}
			}
			$scope.all();
		}

		$scope.remove = function (index) {
			$scope.failOrder.orders.splice(index, 1);
		}

		$scope.selectAll = function (select) {
			if (select) {
				$scope.deleted = [];
				for (let i = 0; i < $scope.data.failOrders.data.length; i++) {
					$scope.deleted.push($scope.data.failOrders.data[i].id);
				}
			} else {
				$scope.deleted = [];
			}
		};

		$scope.$on('refresh_data', function () {
			$scope.all();
		}, true);

		$scope.$on('new_data', function () {
			$('#newFailOrder').modal('show');
			$scope.new();
		}, true);

		$scope.getPage = function (page) {
			$scope.filterData.page = page;
			$scope.all();
		}

		$scope.all = function () {
			FailOrder.all($scope.filterData).success(function (data, status, headers, config) {
				$scope.data = data;
			});
		}

		$scope.send = function () {
			if (!$scope.company_customer_id && !$scope.customer_group_id) {
				alert('Lütfen müşteri veya müşteri grubu seçiniz!');
				return;
			}
			var data = {
				order_id: $scope.order_id,
				company_customer_id: $scope.company_customer_id,
				customer_group_id: $scope.customer_group_id,
			};
			if ($scope.failOrder.id) {
				data.id = $scope.failOrder.id
			}
			FailOrder.addOrder(data).success(function (data, status, headers, config) {
				if ($scope.company_customer_id) {
					if (data.order.company_customer_id != $scope.company_customer_id) {
						errorSound.play()
						Messenger().post({
							message: "Bu Kargo Seçilen Müşteriye Ait Değildir! Sadece seçilen müşteriye ait kargoları ekleyebilirsiniz.",
							type: "error"
						});
						return;
					}
				} else if ($scope.customer_group_id) {
					if (data.order.customer.group && data.order.customer.group.map(i => i.id).indexOf(Number($scope.customer_group_id)) < 0) {
						errorSound.play()
						Messenger().post({
							message: "Bu Kargo Seçilen Müşteriye Ait Değildir! Sadece seçilen müşteriye ait kargoları ekleyebilirsiniz.",
							type: "error"
						});
						return;
					}
				}

				$scope.failOrder.orders.push(data.order)
				$scope.order_id = null;
				successSound.play()
			}).error(function (data) {
				Messenger().post({
					message: data.message,
					type: "error"
				});
			});
		};

		$scope.init = function () {
			if ($routeParams.id) {
				$scope.get($routeParams.id);
			}
		}

		$scope.initApprove = function () {
			if ($routeParams.id) {
				$scope.get($routeParams.id);
			}
		}

		$scope.get = function (id) {
			FailOrder.get(id).success(function (data, status, headers, config) {
				$scope.failOrder = data.failOrder;
				$scope.company_customer_id = String(data.failOrder.company_customer_id)
				$scope.customer_group_id = String(data.failOrder.customer_group_id)
				$scope.waybill_number = String(data.failOrder.waybill_number)
			});
		}

		$scope.delete = function (type) {
			if (type == 'set') {
				$('#delete').modal('show');
			} else if (type == 'delete') {
				FailOrder.delete($scope.deleted).success(function (data, status, headers, config) {
					Messenger().post('Silindi');
					for (var i = $scope.deleted.length - 1; i >= 0; i--) {
						var index = $scope.data.failOrders.data.indexOf($filter('filter')($scope.data.failOrders.data, {
							id: $scope.deleted[i]
						})[0]);
						if (index > -1)
							$scope.data.failOrders.data.splice(index, 1);
					}
					$scope.deleted = [];
					$('#delete').modal('hide');
				}).error(function (data) {
					Messenger().post({
						message: data.message,
						type: "error"
					});
				});
			}
		}

		$scope.save = function (force) {
			if (!force) {
				var warning = false;
				$scope.failOrder.orders.forEach(element => {
					if (!element.status || (element.status && element.status.value != 'fail' && element.status.value != 'refund')) {
						warning = true;
					}
				});
				if (warning) {
					$('#warning').modal('show');
					return;
				}
			}

			if ($scope.failOrder.id) {
				$scope.update();
			} else {
				$scope.failOrder.company_customer_id = $scope.company_customer_id;
				$scope.failOrder.customer_group_id = $scope.customer_group_id;
				// $scope.failOrder.waybill_number = $scope.waybill_number;
				FailOrder.save($scope.failOrder).success(function (data, status, headers, config) {
					$scope.failOrder = data.data;
					$scope.company_customer_id = data.data.company_customer_id
					$scope.customer_group_id = data.data.customer_group_id
					$location.path('fail-order/create').search('id', data.data.id);
					$('#warning').modal('hide');
					Messenger().post('Kaydedildi');
				}).error(function (data) {
					Messenger().post({
						message: data.message,
						type: "error"
					});
				});
			}
		}

		$scope.update = function () {
			$scope.failOrder.company_customer_id = $scope.company_customer_id;
			$scope.failOrder.customer_group_id = $scope.customer_group_id;
			FailOrder.update($scope.failOrder).success(function (data, status, headers, config) {
				Messenger().post('Güncellendi');
				$('#warning').modal('hide');
			}).error(function (data) {
				Messenger().post({
					message: data.message,
					type: "error"
				});
			});
		}

		$scope.approve = function () {
			let isExecuted = confirm("İade teslimini onaylıyor musunuz?");
			if (isExecuted) {
				FailOrder.approve($scope.failOrder).success(function (data, status, headers, config) {
					Messenger().post('Güncellendi');
					$scope.failOrderSuccess = true;
				}).error(function (data) {
					Messenger().post({
						message: data.message,
						type: "error"
					});
				});
			}

		}

		$scope.export = function () {
			FailOrder.export($scope.failOrder).success(function (data, status, headers, config) {
				window.location.href = data.url;
			}).error(function (data) {
				Messenger().post({
					message: data.message,
					type: "error"
				});
			});
		}

		$scope.openDetail = function (data) {
			$location.path('fail-order/create').search('id', data.id);
		}

		$scope.print = function (data) {
			window.open('/print-fail-order/' + data.id, '_blank');
		}

		$scope.toggleSelection = function toggleSelection(id) {
			var idx = $scope.deleted.indexOf(id);
			if (idx > -1) {
				$scope.deleted.splice(idx, 1);
			} else {
				$scope.deleted.push(id);
			}
		};

	}
]);