bovoApp.controller('UserController', ['$scope', 'UserManager', '$location', '$filter', '$routeParams',
  function ($scope, UserManager, $location, $filter, $routeParams) {
    $scope.deleted = [];

    $scope.filterData = {
      permissions: null
    }

    $scope.initUser = function () {
      UserManager.init($scope.filterData).success(function (data, status, headers, config) {
        $scope.data = data;
        if ($routeParams.id) {
          $scope.show();
        }
      });
    }

    $scope.refreshData = function (page) {
      $scope.initUser();
    }

    $scope.clearFilter = function (page) {
      $scope.filterData = {
        permissions: null
      }
      $scope.initUser();
    }

    $scope.show = function () {
      UserManager.show($routeParams.id).success(function (data, status, headers, config) {
        $scope.systemUser = data.user;
        if ($routeParams.id) {
          $scope.openUserDetail($scope.systemUser);
        }
      });
    }

    $scope.$on('new_data', function () {
      $scope.addUser();
      $('#userModal').modal('show');
    }, true);

    $scope.$on('init_user', function () {
      $scope.initUser();
    }, true);

    $scope.postForm = function (user) {
      if ($scope.systemUser.id) {
        $scope.updateUser();
      } else {
        $scope.saveUser();
      }
    }

    $scope.saveUser = function () {
      UserManager.saveUser($scope.systemUser).success(function (data, status, headers, config) {
        Messenger().post(data.message);
        $('#userModal').modal('hide');
        $scope.initUser();
      }).error(function (data) {
        Messenger().post({
          message: data.message,
          type: "error"
        });
      });
    }

    $scope.updateUser = function () {
      UserManager.updateUser($scope.systemUser).success(function (data, status, headers, config) {
        Messenger().post(data.message);
        $('#userModal').modal('hide');
        $scope.initUser();
      }).error(function (data) {
        Messenger().post({
          message: data.message,
          type: "error"
        });
      })
    }

    $scope.delete = function (type) {
      if (type == 'set') {
        $('#delete').modal('show');
      } else if (type == 'delete') {
        UserManager.deleteUser($scope.deleted).success(function (data, status, headers, config) {
          Messenger().post(data.message);
          for (var i = $scope.deleted.length - 1; i >= 0; i--) {
            var index = $scope.data.users.data.indexOf($filter('filter')($scope.data.users.data, {
              id: $scope.deleted[i]
            })[0]);
            if (index > -1)
              $scope.data.users.data.splice(index, 1);
          };
          $scope.deleted = [];
          $('#delete').modal('hide');
        }).error(function (data) {
          Messenger().post({
            message: data.message,
            type: "error"
          });
        })
      }
    }

    $scope.getNumber = function (num) {
      return new Array(num);
    }

    $scope.toggleSelection = function toggleSelection(id) {
      var idx = $scope.deleted.indexOf(id);
      if (idx > -1) {
        $scope.deleted.splice(idx, 1);
      } else {
        $scope.deleted.push(id);
      }
    }

  }
]);