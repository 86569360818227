bovoApp.controller('LineVehicleContoller', ['$scope', 'LineVehicle', '$location', '$routeParams', '$filter', '$translate',
  function ($scope, LineVehicle, $location, $routeParams, $filter, $translate) {
    $scope.deleted = [];

    $scope.type = 'line';
    if ($location.path() == '/route-vehicles') {
      $scope.type = 'route'
    }

    $scope.all = function () {
      LineVehicle.all($scope.type).success(function (data, status, headers, config) {
        $scope.data = data;
      });
    }

    $scope.openDetail = function (item) {
      LineVehicle.get(item.id).success(function (data, status, headers, config) {
        $('#lineVehicleModal').modal('show');
        $scope.lineVehicle = data.lineVehicle;
        $scope.data.vehicleRoutes = data.vehicleRoutes;
        var routes = $scope.lineVehicle.vehicle_routes;
        $scope.lineVehicle.vehicle_routes = [];
        routes.forEach(element => {
          $scope.lineVehicle.vehicle_routes.push({
            vehicle_route_id: element.id
          });
        });
      });
    }

    $scope.$on('new_data', function () {
      $('#lineVehicleModal').modal('show');
      $scope.new();
    }, true);

    $scope.new = function () {
      LineVehicle.new().success(function (data, status, headers, config) {
        $scope.data.vehicleRoutes = data.vehicleRoutes;
        $scope.lineVehicle = {
          type: $scope.type,
          vehicle_routes: []
        };
      });
    }

    $scope.addVehicleRoute = function () {
      $scope.lineVehicle.vehicle_routes.push({
        vehicle_route_id: null,
      });
    }

    $scope.removeVehicleRoute = function (index) {
      $scope.lineVehicle.vehicle_routes.splice(index, 1);
    }

    $scope.delete = function (type) {
      if (type == 'set') {
        $('#delete').modal('show');
      } else if (type == 'delete') {
        LineVehicle.delete($scope.deleted).success(function (data, status, headers, config) {
          Messenger().post($scope.translate(data.message.toString()));
          for (var i = $scope.deleted.length - 1; i >= 0; i--) {
            var index = $scope.data.lineVehicles.indexOf($filter('filter')($scope.data.lineVehicles, {
              id: $scope.deleted[i]
            })[0]);
            if (index > -1) {
              $scope.data.lineVehicles.splice(index, 1);
            }
          }
          $scope.deleted = [];
          $('#delete').modal('hide');
        }).error(function (data) {
          Messenger().post({
            message: data.message,
            type: "error"
          });
        });
      }
    }

    $scope.postForm = function (user) {
      if ($scope.lineVehicle.id) {
        $scope.update();
      } else {
        $scope.save();
      }
    }

    $scope.save = function () {
      LineVehicle.save($scope.lineVehicle).success(function (data, status, headers, config) {
        $scope.data = data;
        Messenger().post('Başarılı');
        $('#lineVehicleModal').modal('hide');
        $scope.all();
      }).error(function (data) {
        Messenger().post({
          message: data.message,
          type: "error"
        });
      });
    }

    $scope.update = function () {
      LineVehicle.update($scope.lineVehicle).success(function (data, status, headers, config) {
        $scope.data = data;
        Messenger().post('Başarılı');
        $('#lineVehicleModal').modal('hide');
        $scope.all();
      }).error(function (data) {
        Messenger().post({
          message: data.message,
          type: "error"
        });
      });
    }

    $scope.toggleSelection = function toggleSelection(id) {
      var idx = $scope.deleted.indexOf(id);
      if (idx > -1) {
        $scope.deleted.splice(idx, 1);
      } else {
        $scope.deleted.push(id);
      }
    };

  }
]);