bovoApp.
factory('VehicleRoute', ['$http', function($http) {
    return {
      all: function() {
        return $http.get('api/vehicle-routes');
      },
      get: function(id) {
        return $http.get('api/vehicle-route/' + id);
      },
      new: function(id) {
        return $http.get('api/new-vehicle-route');
      },
      delete: function(id) {
        return $http.delete('api/delete-vehicle-route/' + id);
      },
      save: function(data) {
        return $http.post('api/save-vehicle-route',data);
      },
      update: function(data) {
        return $http.post('api/update-vehicle-route',data);
      }
    };
}]);