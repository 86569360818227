bovoApp.
  factory('OffZone', ['$http', function ($http) {
    return {
      offZones: function (data) {
        return $http.post('api/off-zones', data);
      },
      importOffZones: function (data) {
        return $http.post('api/import-off-zones', data);
      },
      updateOffZones: function (data) {
        return $http.post('api/update-off-zones', data);
      },
      saveOffZones: function (data) {
        return $http.post('api/save-off-zones', data);
      },
    };
  }]);