bovoApp.controller('TransferReasonController', ['$scope', 'TransferReason', '$location', '$routeParams', '$filter', '$translate',
  function ($scope, TransferReason, $location, $routeParams, $filter, $translate) {
    $scope.deleted = [];

    $scope.type = 'transfer';
    if ($location.path() == '/redirect-reasons') {
      $scope.type = 'redirect'
    }else if ($location.path() == '/completed-reasons') {
      $scope.type = 'completed'
    }

    $scope.all = function () {
      TransferReason.all($scope.type).success(function (data, status, headers, config) {
        $scope.data = data;
      });
    }

    $scope.openDetail = function (item) {
      TransferReason.get(item.id).success(function (data, status, headers, config) {
        $('#transferReasonModal').modal('show');
        $scope.transferReason = data.transferReason;
      });
    }

    $scope.$on('new_data', function () {
      $('#transferReasonModal').modal('show');
      $scope.new();
    }, true);

    $scope.new = function () {
      TransferReason.new().success(function (data, status, headers, config) {
        $scope.data.vehicleRoutes = data.vehicleRoutes;
        $scope.transferReason = {
          type: $scope.type,
          performance: 'no'
        };
      });
    }

    $scope.delete = function (type) {
      if (type == 'set') {
        $('#delete').modal('show');
      } else if (type == 'delete') {
        TransferReason.delete($scope.deleted).success(function (data, status, headers, config) {
          Messenger().post($scope.translate(data.message.toString()));
          for (var i = $scope.deleted.length - 1; i >= 0; i--) {
            var index = $scope.data.transferReasons.indexOf($filter('filter')($scope.data.transferReasons, {
              id: $scope.deleted[i]
            })[0]);
            if (index > -1) {
              $scope.data.transferReasons.splice(index, 1);
            }
          }
          $scope.deleted = [];
          $('#delete').modal('hide');
        }).error(function (data) {
          Messenger().post({
            message: data.message,
            type: "error"
          });
        });
      }
    }

    $scope.postForm = function (user) {
      if ($scope.transferReason.id) {
        $scope.update();
      } else {
        $scope.save();
      }
    }

    $scope.save = function () {
      TransferReason.save($scope.transferReason).success(function (data, status, headers, config) {
        $scope.data = data;
        Messenger().post('Başarılı');
        $('#transferReasonModal').modal('hide');
        $scope.all();
      }).error(function (data) {
        Messenger().post({
          message: data.message,
          type: "error"
        });
      });
    }

    $scope.update = function () {
      TransferReason.update($scope.transferReason).success(function (data, status, headers, config) {
        $scope.data = data;
        Messenger().post('Başarılı');
        $('#transferReasonModal').modal('hide');
        $scope.all();
      }).error(function (data) {
        Messenger().post({
          message: data.message,
          type: "error"
        });
      });
    }

    $scope.toggleSelection = function toggleSelection(id) {
      var idx = $scope.deleted.indexOf(id);
      if (idx > -1) {
        $scope.deleted.splice(idx, 1);
      } else {
        $scope.deleted.push(id);
      }
    };

  }
]);