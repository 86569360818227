bovoApp.controller('ShipmentAreaController', ['$scope', 'ShipmentArea', '$location', '$routeParams', '$filter', '$translate',
  function ($scope, ShipmentArea, $location, $routeParams, $filter, $translate) {
    $scope.deleted = [];
    
    $scope.all = function () {
      ShipmentArea.all().success(function (data, status, headers, config) {
        $scope.data = data;
      });
    }

    $scope.openDetail = function (item) {
      ShipmentArea.get(item.id).success(function (data, status, headers, config) {
        $scope.data.cities = data.cities;
        $scope.data.distritcs = data.distritcs;
        $scope.shipmentArea = data.shipmentArea;
        console.log($scope.shipmentArea);
        setTimeout(() => {
          $scope.$apply();
        }, 300);
        $('#shipmentAreaModal').modal('show');
      });
    }

    $scope.$on('new_data', function () {
      $('#shipmentAreaModal').modal('show');
      $scope.new();
    }, true);

    $scope.new = function () {
      ShipmentArea.new().success(function (data, status, headers, config) {
        $scope.shipmentArea = {
          districts: [{
            district_id: null
          }],
          city_id: null
        };
        $scope.data.cities = data.cities;
        $scope.data.distritcs = data.distritcs;
      });
    }

    $scope.addDistrict = function () {
      $scope.shipmentArea.districts.push({
        district_id: null
      });
    }

    $scope.removeDistrict = function (index) {
      $scope.shipmentArea.districts.splice(index, 1);
    }

    $scope.delete = function (type) {
      if (type == 'set') {
        $('#delete').modal('show');
      } else if (type == 'delete') {
        ShipmentArea.delete($scope.deleted).success(function (data, status, headers, config) {
          Messenger().post($scope.translate(data.message.toString()));
          for (var i = $scope.deleted.length - 1; i >= 0; i--) {
            var index = $scope.data.shipmentAreas.indexOf($filter('filter')($scope.data.shipmentAreas, {
              id: $scope.deleted[i]
            })[0]);
            if (index > -1) {
              $scope.data.shipmentAreas.splice(index, 1);
            }
          }
          $scope.deleted = [];
          $('#delete').modal('hide');
        }).error(function (data) {
          Messenger().post({
            message: data.message,
            type: "error"
          });
        });
      }
    }

    $scope.postForm = function (user) {
      if ($scope.shipmentArea.id) {
        $scope.update();
      } else {
        $scope.save();
      }
    }

    $scope.save = function () {
      ShipmentArea.save($scope.shipmentArea).success(function (data, status, headers, config) {
        $scope.data = data;
        Messenger().post('Başarılı');
        $('#shipmentAreaModal').modal('hide');
        $scope.all();
      }).error(function (data) {
        Messenger().post({
          message: data.message,
          type: "error"
        });
      });
    }

    $scope.update = function () {
      ShipmentArea.update($scope.shipmentArea).success(function (data, status, headers, config) {
        $scope.data = data;
        Messenger().post('Başarılı');
        $('#shipmentAreaModal').modal('hide');
        $scope.all();
      }).error(function (data) {
        Messenger().post({
          message: data.message,
          type: "error"
        });
      });
    }

    $scope.toggleSelection = function toggleSelection(id) {
      var idx = $scope.deleted.indexOf(id);
      if (idx > -1) {
        $scope.deleted.splice(idx, 1);
      } else {
        $scope.deleted.push(id);
      }
    };

  }
]);