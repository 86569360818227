bovoApp.
  factory('RouteList', ['$http', function ($http) {
    return {
      all: function (data) {
        return $http.post('api/routes', data);
      },
      get: function (id) {
        return $http.get('api/route/' + id);
      },
      new: function (id) {
        return $http.get('api/new-route');
      },
      delete: function (id) {
        return $http.delete('api/delete-route/' + id);
      },
      save: function (data) {
        return $http.post('api/save-route', data);
      },
      update: function (data) {
        return $http.post('api/update-route', data);
      },
      updateField: function (data) {
        return $http.post('api/update-route-field', data);
      },
      changeItem: function (data) {
        return $http.post('api/update-order-item', data);
      },
      readAll: function (data) {
        return $http.post('api/read-all', data);
      },
      routeActivity: function (data) {
        return $http.post('api/route-activity', data);
      },
      updateBarcode: function (data) {
        return $http.post('api/route-barcode', data);
      },
      totalOrder: function (data) {
        return $http.post('api/route/order/count', data);
      },
      oldRoutes: function (data) {
        return $http.post('api/old-routes', data);
      },
    };
  }]);