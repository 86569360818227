bovoApp.controller('RouteController', ['$scope', 'Route', 'Order', '$location', '$filter', '$interval',
	'ngAudio', '$routeParams', '$rootScope', '$window', '$http', '$timeout', '$http', 'RouteList', '$translate',
	function ($scope, Route, Order, $location, $filter, ngAudio, $routeParams, $rootScope, $window, $http, $interval, $timeout, $http, RouteList, $translate) {

		$scope.directionsService = [];
		$scope.directionsDisplay = [];
		$scope.selectedMarkers = [];
		$scope.selectedForEditOrder = [];
		$scope.editMode = false;
		var bounds = new google.maps.LatLngBounds();
		var colorArray = ['#FF6633', '#FF33FF', '#FFFF99', '#00B3E6',
			'#E6B333', '#3366E6', '#999966', '#99FF99', '#B34D4D',
			'#80B300', '#809900', '#E6B3B3', '#6680B3', '#66991A',
			'#FF99E6', '#CCFF1A', '#FF1A66', '#E6331A', '#33FFCC',
			'#66994D', '#B366CC', '#4D8000', '#B33300', '#CC80CC',
			'#66664D', '#991AFF', '#E666FF', '#4DB3FF', '#1AB399',
			'#E666B3', '#33991A', '#CC9999', '#B3B31A', '#00E680',
			'#4D8066', '#809980', '#E6FF80', '#1AFF33', '#999933',
			'#FF3380', '#CCCC00', '#66E64D', '#4D80CC', '#9900B3',
			'#E64D66', '#4DB380', '#FF4D4D', '#99E6E6', '#6666FF', '#FFB399'
		];

		$scope.filterPanel = false;
		$scope.data = {};
		$scope.worker_id = 0;
		$scope.selectedTab = 'routes';
		$scope.filterData = {
			workers: [],
			workSteps: [],
			created_at: {
				startDate: null,
				endDate: null
			},
			scheduled_time: {
				startDate: null,
				endDate: null
			}
		}

		$scope.getWorkerOrders = function (item) {
			return item.worker_id === $scope.sWorker.id;
		}

		$scope.searchOrder = function (term) {
			$scope.filterData.term = term;
			$scope.init();
		}

		$scope.init = function (type) {
			if (typeof type !== 'undefined' && type == 'clear') {
				$scope.filterData = {
					term: '',
					workers: [],
					workSteps: [],
					created_at: {
						startDate: null,
						endDate: null
					},
					scheduled_time: {
						startDate: null,
						endDate: null
					}
				}
			}
			Route.routeOrders($scope.filterData).success(function (data, status, headers, config) {
				$scope.data.orders = data.orders;
				$scope.markers = {
					'order': []
				};
				$scope.infowindows = [];
				$scope.infowindowsOrder = [];
				$scope.map = new google.maps.Map(document.getElementById('map'), {
					mapTypeId: google.maps.MapTypeId.ROADMAP,
					mapTypeControl: true,
					streetViewControl: true,
					rotateControl: true,
					scaleControl: true,
					fullscreenControl: true,
					styles: [{
						"elementType": "geometry",
						"stylers": [{
							"color": "#f5f5f5"
						}]
					},
					{
						"elementType": "labels.icon",
						"stylers": [{
							"visibility": "off"
						}]
					},
					{
						"elementType": "labels.text.fill",
						"stylers": [{
							"color": "#616161"
						}]
					},
					{
						"elementType": "labels.text.stroke",
						"stylers": [{
							"color": "#f5f5f5"
						}]
					},
					{
						"featureType": "administrative.land_parcel",
						"elementType": "labels.text.fill",
						"stylers": [{
							"color": "#bdbdbd"
						}]
					},
					{
						"featureType": "landscape.natural",
						"elementType": "geometry.stroke",
						"stylers": [{
							"color": "#4247bd"
						}]
					},
					{
						"featureType": "poi",
						"elementType": "geometry",
						"stylers": [{
							"color": "#eeeeee"
						}]
					},
					{
						"featureType": "poi",
						"elementType": "labels.text.fill",
						"stylers": [{
							"color": "#757575"
						}]
					},
					{
						"featureType": "poi.business",
						"stylers": [{
							"visibility": "off"
						}]
					},
					{
						"featureType": "poi.park",
						"elementType": "geometry",
						"stylers": [{
							"color": "#e5e5e5"
						}]
					},
					{
						"featureType": "poi.park",
						"elementType": "labels.text",
						"stylers": [{
							"visibility": "off"
						}]
					},
					{
						"featureType": "poi.park",
						"elementType": "labels.text.fill",
						"stylers": [{
							"color": "#9e9e9e"
						}]
					},
					{
						"featureType": "road",
						"elementType": "geometry",
						"stylers": [{
							"color": "#ffffff"
						}]
					},
					{
						"featureType": "road.arterial",
						"elementType": "labels.text.fill",
						"stylers": [{
							"color": "#757575"
						}]
					},
					{
						"featureType": "road.highway",
						"elementType": "geometry",
						"stylers": [{
							"color": "#dadada"
						}]
					},
					{
						"featureType": "road.highway",
						"elementType": "labels.text.fill",
						"stylers": [{
							"color": "#616161"
						}]
					},
					{
						"featureType": "road.local",
						"elementType": "labels.text.fill",
						"stylers": [{
							"color": "#9e9e9e"
						}]
					},
					{
						"featureType": "transit.line",
						"elementType": "geometry",
						"stylers": [{
							"color": "#e5e5e5"
						}]
					},
					{
						"featureType": "transit.station",
						"elementType": "geometry",
						"stylers": [{
							"color": "#eeeeee"
						}]
					},
					{
						"featureType": "water",
						"elementType": "geometry",
						"stylers": [{
							"color": "#c9c9c9"
						}]
					},
					{
						"featureType": "water",
						"elementType": "geometry.fill",
						"stylers": [{
							"color": "#bdd1e8"
						}]
					},
					{
						"featureType": "water",
						"elementType": "labels.text.fill",
						"stylers": [{
							"color": "#9e9e9e"
						}]
					}]
				});
				$scope.orderMarkers = [];
				for (var i = $scope.data.orders.length - 1; i >= 0; i--) {
					$scope.createOrderMarker($scope.data.orders[i], '#828c95');
					bounds.extend($scope.orderMarkers[$scope.data.orders[i].id].getPosition());
				}
				$scope.initRoute(type);
				$scope.map.fitBounds(bounds);
			});
		}

		$scope.initRoute = function (type) {
			Route.initRoute($scope.filterData).success(function (data, status, headers, config) {
				$scope.data.routes = data.routes;
				$scope.data.routes.forEach(element => {
					clearRoute(element.id);
				});
				setTimeout(() => {
					$scope.data.routes.forEach((element, index) => {
						renderRoute(element, colorArray[index], type);
						element.color = colorArray[index];
					});
				}, 100);

			});
		}

		$scope.assignToRoute = function (id) {
			var order = find($scope.data.orders, id);
			$scope.selectedForEditOrder.push(order);
		}

		function find(data, find) {
			var index = data.indexOf($filter('filter')(data, {
				id: find
			})[0]);
			if (index > -1)
				return data[index];
		}

		$scope.createOrderMarker = function (order, color) {
			if ($scope.orderMarkers.indexOf(order.id) > -1) {
				$scope.orderMarkers[order.id].setIcon(icon);
			} else if (order.customer) {
				var location = new google.maps.LatLng(Number(order.location.latitude), Number(order.location.longitude));

				var pinImage = {
					url: "/color-pin?color=" + color.substr(1),
					scaledSize: new google.maps.Size(36, 36),
				};

				var marker = new google.maps.Marker({
					position: location,
					map: $scope.map,
					icon: pinImage,
					title: "order_location",
					visible: true
				});

				var content = '<div id="iw-container">' +
					'<div class="iw-content"> ' +
					'<strong>#' + order.id + '</strong>';

				if (order.name && order.name != null)
					content += ' - ' + order.name;

				if (order.special_value)
					content += ' - <strong>' + order.special_value + '</strong>';

				content += '<hr style="margin:17px 0">' +
					'<p>' + order.customer.customer_name + ' </p>' +
					'<p>' + order.location.name + ' </p>' +
					'<p>' + order.location.address + '</p>' +
					'<div class="row"><div class="col-md-6 pull-left">' +
					'<a href="#" onclick="angular.element(this).scope().zoomIn(' + order.location.latitude + ',' + order.location.longitude + ')" class="bovo-button blue btn-xs"><i class="fa fa-search-plus"></i></a> </div>' +
					'<div class="col-md-6 pull-left"><a href="#" onclick="angular.element(this).scope().getOrderDetails(' + order.id + ')" class="bovo-button blue btn-xs pull-right"><i class="fa fa-eye"></i></a> </div></div>'

				if (order.worker)
					content += '<p>' + order.worker.full_name + '</p>';
				content += '</div></div>';
				var infowindow = new google.maps.InfoWindow({
					content: content,
					maxWidth: 360
				});
				marker.addListener('click', function () {
					infowindow.open($scope.map, marker);
				});

				$scope.orderMarkers[order.id] = marker;
				$scope.infowindowsOrder[order.id] = infowindow;
				bounds.extend(location);
			}
		}

		$scope.zoomIn = function (lat, lng) {
			$scope.map.panTo(new google.maps.LatLng(Number(lat), Number(lng)));
			$scope.map.setZoom(16);
		}

		$scope.initData = function () {
			Route.initData().success(function (data, status, headers, config) {
				$scope.data.workers = data.workers;
				$scope.data.steps = data.steps;
				$scope.data.types = data.types;
				$scope.data.fields = data.fields;
				$scope.data.customers = data.customers;
				$scope.data.stations = data.stations;
				$scope.data.steps.unshift({
					id: 0,
					name: 'Veri Oluşturuldu'
				});
				$scope.filterData.station_id = data.stations[0].id;
			});
		}
		$scope.initData();

		$scope.assignToWorker = function (worker_id) {
			$scope.optimizationSpinner = true;
			Route.assignToWorker({
				worker_id: worker_id,
				route_id: $scope.selectedRoute.id
			}).success(function (data, status, headers, config) {
				$scope.initRoute();
				$scope.optimizationSpinner = false;
				Messenger().post('Successfull');
				$('#assignToWorkerRoute').modal('hide');
			}).error(function (data) {
				alert('Error');
			});
		}

		$scope.openOptimize = function () {
			if ($scope.selectedForEditOrder.length == 0) {
				alert($translate.instant('PLEASE_SELECT_ORDER'));
				return true;
			}
			RouteList.new().success(function (data, status, headers, config) {
				$scope.rtstations = data.stations;
				$scope.newRouteData = {
					scheduled_start: '4:30',
					scheduled_end: '8:30',
				}
				$rootScope.newRouteData = {
					scheduled_start: '4:30',
					scheduled_end: '8:30',
				}
				jQuery('#startrt').datetimepicker({
					format: 'H:i',
					formatTime: 'H:i',
					datepicker: false,
					step: 30,
				});
				jQuery('#endrt').datetimepicker({
					format: 'H:i',
					formatTime: 'H:i',
					datepicker: false,
					step: 30,
				})
				$('#optimizeRoute').modal('show');
			});
		}

		$scope.createRoute = function () {
			$scope.optimizationSpinner = true;
			var orders = [];
			$scope.selectedForEditOrder.forEach(element => {
				orders.push(element.id);
			});
			$scope.newRouteData.orders = orders
			$scope.newRouteData.count = 1;
			$scope.newRouteData.deviation = 0;
			RouteList.save($scope.newRouteData).success(function (data, status, headers, config) {
				Messenger().post(data.message);
				$('#optimizeRoute').modal('hide');
				$scope.initRoute();
				$scope.$broadcast('refresh_data');
				$scope.newRouteData = {
					scheduled_start: '4:30',
					scheduled_end: '8:30',
				}
				$rootScope.newRouteData = {
					scheduled_start: '4:30',
					scheduled_end: '8:30',
				}
			}).error(function (data) {
				Messenger().post({
					message: data.message,
					type: "error"
				});
			});
		}

		$scope.selectedOrders = [];
		$scope.openRoute = function (data) {
			var bounds = new google.maps.LatLngBounds();
			data.orders.forEach(element => {
				var location = new google.maps.LatLng(Number(element.location.latitude), Number(element.location.longitude));
				bounds.extend(location);
			});
			$scope.map.fitBounds(bounds);
		}

		$scope.delete = function (route) {
			var r = confirm($translate.instant("ARE_U_SURE_DELETE"));
			if (r == true) {
				Route.delete(route.id).success(function (data, status, headers, config) {
					$scope.data.routes.forEach((element, index) => {
						if (element.id == route.id) {
							$scope.data.routes.splice(index, 1);
						}
					});
					Messenger().post('Successfull');
				}).error(function (data) {
					Messenger().post({
						message: data.message,
						type: "error"
					});
				});
			}
		};

		function clearRoute(id) {
			if ($scope.directionsDisplay[id] != null) {
				$scope.directionsDisplay[id].setMap(null);
				$scope.directionsDisplay[id] = null;
			}
		}

		var drawingManager;
		var overlay;
		$scope.editRoute = function () {
			$scope.editMode = true;
			drawingManager = new google.maps.drawing.DrawingManager({
				drawingMode: google.maps.drawing.OverlayType.POLYGON,
				drawingControl: true,
				drawingControlOptions: {
					position: google.maps.ControlPosition.TOP_CENTER,
					drawingModes: ['polygon']
				},
				circleOptions: {
					fillColor: '#ffff00',
					fillOpacity: 1,
					strokeWeight: 5,
					clickable: false,
					editable: true,
					zIndex: 1
				}
			});
			drawingManager.setMap($scope.map);
			google.maps.Polygon.prototype.Contains = function (point) {
				var crossings = 0,
					path = this.getPath();

				// for each edge
				for (var i = 0; i < path.getLength(); i++) {
					var a = path.getAt(i),
						j = i + 1;
					if (j >= path.getLength()) {
						j = 0;
					}
					var b = path.getAt(j);
					if (rayCrossesSegment(point, a, b)) {
						crossings++;
					}
				}

				// odd number of crossings?
				return (crossings % 2 == 1);

				function rayCrossesSegment(point, a, b) {
					var px = point.lng(),
						py = point.lat(),
						ax = a.lng(),
						ay = a.lat(),
						bx = b.lng(),
						by = b.lat();
					if (ay > by) {
						ax = b.lng();
						ay = b.lat();
						bx = a.lng();
						by = a.lat();
					}
					// alter longitude to cater for 180 degree crossings
					if (px < 0) {
						px += 360;
					}
					if (ax < 0) {
						ax += 360;
					}
					if (bx < 0) {
						bx += 360;
					}

					if (py == ay || py == by) py += 0.00000001;
					if ((py > by || py < ay) || (px > Math.max(ax, bx))) return false;
					if (px < Math.min(ax, bx)) return true;

					var red = (ax != bx) ? ((by - ay) / (bx - ax)) : Infinity;
					var blue = (ax != px) ? ((py - ay) / (px - ax)) : Infinity;
					return (blue >= red);

				}
			};
			google.maps.event.addListener(drawingManager, 'polygoncomplete', function (polygon) {
				console.log(polygon)
				overlay = polygon;
				$scope.data.routes.forEach(route => {
					route.orders.forEach(element => {
						var location = new google.maps.LatLng(Number(element.location.latitude), Number(element.location.longitude))
						if (polygon.Contains(location)) {
							$scope.selectedForEditOrder.push(element);
						}
					});
				});
				$scope.data.orders.forEach(element => {
					var location = new google.maps.LatLng(Number(element.location.latitude), Number(element.location.longitude))
					if (polygon.Contains(location)) {
						$scope.selectedForEditOrder.push(element);
					}
				});

			});
		}

		$scope.closeEditRoute = function () {
			$scope.editMode = false;
			$scope.selectedForEditOrder = [];
			drawingManager.setMap(null);
			console.log(overlay)
			overlay.setMap(null);
		}

		$scope.assignOrderToRoute = function (route_id) {
			var orders = [];
			$scope.selectedForEditOrder.forEach(element => {
				orders.push(element.id)
			});
			var data = {
				route_id: route_id,
				orders: orders
			};
			$scope.optimizationSpinner = true;
			Order.assignOrderToRoute(data).success(function (data, status, headers, config) {
				$scope.init('assign');
				$scope.optimizationSpinner = false;
			}).error(function (data) {
				alert(data)
				$('#orderInfo').modal('show');
			});
		}

		$scope.totalDistance = [];
		$scope.totalDuration = [];
		function renderRoute(data, color, type) {
			if (data.orders.length == 0) {
				return false;
			}
			var orders1 = data.orders.slice(0, 24);
			var orders2 = data.orders.slice(25, 49);
			var orders3 = data.orders.slice(50, 74);
			var orders4 = data.orders.slice(75, 99);
			$scope.station = data.station;
			$scope.totalDistance[data.id] = 0;
			$scope.totalDuration[data.id] = 0;
			renderRouteOpt(data, $scope.station, orders1, color, true);
			if (orders2.length > 0) {
				renderRouteOpt(data, $scope.station, orders2, color, false);
			}
			if (orders3.length > 0) {
				renderRouteOpt(data, $scope.station, orders3, color, false);
			}
			if (orders4.length > 0) {
				renderRouteOpt(data, $scope.station, orders4, color, false);
			}
		}

		function renderRouteOpt(route, station, orders, color, stationStatus) {
			var waypts = [];
			var locations = [];
			var origins = '';
			orders.forEach(element => {
				var lat = element.location.latitude;
				var lng = element.location.longitude;
				var position = {
					lat: parseFloat(lat),
					lng: parseFloat(lng)
				}
				waypts.push({
					location: position,
					stopover: true
				});
				locations.push(position)
				origins = origins.concat(lat + ',' + lng + '|');
			});
			$scope.map.fitBounds(bounds);

			// var flightPath = new google.maps.Polyline({
			// 	path: locations,
			// 	geodesic: false,
			// 	strokeColor: color,
			// 	strokeOpacity: 0.8,
			// 	strokeWeight: 5,
			// 	radius: parseInt(6)
			// });
			// flightPath.setMap($scope.map);
			// if (!route.total_time || route.distance || (type && type == 'assign')) {
			// 	var destination = parseFloat(station.latitude) + ',' + parseFloat(station.longitude);
			// 	Route.distancematrix({ route_id: route.id, destination: destination, origins: origins }).success(function (data, status, headers, config) {
			// 		var index = $scope.data.routes.indexOf($filter('filter')($scope.data.routes, {
			// 			id: route.id
			// 		})[0]);
			// 		if (index > -1) {
			// 			$scope.data.routes[index].total_time = data.total_time;
			// 			$scope.data.routes[index].total_mile = data.distance / 1609;
			// 		}
			// 		setTimeout(() => {
			// 			$scope.$apply();
			// 		}, 300);
			// 	});
			// }
			// return;
			$scope.directionsService[route.id] = new google.maps.DirectionsService();
			$scope.directionsDisplay[route.id] = new google.maps.DirectionsRenderer({
				map: $scope.map,
				suppressMarkers: true, //false it if you want a marker from the direction service
				polylineOptions: {
					strokeColor: color,
					geodesic: false,
					strokeColor: color,
					strokeOpacity: 0.8,
					strokeWeight: 5,
					radius: parseInt(6)
				}
			});
			var request = {
				origin: {
					lat: parseFloat(station.latitude),
					lng: parseFloat(station.longitude)
				},
				destination: {
					lat: parseFloat(orders[0].location.latitude),
					lng: parseFloat(orders[0].location.longitude)
				},
				waypoints: waypts,
				travelMode: 'DRIVING',
				avoidHighways: false,
				optimizeWaypoints: true,
				provideRouteAlternatives: false,
				avoidTolls: true
			};

			var markers = [];
			var pinImage = {
				url: "/color-pin?color=" + color.substr(1),
				scaledSize: new google.maps.Size(36, 36),
			};
			var duration = 0;
			var mile = 0;
			$scope.directionsService[route.id].route(request, function (response, status) {
				if (status == google.maps.DirectionsStatus.OK) {
					$scope.directionsDisplay[route.id].setDirections(response);
					if (response.routes.length == 0) {
						return true;
					}
					var my_route = response.routes[0];
					for (var i = 0; i < my_route.legs.length; i++) {
						mile += my_route.legs[i].distance.value;
						duration += my_route.legs[i].duration.value;
						if (i == 0 && stationStatus) {
							new google.maps.Marker({
								position: my_route.legs[i].start_location,
								label: "" + (i + 1),
								icon: 'img/station.png',
								map: $scope.map
							});
						} else {
							markers[i] = new google.maps.Marker({
								position: my_route.legs[i].start_location,
								map: $scope.map,
								icon: pinImage,
								title: "order_location",
								label: {
									text: "" + (i + 1),
									color: "white"
								},
								visible: true
							});
							google.maps.event.addListener(markers[i], 'click', function () {
								$scope.selectedMarkers.push(markers[i])
							});
						}
					}
					var loc = my_route.legs[my_route.legs.length - 1].end_location.toUrlValue(6).split(',');
					$scope.station = {
						latitude: loc[0],
						longitude: loc[1]
					};
					var totalRouteTime = (duration * 1000);
					var totalStopTime = route.stop_time * 60 * 1000 * (orders.length - 1); // ms
					var totalTime = Number(totalRouteTime) + Number(totalStopTime) + $scope.totalDuration[route.id];
					$scope.totalDuration[route.id] = totalTime;

					var totalDistance = Number(Number(parseFloat(mile) / 1609) + Number($scope.totalDistance[route.id]));
					$scope.totalDistance[route.id] = totalDistance;

					// Find route and update distance/time
					var index = $scope.data.routes.indexOf($filter('filter')($scope.data.routes, {
						id: route.id
					})[0]);
					if (index > -1) {
						$scope.data.routes[index].total_time = totalTime;
						$scope.data.routes[index].total_mile = totalDistance;
						RouteList.updateField({
							id: $scope.data.routes[index].id,
							total_time: totalTime,
							distance: totalDistance * 1609,
						});
					}
					setTimeout(() => {
						$scope.$apply();
					}, 300);
				}
			});
		}

		$scope.$on('refresh_data', function () {
			console.log('refreshing map...')
			$scope.init();
			$scope.selectedOrders = [];
		}, true);

		$scope.clearFilter = function (page) {
			$scope.filterData = {
				workers: [],
				workSteps: [],
				created_at: {
					startDate: null,
					endDate: null
				},
				scheduled_time: {
					startDate: null,
					endDate: null
				}
			}
			$scope.filterProp = [];
			$scope.refreshData('map');
		}

		$scope.calculateDiff = function (start, end) {
			return moment(end).diff(moment(start), 'minute');
		}

		$scope.clickWork = function (work) {
			$scope.selectedOrderId = work.id;
			$scope.infowindowsOrder.forEach(function (element) {
				element.close();
			}, this);
			$scope.infowindowsOrder[work.id].open($scope.map, $scope.orderMarkers[work.id]);
			$scope.map.panTo(new google.maps.LatLng(Number(work.customer.latitude), Number(work.customer.longitude)));

			google.maps.event.addListener($scope.infowindowsOrder[work.id], 'closeclick', function () {
				$scope.selectedOrderId = 0;
			});
		}

	}
]);