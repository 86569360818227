bovoApp.controller('StockMovementController', ['$scope', 'StockMovement', '$location','$routeParams','$filter',
  function ($scope, StockMovement, $location, $routeParams, $filter) {
    $scope.deleted = [];
		$scope.filterData = {
      workers: [],
      page: 1
    }
    
    $scope.all = function (type) {
      if(typeof type !== 'undefined' && type == 'clear') {
				$scope.filterData = {
					term: '',
					workers: [],
					workSteps: [],
					created_at : { 
						startDate: null, 
						endDate: null 
					},
					scheduled_time : { 
						startDate: null, 
						endDate: null 
					}
				}
      }
      
      StockMovement.all($scope.filterData).success(function(data, status, headers, config) {
        $scope.data = data;
      });
    }

    $scope.getPage = function(page) {
			$scope.filterData.page = page;
			$scope.all();
    }
    
    $scope.sort = function(field,sort) {
			$scope.filterData.field = field;
			$scope.filterData.sort = sort;
			$scope.all();
    }
    
    $scope.clearFilter = function(page) {
			$scope.filterData = {
				workers: [],
				workSteps: [],
				created_at : { 
					startDate: null, 
					endDate: null 
				},
				scheduled_time : { 
					startDate: null, 
					endDate: null
				}
			}
			$scope.filterProp = [];
			$scope.all('clear');
		}


    $scope.get = function () {
      StockMovement.get($routeParams.id).success(function(data, status, headers, config) {
        $scope.data = data;
      });
    }
    
    $scope.new = function () {
      StockMovement.new().success(function(data, status, headers, config) {
        $scope.data = data;
      });
    }

    $scope.delete = function (type) {
			if(type == 'set') {
        $('#delete').modal('show');
      } else if(type == 'delete') {
				StockMovement.delete($scope.deleted).success(function(data, status, headers, config) {
          Messenger().post(data.message);
          for (var i = $scope.deleted.length - 1; i >= 0; i--) {
            var index = $scope.data.stockMovement.indexOf($filter('filter')($scope.data.stockMovements, {id: $scope.deleted[i] })[0]);
            if (index > -1)
              $scope.data.stockMovement.splice(index, 1);
          }
          $scope.deleted = [];
          $('#delete').modal('hide');
				}).error(function(data) {
          Messenger().post({message: data.message,type: "error"});
				});
			} 
		}

		$scope.save = function () {
			StockMovement.save($scope.stockMovement).success(function(data, status, headers, config) {
				$scope.data = data;
        Messenger().post(data.message);
        $location.path('stok/hareketler');
			}).error(function(data) {
        Messenger().post({message: data.message,type: "error"});
      });
		}

		$scope.update = function () {
			StockMovement.update($scope.data.stockMovement).success(function(data, status, headers, config) {
				$scope.data = data;
        Messenger().post(data.message);
        $location.path('stok/hareketler');
			}).error(function(data) {
        Messenger().post({message: data.message,type: "error"});
      });
		}

    $scope.goTo = function(id) {
      $location.path('stok/hareket/' + id);
    }

    $scope.stockSelected = function(selected,type) {
      if(typeof type === 'undefined') {
          if(typeof selected !== 'undefined') {
              var data = selected.originalObject;
          }else {
              var data = [];
          }
      }else {
          var data = selected;
      }
      if(typeof data !== 'undefined' && typeof $scope.stockMovement !== 'undefined') {
        $scope.stockMovement.stock_id = data.id;
        $scope.stockMovement.stock = data.name;
      }
      if(typeof data !== 'undefined' && typeof $scope.data.stockMovement !== 'undefined') {
        $scope.data.stockMovement.stock_id = data.id;
        $scope.data.stockMovement.stock = data.name;
      }
  }
  
  $scope.orderSelected = function(selected,type) {
      if(typeof type === 'undefined') {
          if(typeof selected !== 'undefined') {
              var data = selected.originalObject;
          }else {
              var data = [];
          }
      }else {
          var data = selected;
      }
      if(typeof data !== 'undefined' && typeof $scope.stockMovement !== 'undefined') {
        $scope.stockMovement.order_id = data.id;
        $scope.stockMovement.order = data.name;
      }
      if(typeof data !== 'undefined' && typeof $scope.data.stockMovement !== 'undefined') {
        $scope.data.stockMovement.order_id = data.id;
        $scope.data.stockMovement.order = data.name;
      }
  }

  $scope.toggleSelection = function toggleSelection(id) {
    var idx = $scope.deleted.indexOf(id);
    if (idx > -1) {
      $scope.deleted.splice(idx, 1);
    }
    else {
      $scope.deleted.push(id);
    }
  };

  }
]);
