bovoApp.controller('PricingController', ['$scope', 'Pricing', '$location', '$routeParams', '$filter', '$translate',
  function ($scope, Pricing, $location, $routeParams, $filter, $translate) {
    $scope.deleted = [];

    $scope.all = function () {
      Pricing.all().success(function (data, status, headers, config) {
        $scope.data = data;
      });
    }

    $scope.openDetail = function (item) {
      Pricing.get(item.id).success(function (data, status, headers, config) {
        $('#pricingModal').modal('show');
        $scope.pricing = data.pricing;
        $scope.formData = data;
        $scope.getTables($scope.pricing, true);
        $scope.pricing.actives = [];
        $scope.pricing.active_cities.forEach(element => {
          $scope.pricing.actives[element.pricing_city_id] = true;
        });
      });
    }

    $scope.$on('new_data', function () {
      $('#pricingModal').modal('show');
      $scope.new();
    }, true);

    $scope.new = function () {
      Pricing.new().success(function (data, status, headers, config) {
        $scope.formData = data;
        $scope.pricing = {
          name: 'TABLO',
          status: 'active',
          company_customer_id: 0,
          customer_group_id: 0,
          exit_city_id: 0,
          sms_price: 0,
          return_rate: 0,
          second_delivery_fee: 0,
          prices: [],
          actives: []
        };
      });
    }

    $scope.getTables = function (pricing, set = false) {
      Pricing.getTables(pricing).success(function (data, status, headers, config) {
        $scope.formData.tables = data.tables;
        if (set) {
          var prices = [];
          $scope.pricing.prices.forEach(element => {
            if (!Array.isArray(prices[element.pricing_weight_id])) {
              prices[element.pricing_weight_id] = [];
            }
            prices[element.pricing_weight_id][element.pricing_city_id] = element.price
          });
  
          $scope.pricing.prices = prices;
        }
      });
    }

    $scope.filterCities = function (pricingCities, pricingCityId) {
      if (pricingCities && pricingCityId) {
        return pricingCities.filter(function (i) { return i.id == pricingCityId })
      }
    }

    $scope.delete = function (type) {
      if (type == 'set') {
        $('#delete').modal('show');
      } else if (type == 'delete') {
        Pricing.delete($scope.deleted).success(function (data, status, headers, config) {
          Messenger().post($scope.translate('Silindi'));
          $scope.all();
          $scope.deleted = [];
          $('#delete').modal('hide');
        }).error(function (data) {
          Messenger().post({
            message: data.message,
            type: "error"
          });
        });
      }
    }

    $scope.postForm = function (user) {
      if ($scope.pricing.id) {
        $scope.update();
      } else {
        $scope.save();
      }
    }

    $scope.save = function () {
      Pricing.save($scope.pricing).success(function (data, status, headers, config) {
        $scope.data = data;
        Messenger().post('Başarılı');
        $('#pricingModal').modal('hide');
        $scope.all();
      }).error(function (data) {
        Messenger().post({
          message: data.message,
          type: "error"
        });
      });
    }

    $scope.update = function () {
      Pricing.update($scope.pricing).success(function (data, status, headers, config) {
        $scope.data = data;
        Messenger().post('Başarılı');
        $('#pricingModal').modal('hide');
        $scope.all();
      }).error(function (data) {
        Messenger().post({
          message: data.message,
          type: "error"
        });
      });
    }

    $scope.toggleSelection = function toggleSelection(id) {
      var idx = $scope.deleted.indexOf(id);
      if (idx > -1) {
        $scope.deleted.splice(idx, 1);
      } else {
        $scope.deleted.push(id);
      }
    };

    $scope.addPrice = function (workType, type) {
      $scope.pricing.prices.push({
        min_weight: null,
        type: 'weight',
        work_type: workType,
        price_type: type
      });
    }

    $scope.removePrice = function (item) {
      var index = $scope.pricing.prices.findIndex(function (elm) {
        return elm.$$hashKey == item.$$hashKey
      })
      $scope.pricing.prices.splice(index, 1);
    }

  }
]);