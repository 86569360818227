bovoApp.controller('CustomerAccountController', ['$scope', 'CustomerAccount', '$location', 'FileUploader', '$localStorage', '$translate', '$sce',
  function ($scope, CustomerAccount, $location, FileUploader, $localStorage, $translate, $sce) {
    $scope.password = {};

    $scope.init = function () {
      CustomerAccount.init().success(function (data, status, headers, config) {
        $scope.customer = data;
        $scope.password = {};
      });
    };

    $scope.updateCustomerInfo = function () {
      CustomerAccount.updateCustomerInfo($scope.customer).success(function (data, status, headers, config) {
        $scope.authenticatedUser.full_name = $scope.customer.user.first_name + ' ' + $scope.customer.user.last_name;
        $scope.customer.customer = data.customer;
        $scope.customer.user = data.user;
        Messenger().post('Bilgiler güncellendi');
      }).error(function (data) {
        Messenger().post({ message: data.message, type: "error" });
      });
    };

    $scope.updatePassword = function () {
      CustomerAccount.updatePassword($scope.password).success(function (data, status, headers, config) {
        Messenger().post(data.message);
        $scope.password = {};
      }).error(function (data) {
        Messenger().post({ message: data.message, type: "error" });
      })
    };

    var logo = $scope.logo = new FileUploader({
      url: '/api/upload-customer-logo',
      headers: { 'X-CSRF-TOKEN': CSRF_TOKEN },
      autoUpload: true
    });

    logo.filters.push({
      name: 'formatFilter',
      fn: function (item /*{File|FileLikeObject}*/, options) {
        var type = '|' + item.type.slice(item.type.lastIndexOf('/') + 1) + '|';
        return '|jpg|png|jpeg|bmp|gif|'.indexOf(type) !== -1;
      }
    });

    logo.onAfterAddingFile = function (fileItem) {
      $scope.proccessProfile = true;
      $scope.pending = true;
    };

    logo.onCompleteItem = function (item, response, status, headers) {
      $scope.proccessProfile = false;
      $scope.customer.customer.logo = response.file;
      if (response.status == 'success')
        $scope.pending = false;
    }
  }
]);
