bovoApp.
  factory('Worker', ['$http', function ($http) {
    return {
      init: function (type) {
        return $http.post('api/workers', type);
      },
      newWorker: function () {
        return $http.get('api/new-worker');
      },
      show: function (id) { 
        return $http.get('api/worker/' + id);
      },
      saveWorker: function (data) {
        return $http.post('api/save-worker', data);
      },
      deleteWorker: function (data) {
        return $http.post('api/delete-worker', data);
      },
      sendNotification: function (data) {
        return $http.post('api/send-notification-worker', data);
      },
      updateWorker: function (data) {
        return $http.post('api/update-worker', data);
      },
      changeWorkerApplied: function (data) {
        return $http.post('api/change-worker-applied', data);
      },
      chageDocumentField: function (data) {
        return $http.post('api/change-document-field', data);
      },
      chageUserStatus: function (data) {
        return $http.post('api/change-user-status', data);
      },
      chageUserStatus: function (data) {
        return $http.post('api/change-user-status', data);
      },
      chageWorkerStatus: function (data) {
        return $http.post('api/change-worker-status', data);
      },
      chageWorkerRouteStatus: function (data) {
        return $http.post('api/change-worker-route-status', data);
      },
      updateWorkerField: function (data) {
        return $http.post('api/update-worker-field', data);
      },
      callDriver: function (data) {
        return $http.post('api/call-driver', data);
      }
    };
  }]);